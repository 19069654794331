import React from 'react'
import './ChronoxAtTechExpo.css'
import AiWhatsappScrollingSection from '../../Component/AiWhatsappScrollingSection/AiWhatsappScrollingSection'
import Navbar from '../Navbar/Navbar'
import { Grid } from '@mui/material'
import LandingFooter from '../../Component/Footer/LandingFooter'
import OnlineDemoSection from '../../Component/OnlineDemoSection/OnlineDemoSection'
import ChronoxAtGitexBoothSection from '../../Component/ChronoxAtGitexBoothSection/ChronoxAtGitexBoothSection'
import GitexAttendeesCard from '../../Component/GitexAttendeesCard/GitexAttendeesCard'
import GitexBusinessSection from '../../Component/GitexBusinessSection/GitexBusinessSection'
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs'
import TechExpoTimerComponent from '../../Component/TechExpoTimer/TechExpoTimerComponent'
import EventExperience from '../../Component/EventExperience/EventExperience'

const ChronoxAtTechExpo = () => {

    const faqData = [
        {
            question: "How does a WhatsApp chatbot work?",
            answer: "A WhatsApp Chatbot uses AI and predefined responses to interact with users, answer queries, and perform tasks, providing a seamless experience.",
        },
        {
            question: "What are the benefits of using WhatsApp chatbots?",
            answer: "WhatsApp Chatbots improve efficiency, offer 24/7 support, and personalize interactions, leading to enhanced customer satisfaction and business growth."
        },
        {
            question: "Are WhatsApp chatbots secure?",
            answer: "WhatsApp Chatbots prioritize security, ensuring data protection and compliance with WhatsApp's policies.",
        },
        {
            question: "What can I ask the chatbot?",
            answer: "You can ask questions related to our services, product details, pricing, account management, support queries, and more. Our chatbot is designed to provide you with quick information and assist you in navigating our website."
        },
        {
            question: "How does the chatbot understand my questions?",
            answer:
                "Our chatbot uses advanced AI algorithms to understand natural language, which means you can ask questions just as you would to a human."
        },
    ];

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container className='cag-head-container'>
                        <Navbar />
                        <Grid item>
                            <Grid container>
                                <Grid item xs={12} className='tech-expo-timer-component'>
                                    <TechExpoTimerComponent />
                                </Grid>

                                <Grid item xs={10} className='tech-expo-scrolling-component'>
                                    <AiWhatsappScrollingSection />
                                </Grid>

                                <Grid item xs={10} sx={{ margin: '50px auto' }}>
                                    <GitexBusinessSection />
                                </Grid>

                                <Grid item xs={12} sx={{ margin: "0px auto 150px" }}>
                                    <ChatbotFAQs faqData={faqData} />
                                </Grid>

                                <Grid item xs={8} sx={{ margin: "0px auto 150px" }}>
                                    <OnlineDemoSection eventName={"TECH-EXPO GUJARAT 2024"}/>
                                </Grid>

                                <Grid item xs={12} >
                                    <LandingFooter />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    )
}

export default ChronoxAtTechExpo
