import React from 'react'
import './AiWhatsappScrollingSection.css'
import '../AIChatbotSimplified/AIChatbotSimplified.css'
import { Grid, Typography } from '@mui/material'
import greenMyExperience from './../../assests/GlobalImages/green-my-experience.png'

const AiWhatsappScrollingSection = () => {

    const customerLogo = [
        "https://storage.writewiz-staging.in/general/instabrain_1727185013903.png",
        "https://storage.writewiz-staging.in/general/providence_insurance_1727185358825.png",
        "https://storage.writewiz-staging.in/general/Mundo_Chiapas_1727185402366.png",
        "https://storage.writewiz-staging.in/general/Clinica_Biblica_1727185449506.png",
        "https://storage.writewiz-staging.in/general/iVALT_1727185485059.png",
        "https://storage.writewiz-staging.in/general/Kboodl_1727185527865.png",
        "https://storage.writewiz-staging.in/general/Rast_1727185565672.png",
        "https://storage.writewiz-staging.in/general/JMS_1727185601140.png",
        "https://storage.writewiz-staging.in/general/brokersalliance_1727185628853.png",
        greenMyExperience
    ].map((image) => ({
        id: crypto.randomUUID(),
        image
    }));

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container gap={"30px"}>
                        <Grid item xs={12} sx={{ textAlign: 'center', marginBottom: '30px' }}>
                            <Typography
                                variant='h2'
                                className='cw-trusted-normal-text-color'
                            >
                                Trusted by valued customers
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }} className='slider-buttons-style'>
                            <Grid container>
                                <Grid item xs={12} sx={{ margin: '0 auto' }} >
                                    <div className='slider'>
                                        <div className="inner">
                                            <div className="wrapper">
                                                <section style={{ "--speed": `${20000}ms` }}>
                                                    {customerLogo.map(({ id, image }) => (
                                                        <div className="image scroll-image-spacing" key={id}>
                                                            <img src={image} alt={id} className='scroll-image trustedIconStyle' loading='lazy' />
                                                        </div>
                                                    ))}
                                                </section>
                                                <section style={{ "--speed": `${20000}ms` }}>
                                                    {customerLogo.map(({ id, image }) => (
                                                        <div className="image scroll-image-spacing" key={id}>
                                                            <img src={image} alt={id} className='scroll-image trustedIconStyle' loading='lazy' />
                                                        </div>
                                                    ))}
                                                </section>
                                                <section style={{ "--speed": `${20000}ms` }}>
                                                    {customerLogo.map(({ id, image }) => (
                                                        <div className="image scroll-image-spacing" key={id}>
                                                            <img src={image} alt={id} className='scroll-image trustedIconStyle' loading='lazy' />
                                                        </div>
                                                    ))}
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AiWhatsappScrollingSection