import { Button, Grid } from '@mui/material'
import React, { useContext } from 'react'
import "./../ChatbotFeaturesBox/ChatbotFeaturesBox.css"
import "./ChatbotCardSection.css"
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom'
import { Context as mainContext } from '../../Context/MainContext/GlobalContext'

const ChatbotCardSection = (props) => {

    const { blogHeader } = props
    const { setBlogText } = useContext(mainContext)

    const navigate = useNavigate()


    return (
        <>
            <Grid container className="cs-background-container">
                <Grid item xl={9} lg={11} md={11} sm={11} xs={11} sx={{ margin: '0 auto' }} className='cs-card-section'>
                    <Grid container gap={5}>
                        <Grid item xs={12} className='cs-section-title'>
                            <h2>Browse our Resource Library</h2>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container sx={{ justifyContent: 'center', gap: "40px" }}>
                                {blogHeader?.slice(0, 3)?.map((item, index) => {
                                    return (
                                        <Grid item xs={3} className='cs-card-item' key={index}>
                                            <Grid container >
                                                <Grid item className='cs-image-container'>
                                                    <img src={item.img} alt='image' className='cs-image' loading='lazy' />
                                                </Grid>
                                                <Grid item className='cs-description'>
                                                    <p>{item.headText}</p>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant='contained'
                                                        size='large'
                                                        endIcon={<EastIcon />}
                                                        className='lc-blog-explore-btn'
                                                        onClick={(ele) => {
                                                            const plainBlogData = {
                                                                headText: item.headText,
                                                                navigateLink: item.navigateLink,
                                                                img: item.img,
                                                            };

                                                            console.log("plainBlogData", plainBlogData);


                                                            localStorage.setItem('selectedBlogText', JSON.stringify(plainBlogData));
                                                            setBlogText(plainBlogData);
                                                            navigate(item.navigateLink);
                                                        }}
                                                        aria-label='Explore Blog'
                                                    >
                                                        Explore Now
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                                }
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChatbotCardSection
