import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './GitexTimerComponent.css';
import gitexDubai from '../../assests/Gitex/gitex-global-full-img.png'
import { useNavigate } from 'react-router-dom';

const GitexTimerComponent = () => {
    // Timer state to store days, hours, minutes, and seconds
    const [timer, setTimer] = useState({
        days: '--',
        hours: '--',
        minutes: '--',
        seconds: '--',
    });
    const navigate = useNavigate()

    // Function to calculate remaining time until Oct 14, 2024
    useEffect(() => {
        const countDownDate = new Date("Oct 14, 2024 00:00:00").getTime();

        const updateTimer = setInterval(() => {
            const now = new Date().getTime();
            const distance = countDownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                clearInterval(updateTimer);
                setTimer({
                    days: '00',
                    hours: '00',
                    minutes: '00',
                    seconds: '00',
                });
            } else {
                setTimer({
                    days,
                    hours,
                    minutes,
                    seconds,
                });
            }
        }, 1000);

        return () => clearInterval(updateTimer);
    }, []);

    return (
        <>
            <Grid container justifyContent="center" alignItems="center" className='gitex-timer-container'>
                <Grid item xs={12}>
                    <Grid container justifyContent={'center'} alignItems="center">
                        <Grid item xs={10} className='gitex-main-head-item'>
                            <Grid container sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Grid item xs={5.5}>
                                    <Grid container >
                                        <Grid item xs={10} className='gitex-timer-head-item'>
                                            <span className='gitex-timer-heading'>Ready to decode customer delight?</span>
                                        </Grid>

                                        <Grid item xs={8} className='gitex-subhead-text-item'>
                                            <Typography
                                                variant='span'
                                                className='gitex-subhead-text'
                                            >
                                                Turn every intraction into an opportunity with omni-channel AI Agents!
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} className='gitex-timer-main-item'>
                                            <Grid container direction={"column"}>
                                                <Grid item xs={12}>
                                                    <span className='gitex-timer-subheading'>GITEX GLOBAL 2024</span>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <span className='gitex-timer-date'>Oct 14 - 18</span>
                                                            <span className='gitex-timer-place'> | Dubai, United Arab Emirates</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className='gitex-book-booth-btn-item'>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant='span' className='gitex-book-booth-head'>
                                                        Schedule a 1-to-1 meeting to perfect your CX
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ margin: '10px 0px 0px' }}>
                                                    <Button
                                                        variant='contained'
                                                        size='large'
                                                        className='gitex-timer-button'
                                                        onClick={() => { navigate('/contact-us') }}
                                                    >
                                                        Book a Booth Meetup
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                        <Grid item xs={12}>
                                            <Grid container gap={2} className="timer-container" alignItems={"center"}>
                                                <Grid item>
                                                    <span className="timer-number">{timer.days}</span>
                                                    <span className="timer-label">DAYS</span>
                                                </Grid>
                                                <Grid item className='timer-colon'>
                                                    :
                                                </Grid>
                                                <Grid item>
                                                    <span className="timer-number">{timer.hours}</span>
                                                    <span className="timer-label">HOURS</span>
                                                </Grid>
                                                <Grid item className='timer-colon'>
                                                    <span>:</span>
                                                </Grid>
                                                <Grid item>
                                                    <span className="timer-number">{timer.minutes}</span>
                                                    <span className="timer-label">MINUTES</span>
                                                </Grid>
                                                <Grid item className='timer-colon'>
                                                    <span>:</span>
                                                </Grid>
                                                <Grid item>
                                                    <span className="timer-number">{timer.seconds}</span>
                                                    <span className="timer-label">SECONDS</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={5.5} className='gitex-bg-image-item'>
                                    <Grid container justifyContent="center" sx={{ position: 'relative', alignItems: 'center' }}>
                                        <img src={gitexDubai} alt="" srcSet="" className='gitex-image-img' style={{ width: "100%" }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GitexTimerComponent;
