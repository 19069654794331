import { Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import EastIcon from '@mui/icons-material/East';
import './Instagram.css'

import Navbar from '../Navbar/Navbar';
import InstagramHeadSection from '../../Component/InstagramHeadSection/InstagramHeadSection';
import AiWhatsappScrollingSection from '../../Component/AiWhatsappScrollingSection/AiWhatsappScrollingSection';
import InstaAutomateSection from '../../Component/InstaAutomateSection/InstaAutomateSection';
import SideBySideSectionWithBtn from '../../Component/GlobalComponents/SideBySideSectionWithBtn/SideBySideSectionWithBtn';
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs';
import AIChatbotSimplified from '../../Component/AIChatbotSimplified/AIChatbotSimplified';
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter';
import LandingFooter from '../../Component/Footer/LandingFooter';
import InstagramImproveTeamCollabration from '../../Component/InstagramImproveTeamCollabration/InstagramImproveTeamCollabration';
import AIFacebookEngagement from '../../Component/AIFacebookEngagement/AIFacebookEngagement';


const Instagram = (props) => {

    const { setFlagBanner, flagBanner } = props
    const [pageName, setPageName] = useState('Chatbot');


    const [chatbotFaqs, setChatbotFaqs] = useState([
        {
            question: "What makes this AI agent different from other chatbots?",
            answer: "Our AI agent isn’t just about conversations—it drives engagement with image carousels, quick replies, and actionable CTAs. It personalizes interactions, offers seamless human handoffs when needed, and integrates effortlessly with your existing tools, ensuring a smooth, efficient customer experience.",
        },
        {
            question: "How fast can I set it up on my website?",
            answer: "In just a few minutes! With our no-code, no workflow platform, you simply write what you need, and your chatbot is ready to go live. No complicated workflows or technical expertise required."
        },
        {
            question: "Can I customize the chatbot’s design and behavior?",
            answer: "Absolutely! From tone and personality to the chatbot’s visual design, you have complete control to match your brand perfectly. Embed it anywhere on your site—on product pages, forms, or checkout—so your customers get help where they need it most.",
        },
        {
            question: "Does the AI agent work with my existing systems?",
            answer: "Yes! Our AI agent integrates seamlessly with CRMs, helpdesk software, and other tools, ensuring your data flows smoothly and your team stays in sync."
        },
        {
            question: "What if the AI agent can’t answer a customer’s query?",
            answer:
                "No problem! When the AI reaches its limits, it hands over the conversation to a human agent smoothly—ensuring a seamless experience with zero disruption to the customer."
        },
        {
            question: "How does the chatbot help with engagement and conversions?",
            answer:
                "The AI agent interacts visually and dynamically—using image carousels, quick replies, and CTAs—to guide customers through product discovery, answer questions instantly, and drive meaningful actions like purchases or bookings."
        },
    ])


    const data = [
        {
            head: "81%",
            subHead: '81% of people use Instagram to research products and services',
            bgColor: '#001b47',
            color: '#ffffff'
        },
        {
            head: "200M",
            subHead: '200 million Instagram users visit at least one business profile daily',
            bgColor: '#fff',
            color: '#000000'
        },
        {
            head: "130M",
            subHead: '130 million people tap on shopping posts every month.',
            bgColor: '#ffc859',
            color: '#000000'
        },
        {
            head: "90%",
            subHead: '90% of Instagram users follow a business',
            bgColor: '#ffc859',
            color: '#000000'
        },
        {
            head: "50%",
            subHead: '50% of people have visited a website after seeing a product or service on Instagram',
            bgColor: '#fff',
            color: '#000000'
        },
        {
            head: "1.393 billion",
            subHead: 'nstagram has at least 1.393 billion users around the world',
            bgColor: '#001b47',
            color: '#ffffff'
        },
    ]

    return (
        <>
            <Navbar setFlagBanner={setFlagBanner} flagBanner={flagBanner} />
            <Grid container>
                <Grid item x={12}>
                    <InstagramHeadSection flagBanner={flagBanner} />
                </Grid>

                <Grid item xs={10} className='in-trusted-item'>
                    <AiWhatsappScrollingSection />
                </Grid>

                <Grid item xs={10} className='in-trusted-item'>
                    <InstaAutomateSection />
                </Grid>

                <Grid item xs={10} className='in-trusted-item'>
                    <InstagramImproveTeamCollabration />
                </Grid>

                <Grid item xs={12} className='in-trusted-item'>
                    <AIFacebookEngagement data={data} />
                </Grid>

                <Grid item xs={12} className='chatbot-faq-section'>
                    <ChatbotFAQs faqData={chatbotFaqs} />
                </Grid>

                <Grid item xs={12} className='in-new-aisimplified-section-main-item'>
                    <AIChatbotSimplified />
                </Grid>

                <Grid item xs={12} className='pr-overlap-item'>
                    <ParaphraseOverlapFooter pageName={pageName} />
                </Grid>

                <Grid item xs={12} className='brsw-footer-component-main-item'>
                    <LandingFooter />
                </Grid>
            </Grid>
        </>
    )
}

export default Instagram