import { Grid } from '@mui/material'
import React from 'react'
import PhoneInput from 'react-phone-input-2';
import './GlobalPhoneInput.css'
import "react-phone-input-2/lib/style.css";

const GlobalPhoneInput = (props) => {
    return (
        <Grid item className='fullwidth phoneInputWrapper' xs={12}>
            {/* {props.flagUpdate ?  */}
                <PhoneInput 
                    country={props.defaultCountry ?? "in"} 
                    value={props.value}
                    onChange={(value) => props.onChange({ target: { value } })} disabled={props?.disabled} 
                    // dropdownStyle={{top: "auto", bottom: "100%", position: "absolute"}}
                />
                {/* : */}
                {/* <PhoneInput country={props.defaultCountry ?? "in"} value={props.value}
                    onChange={(value) => props.onChange({ target: { value } })} disabled={props.disabled}
                />                 */}
            {/* } */}
        </Grid>
    )
}

export default GlobalPhoneInput
