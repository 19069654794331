import { Grid, Typography } from '@mui/material'
import React from 'react'

const CcaasIsOutdated = () => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                CCaaS moved contact centers to the cloud—but cloud-based inefficiency is still inefficiency. The future of customer service isn’t just upgrading CCaaS. It’s replacing it entirely.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                Businesses sticking to CCaaS will keep struggling with high costs, agent churn, and customer frustration. Those that embrace AI-first support with Chronox AI will gain a true competitive edge.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                Are you ready to move beyond CCaaS? Let’s talk. 🚀
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "20px 0px 0px" }}>
                            <a href="https://calendly.com/birud-chronox/30min?preview_source=et_card&month=2025-01" target='_blank' className='article-navigation-link'>Book a Free Demo:</a>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                {` AI-first customer support is here.`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                Get started today by signing up on <a href={process.env.REACT_APP_REDIRECT_URL} target='_blank' className='article-navigation-link'>Chronox AI</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CcaasIsOutdated
