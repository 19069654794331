import React from 'react';
import './ComparisonContainer.css';

const ComparisonContainer = () => {
    return (
        <div className="comparison-container"> 
            <div className="comparison-parts">
                <h2 className='part-headingName'>Chronox AI</h2>
                <div className="comparison-column">
                    <span className='features-include'>FEATURES INCLUDED :</span>
                    <ul className='tick-lists'>
                        <li>AI Agent Ready in Minutes</li>
                        <li>Action Automations with No-Code Platform</li>
                        <li>No Training, No Workflow Setup Needed</li>
                        <li>Live Chat with Human Agent Support</li>
                        <li>Affordable Pricing Based on Conversations</li>
                        <li>Seamless Routing to the Right Human Agent</li>
                        <li>Scalable and Secure, Enterprise-Ready</li>
                    </ul>
                </div>
            </div>
            <div className='vs-style-container'>
                <span className='vs-style-text'>VS</span>
            </div>
            <div className="comparison-parts">
                <h2 className='part-headingName'>Other Software</h2>
                <div className="comparison-column">
                    <span className='features-include'>FEATURES NOT INCLUDED :</span>
                    <ul className='cross-lists'>
                        <li>Setup takes days or even weeks</li>
                        <li>No AI-driven automations available</li>
                        <li>Requires complex workflows and AI training</li>
                        <li>Limited or missing live agent support</li>
                        <li>High costs based on total users and expensive AI</li>
                        <li>No human routing option available</li>
                        <li>Lacks robust security and scalability for enterprises</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ComparisonContainer;