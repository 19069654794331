import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { bottom } from "@popperjs/core";
import $ from "jquery";
import SmallTextStarIcon from '../../assests/star-extenstion.png'
import { Button } from "@mui/material";
import ForwardArrowIcon from '../../assests/forward-arrow.png'
import '../../Pages/OmnichannelDeployment/CarouselAnimation.css'

// import carouselimg1 from '../../assests/Platform/Omini/omniCarouselimg1.svg'
// import carouselimg2 from '../../assests/Platform/Omini/omniCarouselimg2.svg'
// import carouselimg3 from '../../assests/Platform/Omini/omniCarouselimg3.svg'
// import carouselimg4 from '../../assests/Platform/Omini/omniCarouselimg4.svg'

gsap.registerPlugin(ScrollTrigger);

const AiChatbotCarouselAnimation = () => {

    // const { mainData } = props

    const mainData = [
        {
            name: "one",
            text: "",
            smallTitle: "",
            title: "Effortless Omnichannel Support",
            description: "AI chatbot offers frictionless omnichannel experiences, ensuring your brand is where your customers are. Elevate your customer service with unified conversations.",
        },
        {
            name: "two",
            text: "",
            smallTitle: "",
            title: "No-Code Platform - Empowering Every Team Member",
            description:
                "Harness the power of our user-friendly, no-code platform to create and customize your chatbot effortlessly.",
        },
        {
            name: "three",
            text: "",
            smallTitle: "",
            title: "Crafting Conversations That Reflect Your Brand",
            description:
                "Infuse your chatbot with a personality that mirrors your brand, making every interaction memorable and on-brand.",
        },
    ];

    // const ChangeNextElement = (e) => {
    //     const idOfCurrentImageSection = e.trigger.getAttribute("id");
    //     const idOfCurrentTextSection = `${idOfCurrentImageSection}-text`;

    //     const tempNextComponentText = mainData.filter(
    //         (el) => el.name === idOfCurrentImageSection
    //     );

    //     // if(idOfCurrentImageSection != 'one'){

    //     const nextComponentTitle = tempNextComponentText[0]?.title
    //         ?.split(" ")
    //         .slice(0, -1)
    //         .join(" ");
    //     const nextComponentText = tempNextComponentText[0]?.text;
    //     const nextComponentTitleLastWord = tempNextComponentText[0]?.title
    //         ?.split(" ")
    //         .splice(-1)[0];
    //     const nextComponentDescription = tempNextComponentText[0]?.description;
    //     const nextComponentSmallTitle = tempNextComponentText[0]?.smallTitle;

    //     const newScrollSmallTitleDiv = document.createElement("div");
    //     const newScrollSmallTitleIcon = document.createElement("img");
    //     newScrollSmallTitleIcon.src = SmallTextStarIcon;
    //     newScrollSmallTitleIcon.classList.add("small-title-icon");
    //     const newScrollSmallTitleText = document.createTextNode(`${nextComponentSmallTitle}`);
    //     newScrollSmallTitleDiv.append(newScrollSmallTitleIcon);
    //     newScrollSmallTitleDiv.append(newScrollSmallTitleText);

    //     $("#newScrollSmallTitle").html(newScrollSmallTitleDiv);
    //     // $("#newScrollSmallTitle").text(`${nextComponentSmallTitle}`);
    //     $("#newScrollTitle").text(`${nextComponentTitle}`);
    //     $("#newScrollText").text(`${nextComponentText}`);
    //     $("#newScrollTitleLastWord").text(`${nextComponentTitleLastWord}`);
    //     $("#newScrollDescription").text(`${nextComponentDescription}`);

    //     $("#newScrollTitle").addClass("animateTextFadeIn");
    //     $("#newScrollTitleDiv").addClass("animateTextFadeIn");
    //     $("#newScrollText").addClass("animateTextFadeIn");
    //     $("#newScrollTitleLastWord").addClass("animateTextFadeIn");
    //     $("#newScrollDescription").addClass("animateTextFadeIn");
    //     $("#newScrollSmallTitle").addClass("animateTextFadeIn");
    //     $("newScrollButton").addClass("animateTextFadeIn");

    //     setTimeout(() => {
    //         $("#newScrollSmallTitle").removeClass("animateTextFadeIn");
    //         $("#newScrollTitle").removeClass("animateTextFadeIn");
    //         $("#newScrollTitleDiv").removeClass("animateTextFadeIn");
    //         $("#newScrollText").removeClass("animateTextFadeIn");
    //         $("#newScrollTitleLastWord").removeClass("animateTextFadeIn");
    //         $("#newScrollDescription").removeClass("animateTextFadeIn");
    //         $("newScrollButton").removeClass("animateTextFadeIn");
    //     }, 1000);
    // };


    const ChangeNextElement = (e) => {
        const idOfCurrentImageSection = e.trigger?.getAttribute("id");
        const tempNextComponentText = mainData.find(
            (el) => el.name === idOfCurrentImageSection
        );

        if (!tempNextComponentText) {
            console.error(`No matching data found for id: ${idOfCurrentImageSection}`);
            return;
        }

        const nextComponentTitle = tempNextComponentText?.title?.split(" ").slice(0, -1).join(" ") || '';
        const nextComponentText = tempNextComponentText?.text || '';
        const nextComponentTitleLastWord = tempNextComponentText?.title?.split(" ").splice(-1)[0] || '';
        const nextComponentDescription = tempNextComponentText?.description || '';
        const nextComponentSmallTitle = tempNextComponentText?.smallTitle || '';

        const newScrollSmallTitleDiv = document.createElement("div");
        // const newScrollSmallTitleIcon = document.createElement("img");
        // newScrollSmallTitleIcon.src = SmallTextStarIcon;
        // newScrollSmallTitleIcon.classList.add("small-title-icon");
        const newScrollSmallTitleText = document.createTextNode(nextComponentSmallTitle);
        newScrollSmallTitleDiv.append(newScrollSmallTitleText);

        $("#newScrollSmallTitle").html(newScrollSmallTitleDiv);
        $("#newScrollTitle").text(nextComponentTitle);
        $("#newScrollText").text(nextComponentText);
        $("#newScrollTitleLastWord").text(nextComponentTitleLastWord);
        $("#newScrollDescription").text(nextComponentDescription);

        // Apply animations
        const elements = [
            "#newScrollSmallTitle",
            "#newScrollTitle",
            "#newScrollTitleDiv",
            "#newScrollText",
            "#newScrollTitleLastWord",
            "#newScrollDescription",
            "newScrollButton",
        ];

        elements.forEach((selector) => $(selector).addClass("animateTextFadeIn"));

        setTimeout(() => {
            elements.forEach((selector) => $(selector).removeClass("animateTextFadeIn"));
        }, 1000);
    };


    useEffect(() => {
        const textElement = document.querySelector(
            "#newscrollelement-main-text-inner-div"
        );
        const mainDiv = document.querySelector(".necarouselscrollelement-main-div");
        const mainImage = document.querySelector(".newscrollelement-main-image-wrapper");
        const elements = document.querySelectorAll(".abcdefg");
        const lastSection = document.querySelector(
            "#necarouselscrollelement-main-div-inner-last"
        );
        gsap.timeline({
            scrollTrigger: {
                trigger: textElement,
                start: "center center", // Start pinning at the top of .necarouselscrollelement-main-div
                endTrigger: lastSection,
                end: "center center", // End pinning at the bottom of .newscrollelement-main-image-wrapper
                scrub: true,
                pin: "#newscrollelement-main-text-inner-div",
                anticipatePin: 1,
                // markers: true,
            },
        });
        elements.forEach((element) => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: element,
                    start: "30% 70%",
                    end: "30% 10%",
                    scrub: true,
                    anticipatePin: 1,
                    onEnter: (e) => ChangeNextElement(e),
                    onEnterBack: (e) => ChangeNextElement(e),
                    // markers: true,
                },
            });
        });
    }, []);

    return (
        <div className="necarouselscrollelement-main-div">
            <div
                id="necarouselscrollelement-main-div-inner"
                className="necarouselscrollelement-main-div-inner"
            >
                <div className="newscrollelement-main-image-wrapper">
                    <div id="one" className="abcdefg">
                        <div className="newscrollelement-main-image-container">
                            <img
                                src={"https://storage.writewiz-staging.in/general/Ominchanncel_support_image_1710941882652.png"}
                                className="newscrollelement-main-image"
                                alt="Support"
                            />
                        </div>
                    </div>
                </div>
                <div
                    id="newscrollelement-main-text"
                    className="newscrollelement-main-text"
                >
                    <div className="tempDiv"></div>
                    <div
                        id="newscrollelement-main-text-inner-div"
                        className="newscrollelement-main-text-inner-div"
                    >
                        <div id="newScrollSmallTitle" className="text small-title">
                            {mainData[0].smallTitle}
                        </div>

                        <div id="newScrollTitleDiv">
                            <span id="newScrollTitle">
                                {mainData[0].title?.split(" ").slice(0, -1).join(" ")}
                            </span>
                            <span id="newScrollTitleLastWord">
                                {mainData[0].title?.split(" ").splice(-1)[0]}
                            </span>
                        </div>
                        <div id="newScrollText">{mainData[0].text}</div>
                        <div id="newScrollDescription">{mainData[0].description}</div>
                        {/* <div id="newScrollButton">
                            <Button
                                variant="outlined"
                                className="newscroll-learn-more-button"
                                size="medium"
                                onClick={() => window.open("/browser-extension", "_blank")}
                                sx={{
                                    border: 'none',
                                    borderRadius: '10px',
                                    padding: '6px 18px',
                                    boxShadow: '0 0 3px 0 #6A097D',
                                    color: '#6A097D',
                                    fontWeight: '600',
                                    textTransform: 'capitalize',
                                    fontSize: '18px',
                                    marginTop: '50px'
                                }}
                            >
                                Learn More
                                <img src={ForwardArrowIcon} className="newscroll-learn-more-button-forward-icon" />
                            </Button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="necarouselscrollelement-main-div-inner">
                <div className="newscrollelement-main-image-wrapper">
                    <div id="two" className="abcdefg">
                        {" "}
                        <img
                            src={"https://storage.writewiz-staging.in/general/no_code_platform_image_1710941920521.png"}
                            className="newscrollelement-main-image"
                            alt="Platform"
                        />
                    </div>
                </div>
                <div className="newscrollelement-main-text">
                    <div className="newscrollelement-main-text-inner-div"></div>
                </div>
            </div>

            <div id="necarouselscrollelement-main-div-inner-last" className="necarouselscrollelement-main-div-inner">
                <div className="newscrollelement-main-image-wrapper">
                    <div id="three" className="abcdefg">
                        {" "}
                        <img
                            src={"https://storage.writewiz-staging.in/general/Brand_conversations_image_1710942691802.png"}
                            className="newscrollelement-main-image"
                            alt="Conversation"
                        />
                    </div>
                </div>
                <div className="newscrollelement-main-text">
                    <div className="newscrollelement-main-text-inner-div"></div>
                </div>
            </div>

        </div>
    );
};

export default AiChatbotCarouselAnimation;
