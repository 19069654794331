import { Grid, Typography } from '@mui/material'
import React from 'react'

const NobodyLikeContactCenter = () => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                Customers hate calling support. Agents dread handling queues. And businesses? They’re stuck paying for a system that still doesn’t work. Traditional CCaaS (Contact Center as a Service) promised seamless, scalable customer service—but instead, it’s slow, costly, and frustrating.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                The real problem? CCaaS still relies on human agents to do the heavy lifting. No matter how modern it claims to be, CCaaS can’t escape these core inefficiencies.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default NobodyLikeContactCenter