import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../../Component/NewScrollElement/NewScrollElement.css";
import "./ChatbotExplore.css"
import $ from "jquery";

gsap.registerPlugin(ScrollTrigger);

const ChatbotExplore = () => {

  const [isScroll, setIsScroll] = useState(false);

  const mainData = [
    {
      name: "one",
      text: "",
      smallTitle: "",
      title: "Effortless Omnichannel Support",
      description: "AI chatbot offers frictionless omnichannel experiences, ensuring your brand is where your customers are. Elevate your customer service with unified conversations.",
    },
    {
      name: "two",
      text: "",
      smallTitle: "",
      title: "No-Code Platform - Empowering Every Team Member",
      description:
        "Harness the power of our user-friendly, no-code platform to create and customize your chatbot effortlessly.",
    },
    {
      name: "three",
      text: "",
      smallTitle: "",
      title: "Crafting Conversations That Reflect Your Brand",
      description:
        "Infuse your chatbot with a personality that mirrors your brand, making every interaction memorable and on-brand.",
    },
  ];

  const ChangeNextElement = (e) => {
    const idOfCurrentImageSection = e.trigger.getAttribute("id");
    const idOfCurrentTextSection = `${idOfCurrentImageSection}-text`;

    const tempNextComponentText = mainData.filter(
      (el) => el.name === idOfCurrentImageSection
    );

    // if(idOfCurrentImageSection != 'one'){

    const nextComponentTitle = tempNextComponentText[0]?.title
      ?.split(" ")
      .slice(0, -1)
      .join(" ");
    const nextComponentText = tempNextComponentText[0]?.text;
    const nextComponentTitleLastWord = tempNextComponentText[0]?.title
      ?.split(" ")
      .splice(-1)[0];
    const nextComponentDescription = tempNextComponentText[0]?.description;
    const nextComponentSmallTitle = tempNextComponentText[0]?.smallTitle;

    const newScrollSmallTitleDiv = document.createElement("div");
    const newScrollSmallTitleText = document.createTextNode(`${nextComponentSmallTitle}`);
    newScrollSmallTitleDiv.append(newScrollSmallTitleText);

    $("#newScrollSmallTitle").html(newScrollSmallTitleDiv);
    // $("#newScrollSmallTitle").text(`${nextComponentSmallTitle}`);
    $("#chatbotnewScrollTitle").text(`${nextComponentTitle}`);
    $("#newScrollText").text(`${nextComponentText}`);
    $("#chatbotnewScrollTitleLastWord").text(`${nextComponentTitleLastWord}`);
    $("#chatbotnewScrollDescription").text(`${nextComponentDescription}`);

    $("#chatbotnewScrollTitle").addClass("animateTextFadeIn");
    $("#newScrollTitleDiv").addClass("animateTextFadeIn");
    $("#newScrollText").addClass("animateTextFadeIn");
    $("#chatbotnewScrollTitleLastWord").addClass("animateTextFadeIn");
    $("#chatbotnewScrollDescription").addClass("animateTextFadeIn");
    $("#newScrollSmallTitle").addClass("animateTextFadeIn");
    $("newScrollButton").addClass("animateTextFadeIn");

    setTimeout(() => {
      $("#newScrollSmallTitle").removeClass("animateTextFadeIn");
      $("#chatbotnewScrollTitle").removeClass("animateTextFadeIn");
      $("#newScrollTitleDiv").removeClass("animateTextFadeIn");
      $("#newScrollText").removeClass("animateTextFadeIn");
      $("#chatbotnewScrollTitleLastWord").removeClass("animateTextFadeIn");
      $("#chatbotnewScrollDescription").removeClass("animateTextFadeIn");
      $("newScrollButton").removeClass("animateTextFadeIn");
    }, 1000);
  };



  const changeStructure = () => {
    if (window.scrollY > 1700) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeStructure);

    return () => {
      window.removeEventListener("scroll", changeStructure);
    };
  }, []);

  useEffect(() => {
    const textElement = document.querySelector(
      "#newscrollelement-main-text-inner-div"
    );
    const mainDiv = document.querySelector(".newscrollelement-main-div");
    const mainImage = document.querySelector(".newscrollelement-main-image-wrapper");
    const elements = document.querySelectorAll(".abcdefg");
    const lastSection = document.querySelector("#newscrollelement-main-div-inner-last");

    gsap.timeline({
      scrollTrigger: {
        trigger: textElement,
        start: "center center", // Start pinning at the top of .newscrollelement-main-div
        endTrigger: lastSection,
        end: "center center", // End pinning at the bottom of .newscrollelement-main-image-wrapper
        scrub: true,
        pin: "#newscrollelement-main-text-inner-div",
        anticipatePin: 1,
        // markers: true,
      },
    });

    elements.forEach((element) => {

      gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: "10% 90%",
          end: "20% 10%",
          scrub: true,
          anticipatePin: 1,
          onEnter: (e) => ChangeNextElement(e),
          onEnterBack: (e) => ChangeNextElement(e),
          // markers: true,
        },
      });
    });
  }, []);

  return (
    <div className="newscrollelement-main-div" style={{ backgroundColor: "#FFF" }}>
      <div style={{ position: "relative" }}>


        <div className={`cb-explore-heading-container ${isScroll && "cb-shodow-and-bg"}`}>
          <p className="cb-explore-heading">Conversational <span>AI Chatbot</span> for Your Business</p>
          <p className="cb-explore-sub-heading"> Ensuring a personal touch in every interaction, tailored just for you</p>
        </div>

        <div >
          <div id="newscrollelement-main-div-inner" className="chatbot-newscrollelement-main-div-inner">

            <div className="newscrollelement-main-image-wrapper">
              <div id="one" className="abcdefg">
                <div className="newscrollelement-main-image-container">
                  <img
                    src={"https://storage.writewiz-staging.in/general/Ominchanncel_support_image_1710941882652.png"}
                    className="chatbotnewscrollelement-main-image"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>

            <div id="newscrollelement-main-text" className="chatbot-newscrollelement-main-text"  >

              <div className="chatbot-tempDiv"></div>

              <div id="newscrollelement-main-text-inner-div" className="newscrollelement-main-text-inner-div" >

                <div id="newScrollSmallTitle" className="text chatbot-small-title">
                  {mainData[0].smallTitle}
                </div>

                <div id="newScrollTitleDiv">
                  <span id="chatbotnewScrollTitle">
                    {/* {mainData[0].title?.split(" ").slice(0, -1).join(" ")} */}
                  </span>
                  <span id="chatbotnewScrollTitleLastWord">
                    {/* {mainData[0].title?.split(" ").splice(-1)[0]} */}
                  </span>
                </div>

                <div id="newScrollText">
                  {/* {mainData[0].text} */}
                </div>
                <div id="chatbotnewScrollDescription">
                  {/* {mainData[0].description} */}
                </div>

              </div>
            </div>
          </div>
        </div>


        <div className="cb-explore-full-heigth-wrapper">
          <div className="chatbot-newscrollelement-main-div-inner">
            <div className="newscrollelement-main-image-wrapper">
              <div id="two" className="abcdefg">
                {" "}
                <img
                  src={"https://storage.writewiz-staging.in/general/no_code_platform_image_1710941920521.png"}
                  className="chatbotnewscrollelement-main-image"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div className="chatbot-newscrollelement-main-text">
              <div className="newscrollelement-main-text-inner-div"></div>
            </div>
          </div>
        </div>

      </div>

      <div style={{ margin: "0px 0px 60px 0px" }} >
        <div className="chatbot-newscrollelement-main-div-inner" id="newscrollelement-main-div-inner-last" >
          <div className="newscrollelement-main-image-wrapper">
            <div id="three" className="abcdefg">
              {" "}
              <img
                src={"https://storage.writewiz-staging.in/general/Brand_conversations_image_1710942691802.png"}
                className="chatbotnewscrollelement-main-image"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
          <div className="chatbot-newscrollelement-main-text">
            <div className="newscrollelement-main-text-inner-div"></div>
          </div>
        </div>
      </div>

      {/* <div className="chatbot-expolre-border-bottom"></div> */}
    </div>
  );
};

export default ChatbotExplore;
