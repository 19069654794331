import { Grid, Typography } from '@mui/material'
import React from 'react'

const StopPatchingCCaas = () => {
    const data = [
        {
            title: "No more waiting. ",
            content: "AI Agents handle customer issues instantly—no queues, no hold music, no wasted time."
        },
        {
            title: "No more scripted bots. ",
            content: "AI Agents don’t just reply; they think, resolve, and make decisions like real support teams."
        },
        {
            title: "No more expensive agent seats. ",
            content: "Ditch the costly per-seat CCaaS model—AI Agents scale instantly without adding headcount."
        },
        {
            title: "Seamless, human-like conversations. ",
            content: "Chronox AI ensures smooth, personalized interactions across text-based channels like WhatsApp, Facebook, Instagram, and websites—no matter the platform."
        },
        {
            title: "True AI-powered resolution. ",
            content: "Unlike traditional automation, AI Agents don’t just pass issues along—they solve them."
        }
    ]

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                               Instead of trying to fix an outdated system, Chronox AI introduces a completely new approach: AI-first customer service.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                               AI Agents aren’t just chatbots or virtual assistants. They replace inefficient processes, handle real customer interactions, and make intelligent decisions—just like (or better than) human agents.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                               Here’s how AI Agents completely eliminate CCaaS inefficiencies:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "0px 0px 10px" }}>
                            <ul>
                                {data.map((list, index) =>
                                    <li key={index} style={{marginTop: "10px"}}>
                                        <Typography
                                            component='span'
                                            className='artical-paragraph-global-text-list'
                                        >
                                            {list.title}
                                        </Typography>
                                        <Typography
                                            component='span'
                                            className='artical-paragraph-global-text'
                                        >
                                            {`${list.content}`}
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default StopPatchingCCaas