import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import "./LandingFooter.css";
import chronoxWhiteLogo from "../../assests/LandingPage/ChronoxWhiteLogoNew.svg"

import facebookimg from "../../assests/FacebookLogo.svg";
import tweeterimg from "../../assests/TwitterLogo.svg";
import linkedinimg from "../../assests/LinkedinLogo.svg";
import instagramimg from "../../assests/InstagramLogo.svg";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const LandingFooter = () => {

  const isBelowLaptopWidth = useMediaQuery('(min-width: 0px) and (max-width: 1200px')
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleLinkClick = (url) => {
    window.location.href = url;
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const menuData = {
    Platform: {
      sections: [
        {
          title: 'Platform',
          content: [
            {
              name: 'AI Agent',
              nameColor: '#6A097D',
              bgColor: '#5E35B112',
              description: 'Beyond chat—AI Agents that act, solve, and transform your business at scale',
              navigationLink: 'ai-chatbot',
            },
            {
              name: 'Omnichannel Deployment',
              nameColor: '#C3236E',
              bgColor: '#C3236E0D',
              description: 'Get insights that highlight what’s working and where to improve.',
              navigationLink: 'omnichannel-deployment',
            },
            {
              name: 'Interactive Messages',
              nameColor: '#14CF8B',
              bgColor: '#DDF4ED99',
              description: 'Engage customers with interactive chats that feel personal.',
              navigationLink: 'interactive-message',
            },
            // {
            //   name: 'Integration',
            //   nameColor: '#F37C00',
            //   bgColor: '#FFE0AE4D',
            //   description: 'Seamlessly integrate AI capabilities into your existing systems.',
            //   navigationLink: '/',
            // },
            // {
            //   name: 'RAG & AI Actions',
            //   nameColor: '#3233A3',
            //   bgColor: '#BAB3E226',
            //   description: 'Leverage real-time AI-driven actions and responses to improve customer satisfaction.',
            //   navigationLink: '/',
            // },
            // {
            //   name: 'Live Agent Assist',
            //   nameColor: '#1B1B1B',
            //   bgColor: '#E9E9E980',
            //   description: 'Empower agents with real-time AI support for more effective resolutions.',
            //   navigationLink: '/',
            // },
            // {
            //   name: 'Call Analysis',
            //   nameColor: '#00796B',
            //   bgColor: '#00796B12',
            //   description: 'Analyze customer calls to gather insights and improve service quality.',
            //   navigationLink: '/',
            // },
            {
              name: 'Voice AI',
              chip: "Coming Soon",
              chipColor: "#6A097D",
              chipBgColor: "#F7E9FF",
              nameColor: '#3E9DA1',
              bgColor: '#BFF5F64D',
              description: 'Enhance customer interactions with cutting-edge voice AI technology.',
              navigationLink: '/voice-ai',
            },

          ]
        }
      ]
    },
    Solutions: {
      sections: [
        {
          title: 'Solutions',
          content: [
            {
              name: 'Website',
              nameColor: '#6A097D',
              bgColor: '#F7E9FF99',
              description: 'Deploy AI-powered chatbots directly on your website for seamless customer support.',
              navigationLink: "/website-ai-chatbot"
            },
            {
              name: 'Facebook',
              nameColor: '#0084FF',
              bgColor: '#0084FF12',
              description: 'Automate customer service on Facebook using intelligent AI chatbots.',
              navigationLink: "/facebook-ai-chatbot"
            },
            {
              name: 'WhatsApp',
              nameColor: '#25D366',
              bgColor: '#25D3661A',
              description: 'Engage customers on WhatsApp with real-time AI-driven conversations.',
              navigationLink: "/whatsapp-ai-chatbot"
            },
            {
              name: 'Instagram',
              nameColor: '#FFD521',
              bgColor: '#D3297812',
              description: 'Connect with customers on Instagram through AI-powered messaging.',
              navigationLink: "/instagram"
            },
            {
              name: 'Slack',
              nameColor: '#00BCD4',
              bgColor: '#FD8E0712',
              description: 'Integrate AI chatbots into Slack to streamline team and customer communication.',
              navigationLink: "/slack-ai-chatbot"
            },
            // {
            //   name: 'Email',
            //   nameColor: '#1E55BA',
            //   bgColor: '#1E55BA12',
            //   description: 'Enhance email communications with AI-driven automated responses.',
            //   navigationLink: "/"
            // },
          ]
        },
        // {
        //   title: 'Integrations',
        //   content: [
        //     {
        //       name: 'Calendly',
        //       nameColor: '#6451F4',
        //       bgColor: '#F1F0F9B2',
        //       description: 'Integrate with Calendly to automate meeting scheduling through AI chatbots.',
        //       navigationLink: "/"
        //     },
        //     {
        //       name: 'WhatsApp',
        //       nameColor: '#25D366',
        //       bgColor: '#EAFBF0',
        //       description: 'Enhance your WhatsApp experience with advanced AI integrations.',
        //       navigationLink: "/whatsapp-ai-chatbot"
        //     },
        //     {
        //       name: 'Slack',
        //       nameColor: '#00BCD4',
        //       bgColor: '#FFF7ED',
        //       description: 'Enable seamless team collaboration with AI integration in Slack.',
        //       navigationLink: "/slack-ai-chatbot"
        //     },
        //     {
        //       name: 'Trello',
        //       nameColor: '#518FE1',
        //       bgColor: '#EDF6FF',
        //       description: 'Integrate AI into Trello for better task management and team collaboration.',
        //       navigationLink: "/"
        //     },
        //     {
        //       name: 'Email',
        //       nameColor: '#1E55BA',
        //       bgColor: '#EFF3FA',
        //       description: 'Automatically manage email responses with AI to improve productivity.',
        //       navigationLink: "/"
        //     },
        //   ]
        // }
      ]
    },
    Industries: {
      sections: [
        {
          title: 'Industries',
          content: [
            {
              name: 'Healthcare',
              nameColor: '#6A097D',
              bgColor: '#F7E9FF99',
              description: 'Revolutionize patient engagement and support with AI-driven healthcare solutions.',
              navigationLink: "industry/healthcare"
            },
            {
              name: 'Food Sector',
              nameColor: '#0084FF',
              bgColor: '#0084FF12',
              description: 'Streamline order management and customer service with AI in the food industry.',
              navigationLink: "industry/food"
            },
            {
              name: 'Retail',
              nameColor: '#25D366',
              bgColor: '#25D3661A',
              description: 'Enhance customer shopping experiences with AI-powered retail automation.',
              navigationLink: "industry/retail"
            },
            {
              name: 'E-Commerce',
              nameColor: '#FFD521',
              bgColor: '#D3297812',
              description: 'Boost sales and customer satisfaction with intelligent AI for e-commerce platforms.',
              navigationLink: "industry/ecommerce"
            },
            {
              name: 'Blogs',
              nameColor: '#FFD521',
              bgColor: '#D3297812',
              description: 'Automate content interaction and audience engagement for your blogs using AI.',
              navigationLink: "/blog",
            },
          ]
        }
      ]
    },
    Pricing: {
      sections: [
        {
          title: 'Pricing',
          content: [
            {
              name: 'Pricing',
              description: 'Revolutionize patient engagement and support with AI-driven healthcare solutions.',
              navigationLink: "/pricing"
            },
          ]
        }
      ]
    },
    ContactUS: {
      sections: [
        {
          title: 'Contact',
          content: [
            {
              name: 'Contact',
              navigationLink: "/contact-us"
            },
          ]
        }
      ]
    },
    Gitex: {
      sections: [
        {
          title: 'Events',
          content: [
            {
              name: 'TECH-EXPO GUJARAT 2024',
              description: 'Revolutionize patient engagement and support with AI-driven healthcare solutions.',
              navigationLink: "/events/tech-expo-gujarat"
            },
            {
              name: 'Gitex',
              description: 'Revolutionize patient engagement and support with AI-driven healthcare solutions.',
              navigationLink: "/chronox-at-gitex-global-dubai"
            },
          ]
        }
      ]
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="lf-main-item">
          <Grid container>

            <Grid item xl={11} lg={11.5} md={11} sm={11} xs={11} className="lf-content-main-item">
              <Grid container className="lf-content-main-container">
                <Grid item xs={12}>
                  <Grid container sx={{ gap: "10px", justifyContent: 'space-between' }}>
                    <Grid item xl={3} lg={3} md={5.5} sm={12} xs={12}>
                      <Grid container>
                        <Grid item xs={12} className="lp-ft-logo-item">
                          <a href={"/"} target="_self">
                            <img
                              src={chronoxWhiteLogo}
                              alt="Chronox"
                              height={40}
                              width={"auto"}
                            />
                          </a>
                        </Grid>
                        <Grid item xs={12} >
                          <Typography
                            variant={"span"}
                            className="lp-ft-content-caption"
                          >
                            Enhance support with AI agents for personalized interactions.{" "} <br />
                            Integrate easily with different platforms to improve processes and efficiency.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                          <Grid container>
                            <Grid item xs={12} sx={{ margin: '3px 0px' }}>
                              <Typography variant="span"
                                sx={{ color: "white", fontSize: "14px", fontWeight: "500", cursor: "pointer" }}
                                onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}/privacy-policy`, `_blank`)}>
                                Privacy Policy
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ margin: '3px 0px' }}>
                              <Typography
                                sx={{ color: "white", fontSize: "14px", fontWeight: "500", cursor: "pointer" }}
                                variant="span"
                                onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}/terms`, `_blank`)}>
                                T&C
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {!isBelowLaptopWidth ?
                      (
                        <>
                          <Grid item xl={5} lg={5.5} md={0} xs={0}>
                            <Grid container sx={{ gap: "50px", justifyContent: 'space-evenly' }}>
                              <Grid item>
                                {menuData?.Platform?.sections?.map((section, sectionIndex) => (
                                  <div key={sectionIndex}>
                                    <Grid item className="lp-ft-section-title-item">
                                      <Typography
                                        variant="span"
                                        className="lp-ft-section-title"
                                      >
                                        {section.title}
                                      </Typography>
                                    </Grid>

                                    <Grid item>
                                      <Grid container className="lp-ft-ft-link-container">
                                        {section.content.map((ele, index) => (
                                          <Grid item key={index}>
                                            <a
                                              href={ele.navigationLink}
                                              target="_self"
                                              className="lp-ft-link-anchor"
                                            >
                                              <span className="lp-ft-link-label">
                                                {ele.name}
                                              </span>
                                            </a>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </Grid>
                                  </div>
                                ))}
                              </Grid>

                              <Grid item>
                                {menuData?.Solutions?.sections?.map((section, sectionIndex) => (
                                  <div key={sectionIndex}>
                                    <Grid item className="lp-ft-section-title-item" sx={{ marginTop: section.title == "Integrations" ? "30px" : "0px" }} key={section.title}>
                                      <Typography
                                        variant="span"
                                        className="lp-ft-section-title"
                                      >
                                        {section.title}
                                      </Typography>
                                    </Grid>

                                    <Grid item>
                                      <Grid container className="lp-ft-ft-link-container">
                                        {section.content.map((ele, index) => (
                                          <Grid item key={index}>
                                            <a
                                              href={ele.navigationLink}
                                              target="_self"
                                              className="lp-ft-link-anchor"
                                            >
                                              <span className="lp-ft-link-label">
                                                {ele.name}
                                              </span>
                                            </a>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </Grid>
                                  </div>
                                ))}
                              </Grid>

                              <Grid item>
                                {menuData?.Industries?.sections?.map((section, sectionIndex) => (
                                  <div key={sectionIndex}>
                                    <Grid item className="lp-ft-section-title-item" key={section.title}>
                                      <Typography
                                        variant="span"
                                        className="lp-ft-section-title"
                                      >
                                        {section.title}
                                      </Typography>
                                    </Grid>

                                    <Grid item>
                                      <Grid container className="lp-ft-ft-link-container">
                                        {section.content.map((ele, index) => (
                                          <Grid item key={index}>
                                            <a
                                              href={ele.navigationLink}
                                              target="_self"
                                              className="lp-ft-link-anchor"
                                            >
                                              <span className="lp-ft-link-label">
                                                {ele.name}
                                              </span>
                                            </a>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </Grid>
                                  </div>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xl={3} lg={3} md={0} xs={0} className="lf-cnx-label-item">
                            <Grid container sx={{ gap: '10px' }}>

                              <Grid item xs={12}>
                                <Grid container sx={{ justifyContent: 'space-between', }}>
                                  <Grid item xs={2.5}>
                                    <p className="lf-cnx-label">Company</p>
                                  </Grid>
                                  <Grid item xs={8.5}>
                                    <p className="lf-cnx-label">
                                      Chronox AI Private Limited
                                    </p>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid container sx={{ justifyContent: 'space-between', }}>
                                  <Grid item xs={2.5}>
                                    <p className="lf-cnx-label">
                                      Address
                                    </p>
                                  </Grid>
                                  <Grid item xs={8.5}>
                                    <address className="lf-cnx-label normal-font-style">
                                      611, 612 Satyamev Eminence, Science City Road, Sola, Ahmedabad-West, Gujarat 380060.
                                    </address>
                                  </Grid>
                                </Grid>
                              </Grid>

                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xl={5} lg={5} md={5.5} sm={12} xs={12} className='lf-accordian-item'>


                            {Object.keys(menuData).map((sectionKey, sectionIndex) => (
                              <div key={sectionIndex}>
                                {/* Each section has its own accordion */}
                                {menuData[sectionKey]?.sections?.map((section, secIndex) => (
                                  <Accordion
                                    key={secIndex}
                                    className='lf-accordian'
                                    sx={{ borderBottom: expanded === `${sectionKey}-${secIndex}` ? '1px solid #6A097D' : '1px solid #d0d0d0' }}
                                    expanded={expanded === `${sectionKey}-${secIndex}`}
                                    onChange={handleChange(`${sectionKey}-${secIndex}`)}
                                  >
                                    <AccordionSummary
                                      // disableScrollLock={true}
                                      expandIcon={<ExpandMoreIcon style={{ color: 'white', fontSize: '22px', fontWeight: '500' }} />}
                                      aria-controls={`panel${sectionKey}-${secIndex}-content`}
                                      id={`panel${sectionKey}-${secIndex}-header`}
                                      className='lf-accordian-summery'
                                    >
                                      <Typography variant='span' className='lf-accordian-summery-title'>
                                        {section.title}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className=''>
                                      <Grid container>
                                        {/* Mapping over the content inside each section */}
                                        {section.content.map((contentItem, contentIndex) => (
                                          <Grid item xs={12} key={contentIndex} className='' sx={{ margin: '2px 0px' }}>
                                            <Typography
                                              key={contentIndex}
                                              variant='span'
                                              onClick={() => handleLinkClick(contentItem.navigationLink || contentItem.link)}
                                              className=''
                                              sx={{
                                                color: "white",
                                                fontWeight: '500',
                                                fontSize: '14px'
                                              }}
                                            >
                                              {contentItem.name}
                                            </Typography>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                ))}
                              </div>
                            ))}


                          </Grid>

                        </>
                      )}
                  </Grid>
                </Grid>

                <Grid item xs={12} className="lp-ft-divider-item">
                  <Divider className="lp-ft-divider" />
                </Grid>

                <Grid item xs={12} className="lp-ft-cp-item">
                  <Grid container className="lp-ft-cp-container">
                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12} className="lf-cnx-cp-label-item">
                      <span className="lf-cnx-cp-label">
                        Copyright &copy; {new Date().getFullYear()} Chronox AI Private Limited.
                        All Rights Reserved.
                      </span>
                    </Grid>

                    <Grid item xl={4} lg={4} md={4} sm={4} xs={12} className="lp-ft-social-item">
                      {/* <a href="https://twitter.com/" target="_blank" className="lp-ft-social-link1" aria-label="Twitter">
                        <img src={tweeterimg} alt="" />
                      </a> */}
                      <a href="https://www.linkedin.com/company/chronox-ai/" target="_blank" className="lp-ft-social-link" aria-label="LinkedIn">
                        <img src={linkedinimg} alt="" />
                      </a>
                      <a href="https://www.facebook.com/people/Chronox-AI/61562335443030/" target="_blank" className="lp-ft-social-link" aria-label="Facebook">
                        <img src={facebookimg} alt="" />
                      </a>
                      <a href="https://www.instagram.com/chronoxai/" target="_blank" className="lp-ft-social-link" aria-label="Instagram">
                        <img src={instagramimg} alt="" />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container>
        <Grid item xs={12}>
          <Grid container className="lp-footer-main-container">

            <Grid item xs={12} className="lp-ft-footer-content-item">
              <Grid container className="lp-ft-footer-content-container">
                <Grid
                  item
                  xl={11}
                  lg={11}
                  md={12}
                  sm={11}
                  xs={12}
                  className="lp-ft-footer-content-sub-item"
                >
                  <Grid
                    container
                    className="lp-ft-footer-content-sub-container"
                    sx={{ alignItems: 'baseline' }}
                  >
                    <Grid
                      item
                      xl={3}
                      lg={4.5}
                      md={5.5}
                      sm={12}
                      xs={12}
                      className="lp-ft-left-content-item"
                    >
                      <Grid container>
                        <Grid item xs={12} className="lp-ft-logo-item">
                          <a href={writeWizWebsiteURL} target="_self">
                            <img
                              src={chronoxWhiteLogo}
                              height={50}
                              width={"auto"}
                              alt=""
                            />
                          </a>
                        </Grid>

                        <Grid item xs={12} marginTop={"15px"}>
                          <Typography
                            component={"p"}
                            className="lp-ft-content-caption"
                          >
                            Enhance support with AI agents for personalized interactions.{" "}
                            <br />
                            Integrate easily with different platforms to improve processes and efficiency.
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ margin: '25px 0px' }}>
                          <Grid container direction={"column"}>
                            <Grid item height={"30px"}>
                              <Grid container direction={"row"}>
                                <Grid item xs={3.5} sm={3} md={4} lg={3}>
                                  Company
                                </Grid>
                                <Grid item xs={8.5} sm={9} md={8} lg={9}>
                                  <Grid container>
                                    <Grid item xs={0.25}>

                                    </Grid>
                                    <Grid item xs={11.75}>
                                      Chronox AI Private Limited
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item>
                              <Grid container>
                                <Grid item xs={3.5} sm={3} md={4} lg={3}>
                                  Address
                                </Grid>
                                <Grid item xs={8.5} sm={9} md={8} lg={9}>
                                  <Grid container>
                                    <Grid item xs={0.25}>

                                    </Grid>
                                    <Grid item xs={11.75}>
                                      611,612, Satyamev Eminence, Science City Road, Gujarat, 380059, Thaltej, Ahmedabad 380059, Gujarat
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={8.5}
                      lg={3.5}
                      md={4}
                      sm={12}
                      xs={12}
                      className="lp-ft-right-content-item"
                    >
                      <Grid container className="lp-footer-ul-points-main-item">
                        <Grid item>
                          <ul className="lp-footer-ul-points">
                            {menuData?.Platform?.sections?.map((section, sectionIndex) => (
                              <>
                                <h3 style={{ color: '#FFFFFF' }}>{section.title}</h3>
                                {section.content.map((ele, index) => (
                                  <Grid item>
                                    <Grid container sx={{ gap: '5px' }}>
                                      <a
                                        href={ele.link} // Redirect to the correct link
                                        target="_blank"
                                        style={{ textDecoration: 'none', color: "white", width: '100%' }}
                                      >
                                        <span style={{ fontSize: '14px', fontWeight: "400", margin: '0px 0px 5px' }}>
                                          {ele.name}
                                        </span>
                                      </a>
                                    </Grid>
                                  </Grid>
                                ))}
                              </>
                            ))}
                          </ul>
                        </Grid>

                        <Grid item>
                          <ul className="lp-footer-ul-points">
                            {menuData?.Solutions?.sections?.map((section, sectionIndex) => (
                              <>
                                <h3 style={{ color: '#FFFFFF' }}>{section.title}</h3>
                                {section.content.map((ele, index) => (
                                  <Grid item>
                                    <Grid container sx={{ gap: '5px' }}>
                                      <a
                                        href={ele.link} // Redirect to the correct link
                                        target="_blank"
                                        style={{ textDecoration: 'none', color: "white", width: '100%' }}
                                      >
                                        <span style={{ fontSize: '14px', fontWeight: "400", margin: '0px 0px 5px' }}>
                                          {ele.name}
                                        </span>
                                      </a>
                                    </Grid>
                                  </Grid>
                                ))}
                              </>
                            ))}
                          </ul>
                        </Grid>

                        <Grid item>
                          <ul className="lp-footer-ul-points">
                            {menuData?.Industries?.sections?.map((section, sectionIndex) => (
                              <>
                                <h3 style={{ color: '#FFFFFF' }}>{section.title}</h3>
                                {section.content.map((ele, index) => (
                                  <Grid item>
                                    <Grid container sx={{ gap: '5px' }}>
                                      <a
                                        href={ele.link} // Redirect to the correct link
                                        target="_blank"
                                        style={{ textDecoration: 'none', color: "white", width: '100%' }}
                                      >
                                        <span style={{ fontSize: '14px', fontWeight: "400", margin: '0px 0px 5px' }}>
                                          {ele.name}
                                        </span>
                                      </a>
                                    </Grid>
                                  </Grid>
                                ))}
                              </>
                            ))}
                          </ul>
                        </Grid>
                      </Grid>
                    </Grid>


                  </Grid>
                </Grid>

                <Grid
                  item
                  xl={11}
                  lg={11}
                  md={12}
                  sm={12}
                  xs={12}
                  className="lp-ft-divider-item"
                >
                  <Divider className="lp-ft-divider" />
                </Grid>

                <Grid
                  item
                  xl={11}
                  lg={11}
                  md={12}
                  sm={12}
                  xs={12}
                  className="lp-ft-cp-item"
                >
                  <Grid container className="lp-ft-cp-container">
                    <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12}>
                      <span>
                        {" "}
                        Copyright &copy; {new Date().getFullYear()} Chronox AI Private Limited.
                        All Rights Reserved.
                      </span>
                    </Grid>
                    <Grid
                      item
                      xl={5.5}
                      lg={5.5}
                      md={5.5}
                      sm={5.5}
                      xs={12}
                      className="lp-ft-social-item"
                    >
                      <a href="https://twitter.com/" target="_blank" className="lp-ft-social-link1">
                        <img src={tweeterimg} alt="" />
                      </a>
                      <a href="https://www.linkedin.com/company/chronox-ai/" target="_blank" className="lp-ft-social-link">
                        <img src={linkedinimg} alt="" />
                      </a>
                      <a href="https://www.facebook.com/people/Chronox-AI/61562335443030/" target="_blank" className="lp-ft-social-link">
                        <img src={facebookimg} alt="" />
                      </a>
                      <a href="https://www.instagram.com/" target="_blank" className="lp-ft-social-link">
                        <img src={instagramimg} alt="" />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
    </>
  );
};

export default LandingFooter;
