import React from 'react'
import './BookAMeetingDialog.css'
import { Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

const BookAMeetingDialog = (props) => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container sx={{ justifyContent: 'end' }}>
                                <Grid item xs={1}
                                    sx={{
                                        position: 'absolute',
                                        background: 'black',
                                        padding: '5px',
                                        borderRadius: '50%',
                                        right: '20px',
                                        top: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CloseIcon
                                        sx={{ cursor: 'pointer', color: 'white' }}
                                        onClick={() => props.setFlagOpenBookaMeetingDemo(false)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <iframe
                                src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1sWU57vlIIxJ2Dej3cM1Va9WFeugHnh6WGn4KgLlPVege17yXHq0aPk6Ve54Y_wjCrCge1Mquj?gv=true"
                                frameborder="0"
                                style={{
                                    height: '80vh',
                                    width: '100%'
                                }}
                                title='Book a Meeting'
                            >
                            </iframe>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default BookAMeetingDialog 