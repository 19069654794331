import React, { useState } from 'react'
import './NavbarNastedMenu.css'
import { AppBar, Box, Button, Grid, Typography } from '@mui/material'
import EastIcon from '@mui/icons-material/East';
import { Link, useNavigate } from 'react-router-dom';

const NavbarNastedMenu = (props) => {
    const navigate = useNavigate();
    const { navbarHeight, menu, cancelClose, clearMenu } = props
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (idx) => {
        setHoveredIndex(idx);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    return (
        <>
            <AppBar
                component="nav"
                sx={{
                    padding: "20px",
                    margin: '20px auto',
                    alignItems: "center",
                    top: `${navbarHeight}px !important`,
                }}
                className="navbar-nested-menu"
                onMouseEnter={cancelClose}
                onMouseLeave={clearMenu}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container sx={{ justifyContent: 'space-between' }}>
                            {menu && menu?.sections?.map((section, sectionIndex) => (
                                <Grid item xs={12} key={sectionIndex}>
                                    <Grid container>
                                        {section?.title == '' ? (<></>) : (
                                            <Grid item xs={12} sx={{ marginBottom: '15px' }}>
                                                <Typography
                                                    variant="h6"
                                                    className='nav-menu-title'
                                                >
                                                    {section?.title}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Grid container sx={{ justifyContent: 'space-between' }}>
                                                {section.content.map((item, itemIndex) => {
                                                    const uniqueIndex = `${sectionIndex}-${itemIndex}`;
                                                    return (                                                       
                                                        <Grid item xs={5.8}
                                                            key={uniqueIndex}
                                                            sx={{
                                                                // margin: '0px',
                                                                margin: itemIndex < section.content.length - 1 ? '0px 0px 5px' : '0px',
                                                                transition: 'background-color 0.3s ease',
                                                                backgroundColor: hoveredIndex == uniqueIndex ? item.bgColor : "white",
                                                                borderRadius: '8px',
                                                                cursor: 'pointer',
                                                                padding: itemIndex < section.content.length - 1 ? '10px' : '10px'
                                                            }}
                                                            // onClick={() => navigate( item.navigationLink ? `/${item.navigationLink}` : '/')}
                                                            onMouseEnter={() => handleMouseEnter(uniqueIndex)}
                                                            onMouseLeave={handleMouseLeave}
                                                        >
                                                            <Link to={item.navigationLink ? `/${item.navigationLink}` : '/'} style={{textDecoration: "none"}}>
                                                                <Grid container sx={{ alignItems: 'start', justifyContent: 'space-between' }}>
                                                                    <Grid item
                                                                        className='nav-menu-img-item'
                                                                        sx={{
                                                                            background: hoveredIndex == uniqueIndex ? 'white' : item.bgColor,
                                                                            borderRadius: '5px'
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={item.image}
                                                                            alt={item.name}
                                                                            style={{
                                                                                width: '26px',
                                                                                height: '26px',
                                                                                objectFit: 'fill',
                                                                                display: 'flex',
                                                                                alignItems: 'center'
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item className='nav-menu-text-item'>
                                                                        <Grid container>
                                                                            <Grid item xs={12}>
                                                                                <Typography
                                                                                    variant='span'
                                                                                    className='nav-menu-text'
                                                                                    sx={{
                                                                                        color: hoveredIndex == uniqueIndex ? item.nameColor : 'black',
                                                                                    }}
                                                                                >
                                                                                    {item.name}
                                                                                    {item.chip &&
                                                                                        <Typography
                                                                                            variant='span'
                                                                                            sx={{
                                                                                                color: item.chipColor,
                                                                                                backgroundColor: item.chipBgColor,
                                                                                                fontSize: '10px',
                                                                                                fontWeight: 600,
                                                                                                marginLeft: "10px",
                                                                                                padding: '3px 10px',
                                                                                                borderRadius: '5px'
                                                                                            }}
                                                                                        >
                                                                                            Coming Soon
                                                                                        </Typography>
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography
                                                                                    variant='span'
                                                                                    className='nav-menu-desc'
                                                                                    sx={{
                                                                                        color: '#707070'
                                                                                    }}
                                                                                >
                                                                                    {item.description}
                                                                                </Typography>
                                                                            </Grid>
                                                                            {hoveredIndex === uniqueIndex && (
                                                                                <Grid item className='nav-menu-hovered-arrow'>
                                                                                    <EastIcon style={{ color: hoveredIndex == uniqueIndex ? item.nameColor : 'black', }} />
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </Link>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </AppBar>

        </>
    )
}

export default NavbarNastedMenu