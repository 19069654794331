import { Dialog, Grid } from '@mui/material'
import React, { useState } from 'react'
import './HealthCareComponents.css'
import Navbar from '../../../Pages/Navbar/Navbar'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CommonFormatImageContents from '../../CommonFormatImageContent/CommonFormatImageContents';
import demoImage1 from './../../../assests/LandingPage/commonSection1.svg'
import demoImage2 from './../../../assests/LandingPage/commonSection2.svg'
import demoImage3 from './../../../assests/LandingPage/commonSection3.svg'
import ChannelCardsComponent from './ChannelCardsComponent';
import FooterComponent from './FooterComponent';
import HeroSectionNew from '../../HeroSectionNew/HeroSectionNew';
import heroImage from './../../../assests/Industries/HealthCare/health-care-hero.svg'
import LandingFooter from '../../Footer/LandingFooter';
import ChatbotFAQs from '../../ChatbotFAQs/ChatbotFAQs';
import AiWhatsappScrollingSection from '../../AiWhatsappScrollingSection/AiWhatsappScrollingSection';
import ParaphraseOverlapFooter from '../../ParaphraserSections/ParaphraseOverlapFooter';
import AIChatbotSimplified from '../../AIChatbotSimplified/AIChatbotSimplified';
import BookAMeetingDialog from '../../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog';
import BookACalendlyDialog from '../../../Utils/GlobalDialog/BookACalendlyDialog/BookACalendlyDialog';

const HealthCareComponent = (props) => {
    const { flagBanner, setFlagBanner } = props;
    const [pageName, setPageName] = useState('Chatbot');
    const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);


    const [chatbotFaqs, setChatbotFaqs] = useState([
        {
            question: "How does a WhatsApp chatbot work?",
            answer: "A WhatsApp Chatbot uses AI and predefined responses to interact with users, answer queries, and perform tasks, providing a seamless experience.",
        },
        {
            question: "What are the benefits of using WhatsApp chatbots?",
            answer: "WhatsApp Chatbots improve efficiency, offer 24/7 support, and personalize interactions, leading to enhanced customer satisfaction and business growth."
        },
        {
            question: "Are WhatsApp chatbots secure?",
            answer: "WhatsApp Chatbots prioritize security, ensuring data protection and compliance with WhatsApp's policies.",
        },
        {
            question: "What can I ask the chatbot?",
            answer: "You can ask questions related to our services, product details, pricing, account management, support queries, and more. Our chatbot is designed to provide you with quick information and assist you in navigating our website."
        },
        {
            question: "How does the chatbot understand my questions?",
            answer:
                "Our chatbot uses advanced AI algorithms to understand natural language, which means you can ask questions just as you would to a human."
        },
    ])

    const heroContentData = {
        contentID: 1,
        contentHeading1: "Personalized AI for Healthcare:",
        contentHeading2: " 24/7 Support That Cuts Costs and Simplifies Care",
        contentSubHeading: "Keep your focus on patients while AI handles the rest. From round-the-clock support to effortless scheduling and follow-ups. No complicated setups—just smart, flexible automation designed to fit seamlessly into your practice.",
        buttonData: [
            {
                buttonStyle: "dark",
                buttonText: "Get Started",
                buttonlink: process.env.REACT_APP_REDIRECT_URL,
            },
            {
                buttonStyle: "light",
                buttonText: "Book a Demo",
                buttonlink: () => setFlagOpenBookaMeetingDemo(true)
            }
        ],
        listIcon: <CheckCircleOutlineIcon sx={{ fontSize: "20px" }} />,
        flagListData: false,
        // listData: [
        //     "Lorem ipsum dolor sit amet consectetur. Ornare varius risus erat mi.",
        //     "Lorem ipsum dolor sit amet consectetur. Ornare varius risus erat mi.",
        //     "Lorem ipsum dolor sit amet consectetur. Ornare varius risus erat mi."
        // ],
        flagFloatImage: false,
        contentImageSection: [
            {
                imgID: 1,
                imgName: "Image 1",
                imgLink: heroImage,
            },
        ]
    }

    const healthCareData = [
        {
            healthCareID: 1,
            heading: "24/7 Patient Assistance",
            subHeading: "Care Whenever It’s Needed",
            subDataIcon: <CheckCircleOutlineIcon sx={{ display: "flex", fontSize: "17px", verticalAlign: "middle" }} />,
            classificationData: "Never leave your patients waiting. With automated support available 24/7, patients receive timely responses, even outside office hours, ensuring their needs are met instantly.",
            subData: [
                "Provide immediate responses to patient questions and concerns.",
                "Automatically handle appointment bookings, lab results, and prescription queries.",
                "Escalate to human support seamlessly when needed."
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1,
        },
        {
            healthCareID: 2,
            heading: "Simplify Appointment Scheduling & Rescheduling",
            subHeading: "Manage Appointments with Zero Downtime",
            subDataIcon: <CheckCircleOutlineIcon sx={{ display: "flex", fontSize: "17px" }} />,
            classificationData: "Make appointment booking and rescheduling a breeze for your patients. Chronox AI automates the entire process, giving patients the flexibility to manage their own time.",
            subData: [
                "AI agents handle appointment requests and rescheduling instantly.",
                "Real-time updates and reminders to minimize no-shows.",
                "Patients can choose their preferred communication method (SMS, email, chat)."
            ],
            buttonData: "Learn more about Proactive Messaging",
            imgData: demoImage2,
        },
        {
            healthCareID: 3,
            heading: "Seamless Patient Onboarding & On-Demand Support",
            subHeading: "Get Patients Started Without the Hassle",
            subDataIcon: <CheckCircleOutlineIcon sx={{ display: "flex", fontSize: "17px" }} />,
            classificationData: "Expand your patient base effortlessly. Automate personalized outreach to build trust, nurture relationships, and turn leads into loyal patients, without the manual workload.",
            subData: [
                "Send personalized messages for follow-ups, appointment reminders, or new services.",
                "Engage with potential patients across multiple platforms like email, SMS, and chat.",
                "Convert website visitors into booked appointments through real-time conversations."
            ],
            buttonData: "Read more about Customer Care",
            imgData: demoImage3,
        },
        {
            healthCareID: 4,
            heading: "Grow Brand Awareness & Acquire More Patients",
            subHeading: "Automatically Reach and Engage",
            subDataIcon: <CheckCircleOutlineIcon sx={{ display: "flex", fontSize: "17px" }} />,
            classificationData: "Expand your patient base effortlessly. Automate personalized outreach to build trust, nurture relationships, and turn leads into loyal patients, without the manual workload.",
            subData: [
                "Send personalized messages for follow-ups, appointment reminders, or new services.",
                "Engage with potential patients across multiple platforms like email, SMS, and chat.",
                "Convert website visitors into booked appointments through real-time conversations."
            ],
            buttonData: "Read more about Customer Care",
            imgData: demoImage3,
        }
    ]

    return (


        <Grid container>
            {
                flagOpenBookaMeetingDemo &&
                <Dialog open={flagOpenBookaMeetingDemo}
                    maxWidth='lg'
                    sx={{ '&.MuiPaper-root': { width: '100%' } }}
                    className="iframe-dialog"
                >
                    {/* <BookAMeetingDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    /> */}
                    <BookACalendlyDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    />
                </Dialog>
            }
            <Navbar flagBanner={flagBanner} setFlagBanner={setFlagBanner} />

            <Grid item xs={12} mt={{ xs: flagBanner ? 10 : 0, md: flagBanner ? 8 : 0 }} sx={{ overflowX: "hidden" }}>
                <Grid container>
                    <Grid item xs={12} className='healthcare-new-hero-section'>
                        <HeroSectionNew content={heroContentData} />
                    </Grid>

                    <Grid item xs={9} className='brsw-new-expertise-section-main-item'>
                        <AiWhatsappScrollingSection />
                    </Grid>

                    {
                        healthCareData.map((data, index) =>
                            <Grid item xs={12} className='common-format-new-head-section-main-item' key={index}>
                                <CommonFormatImageContents content={data} />
                            </Grid>
                        )
                    }

                    {/* <Grid item xs={12} className='common-format-new-card-section'>
                        <ChannelCardsComponent />
                    </Grid> */}

                    {/* <Grid item xs={12} className='common-format-new-footer-section'>
                        <FooterComponent />
                    </Grid> */}

                    <Grid item xs={12} className='chatbot-faq-section'>
                        <ChatbotFAQs faqData={chatbotFaqs} />
                    </Grid>

                    <Grid item xs={12} className='brsw-new-aisimplified-section-main-item'>
                        <AIChatbotSimplified />
                    </Grid>

                    <Grid item xs={12} className='pr-overlap-item'>
                        <ParaphraseOverlapFooter pageName={pageName} />
                    </Grid>

                    <Grid item xs={12} className='brsw-footer-component-main-item'>
                        <LandingFooter />
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default HealthCareComponent
