import React, { useEffect, useState } from 'react';
import './ChatbotFAQs.css';
import {
    Grid,
    Typography,
    Accordion, AccordionDetails, AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ChatbotFAQs = (props) => {
    const [expanded, setExpanded] = React.useState([]);

    const { faqData } = props
    const [faqDetails, setFaqDetails] = useState([])

    useEffect(() => {
        setFaqDetails(faqData)
    }, [faqData])

    // const faqData = [
    //     {
    //         question: "How does a WhatsApp chatbot work?",
    //         answer: "A WhatsApp Chatbot uses AI and predefined responses to interact with users, answer queries, and perform tasks, providing a seamless experience.",
    //     },
    //     {
    //         question: "What are the benefits of using WhatsApp chatbots?",
    //         answer: "WhatsApp Chatbots improve efficiency, offer 24/7 support, and personalize interactions, leading to enhanced customer satisfaction and business growth."
    //     },
    //     {
    //         question: "Are WhatsApp chatbots secure?",
    //         answer: "WhatsApp Chatbots prioritize security, ensuring data protection and compliance with WhatsApp's policies.",
    //     },
    //     {
    //         question: "What can I ask the chatbot?",
    //         answer: "You can ask questions related to our services, product details, pricing, account management, support queries, and more. Our chatbot is designed to provide you with quick information and assist you in navigating our website."
    //     },
    //     {
    //         question: "How does the chatbot understand my questions?",
    //         answer:
    //             "Our chatbot uses advanced AI algorithms to understand natural language, which means you can ask questions just as you would to a human."
    //     },
    // ];


    const handleChange = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded([...expanded, panel]);
        } else {
            setExpanded(expanded.filter(item => item !== panel));
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xl={8} lg={8} md={10} sm={11} xs={11} sx={{ margin: '0 auto' }}>
                    <Grid container>
                        {/* <Grid item xs={12} className='pr-ai-question-item'>
                            <Typography variant='span' className='pr-ai-global-head-text'>Questions?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ borderBottomWidth: "1px", borderColor: "#6A097D" }} />
                        </Grid> */}
                        <Grid item xs={12}>
                            <Grid container alignItems={"center"} direction={"column"}>
                                <Grid item xs={12} sx={{ margin: "50px 0px 10px", textAlign: "center" }} >
                                    <Typography variant='h2' className='chfq-ai-faq-main-head'>Frequently Asked Questions</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ margin: "0px 0px 15px 0px", textAlign: "center" }}>
                                    <Typography variant='h4' className='chfq-ai-faq-sub-head'>Got a question? We’re here to answer! If you don’t see your question here, drop us a line on our mail.</Typography>
                                </Grid>
                                <Grid item xs={12} className='chfq-faq-accordion-item'>

                                    {faqDetails?.map((item, index) => (
                                        <Accordion
                                            key={index}
                                            expanded={expanded.includes(`panel${index}`)}
                                            onChange={handleChange(`panel${index}`)}
                                            className="chfq-ai-faq-accordion"

                                        >
                                            <AccordionSummary
                                                className="chfq-ai-expand-accordion-summary"
                                                expandIcon={<ExpandMoreIcon className="chfq-expand-icon chfq-ai-expand-accordion-summary" />}
                                                aria-controls={`panel${index}bh-content`}
                                                id={`panel${index}bh-header`}
                                            >
                                                <h6 className="chfq-faq-ques-head">{item.question}</h6>
                                            </AccordionSummary>
                                            <AccordionDetails className="chfq-faq-details-section">
                                                {item.answer.split('\n').map((it) => {
                                                    return (
                                                        <p className="chfq-faq-ans-text" sx={{ marginBottom: "1rem" }} key={it}>
                                                            {it}
                                                        </p>
                                                    )
                                                })}
                                                {/* <Typography>{item.answer}</Typography> */}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChatbotFAQs