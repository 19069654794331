import { Button, Dialog, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import './voiceAIComingSoon.css'
import './../../assests/Styles/global.css'
import audioImage from './../../assests/Pages/VoiceAI/voice-icon-image.svg'
import IconsColor from '../../Utils/Global/iconsColor'
import BookACalendlyDialog from '../../Utils/GlobalDialog/BookACalendlyDialog/BookACalendlyDialog'

const VoiceAIComingSoon = (props) => {
    const { flagBanner, setFlagBanner } = props
    const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);

    const [fadeIn, setFadeIn] = useState(false);
    useEffect(() => {
        setFadeIn(true);
    }, []);

    return (
        <>
            <Grid container>
                <Navbar setFlagBanner={setFlagBanner} flagBanner={flagBanner} />
                <Grid item xs={12} mt={flagBanner ? 7 : 0} className='voice-ai-global-page-item'>
                    <Grid container className='voice-ai-global-page-container' justifyContent={"center"} alignItems={"center"}>
                        <Grid item xs={12} md={10} lg={8} xl={6} paddingBottom={{xs: "30px", sm: "60px"}}>
                            <Grid container textAlign={"center"} justifyContent={"center"}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent={"center"}>
                                        <Grid item xs={12} className='voice-ai-image-button-container'>
                                            <Grid container gap={3} alignItems={"center"} justifyContent={"center"} flexDirection={{xs: "column-reverse", sm: "row"}}>
                                                <Grid item className={fadeIn ? 'voice-ai-image-animation-style fade-style' : 'voice-ai-image-animation-style'}>
                                                    <img src={audioImage} className='voice-ai-image' alt='voice-ai'/>
                                                </Grid>
                                                <Grid item marginLeft={{xs: 0, md: 3, lg: 4, xl: 5}} className={fadeIn ? 'coming-soon-button-container fade-style' : 'coming-soon-button-container'}>
                                                    <Button aria-label='Coming Soon' disableRipple className='global-dark-button-gradient-style' startIcon={<IconsColor iconName="star" width="22" height="22"/>}>Coming Soon</Button>
                                                </Grid>
                                            </Grid>                                           
                                        </Grid>
                                        <Grid item className={fadeIn ? 'voice-ai-heading-style textWrtingAnimationStyle' : 'voice-ai-heading-style'} component="h1" xs={12}>
                                            <span className='typingEffect'>Voice AI</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={10} sm={7.5} md={8} lg={9} xl={10} className='voice-ai-text-style voice-ai-image-animation-style'>
                                    Enhance customer interactions with real time Voice AI.
                                </Grid>
                                <Grid item xs={12} mt={{xs: 3,sm: 5}} className='voice-ai-image-animation-style'>
                                    <Button
                                        aria-label='Schedule a Meeting'
                                        className='global-dark-button-style'
                                        onClick={() => {
                                            setFlagOpenBookaMeetingDemo(true)
                                        }}
                                    >
                                        Schedule a meeting
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {flagOpenBookaMeetingDemo && (
                <Dialog open={flagOpenBookaMeetingDemo}
                    maxWidth='lg'
                    sx={{ '&.MuiPaper-root': { width: '100%' } }}
                    className="iframe-dialog"
                >
                    {/* <BookAMeetingDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    /> */}
                    <BookACalendlyDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    />
                </Dialog>
            )}
        </>
    )
}

export default VoiceAIComingSoon
