import { Button, DialogContent, Divider, Grid, IconButton, InputLabel, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react'
import ChronoxPrimaryLogo from "../../../assests/GlobalImages/New-Chronox-Logo.svg"
import './UserAuthenticationDialog.css'
import { Close } from '@mui/icons-material';
import googleIcon from '../../../assests/ChatbotPricing/google.svg'
import { Context as mainContext } from '../../../Context/MainContext/GlobalContext'
import ReCAPTCHA from 'react-google-recaptcha';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OtpInput from 'react18-input-otp';
import editIcon from '../../../assests/ChatbotPricing/cbc-dialog-field-edit-icon.png'
import { createChatbotPlanSubscriptionAPI } from '../../../Api/ChatbotPricing/ChatbotAPI';
import { RegisterAPI, ResendLoginOtpAPI, VerifyLoginOtpAPI } from '../../../Api/Global/GlobalAPI';

const UserAuthenticationDialog = (props) => {

    const {
        setLoader,
        setUserDetails,
        setFlagOpenAuthenticationDialog,
    } = useContext(mainContext)

    const {
        userDetails
    } = useContext(mainContext).state

    const {
        setAlertMessage,
        selectedAmountAndHours,
        selectedAmountAndImages,
        chatbotSubscriptionPlanDetails
    } = props

    // console.log("userDetailssssssssssssssssssssssssssss", userDetails);
    // console.log("selectedAmountAndHours", selectedAmountAndHours);
    // console.log("selectedAmountAndImages", selectedAmountAndImages);
    // console.log("chatbotSubscriptionPlanDetails", chatbotSubscriptionPlanDetails);


    const [flagShowVarificationBlock, setFlagShowVarificationBlock] = useState(false)
    const [seconds, setSeconds] = useState(60)
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60;

    const [flagShowRecaptchaLogin, setFlagShowRecaptchaLogin] = useState(false)
    const [flagEmailValid, setFlagEmailValid] = useState(true);
    const [user, setUser] = useState({
        email: "",
        google_recaptcha_token: "",
    });
    const [flagTimerActive, setFlagTimerActive] = useState(false);
    const [recaptchaDetails, setRecaptchaDetails] = useState(null);
    const [flagContinueWithGoogle, setFlagContinueWithGoogle] = useState(false);
    const [flagLogin, setFlagLogin] = useState(false);


    // varification
    const [otp, setOtp] = useState("");
    const [invalidOtp, setInvalidOtp] = useState(false);
    const [isTimerActive, setIsTimerActive] = useState(false);

    const SiteKey = "6Ld0tIgnAAAAAM6flWQfozA41EW4o13PRW78EILx";
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const recaptchaRef = useRef(null);
    const currentUrl = window.location.href

    // Function to handle resize and set dialog content min width
    function handleResize() {
        const dialogContent = document.getElementById("get-started-dialog-content");

        if (dialogContent) {
            const parent = dialogContent.parentElement;
            if (window.innerWidth >= 550) {
                parent.style.minWidth = '500px';
            } else if (window.innerWidth >= 450) {
                parent.style.minWidth = '450px';
            } else if (window.innerWidth >= 400) {
                parent.style.minWidth = '365px';
            } else {
                parent.style.minWidth = '285px';
            }
        }
    }

    // Call handleResize once to set initial sizes
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Add cleanup function for removing the resize event listener
    window.addEventListener('unload', () => {
        window.removeEventListener('resize', handleResize);
    });


    const handleKeyDown = async (event) => {
        if (event.keyCode === 13 && otp.length === 6) {
            await handleLogin(otp);
        }
    }

    useEffect(() => {
        const onKeyDown = (event) => {
            handleKeyDown(event);
        };
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [otp]);

    useEffect(() => {
        let timerId
        if (flagTimerActive && seconds > 0) {
            timerId = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
        }
        if (seconds === 0) {
            setFlagTimerActive(false);
        }
        return () => clearInterval(timerId);
    }, [flagTimerActive, seconds]);


    const handleResetTimer = () => {
        setFlagTimerActive(false);
        setSeconds(60);
    };

    const handleStartTimer = () => setFlagTimerActive(true);


    const handleCreateUser = async (value) => {

        setRecaptchaDetails(value)
        const createPayload = {
            google_recaptcha_token: value,
            user_email: user.email
        };
        setLoader(true)
        RegisterAPI(createPayload,
            (res) => {
                if (res.data.status) {
                    console.log("success response of register api", res);
                    if (res.data.data && 'message' in res.data.data) {
                        let message = res?.data?.data?.message
                        setAlertMessage({
                            status: true,
                            message: message,
                            error: false
                        })
                        handleResetTimer();
                        handleStartTimer();
                        setFlagShowVarificationBlock(true);
                        setLoader(false)
                    }
                }
                else {
                    console.log("error response of register api", res);
                    recaptchaRef.current?.reset();
                    if (res?.data?.error?.message) {
                        setAlertMessage({
                            status: true,
                            message: res?.data?.error?.message,
                            error: true
                        })
                    }
                    else {
                        setAlertMessage({
                            status: true,
                            message: "Something Went Wrong!, Please try again later",
                            error: true
                        })
                    }
                    setLoader(false)
                }
            }, (err) => {
                console.log("register api error", err);
                setAlertMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true
                })
                setLoader(false)
            })
    };

    const handleLogin = (otp) => {
        const payload = {
            user_email: user.email,
            authentication_code: otp,
        };

        setLoader(true)
        VerifyLoginOtpAPI(payload,
            (res) => {
                console.log("response of VerifyLoginOtpAPI", res);
                if (res.data.status) {
                    setFlagLogin(true)
                    handleCraeteChatbotSubscription("login")
                }
                else {
                    console.log("response false of VerifyLoginOtpAPI");
                    if (res?.data?.error?.message) {
                        setAlertMessage({
                            status: true,
                            message: res?.data?.error?.message,
                            error: true
                        })
                        setLoader(false)
                    }
                    else {
                        setAlertMessage({
                            status: true,
                            message: "Something Went Wrong!, Please try again later",
                            error: true
                        })
                        setLoader(false)
                    }
                }
            },
            (err) => {
                console.log("error of VerifyLoginOtpAPI", err);
                setAlertMessage({
                    status: true,
                    message: "Something Went Wrong!, Please try again later",
                    error: true
                })
                setLoader(false)
            }
        )
    }

    useEffect(() => {
        console.log("flagLogin", flagLogin);
    }, [flagLogin])

    const handleResendOTP = () => {

        const payload = {
            user_email: user.email,
            google_recaptcha_token: user.google_recaptcha_token,
            resend_flag: true
        };

        setLoader(true)
        ResendLoginOtpAPI(payload,
            (res) => {
                console.log("response of ResendLoginOtpAPI", res);
                if (res.data.status) {
                    console.log("response true of ResendLoginOtpAPI");
                    handleResetTimer();
                    handleStartTimer();
                    setFlagShowVarificationBlock(true);
                    setAlertMessage({
                        status: true,
                        message: res?.data?.data?.message,
                        error: false
                    })
                    setLoader(false)
                }
                else {
                    console.log("response false of ResendLoginOtpAPI");
                    recaptchaRef.current?.reset();
                    if (res?.data?.error?.message) {
                        setAlertMessage({
                            status: true,
                            message: res?.data?.error?.message,
                            error: true
                        })
                        setLoader(false)
                    }
                    else {
                        setAlertMessage({
                            status: true,
                            message: "Something Went Wrong!, Please try again later",
                            error: true
                        })
                        setLoader(false)
                    }
                    setUser({ ...user, google_recaptcha_token: "" })
                }
            },
            (err) => {
                console.log("error of ResendLoginOtpAPI", err);
                setAlertMessage({
                    status: true,
                    message: "Something Went Wrong!, Please try again later",
                    error: true
                })
                setLoader(false)
            })
    }

    const handleContinueWithGoogle = async () => {

        const gc_integration_type_id = 1;
        const currentUrl = encodeURIComponent(window.location.href);
        // const currentUrl = encodeURIComponent(window.location.href);

        try {
            setLoader(true)
            const response = await fetch(`${process.env.REACT_APP_API_CALL_URL2}apiIntegration/api/oauth/outbound/auth-url?currentUrl=${currentUrl}&gc_integration_type_id=${gc_integration_type_id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });

            if (!response.ok) {
                setLoader(false)
                setFlagOpenAuthenticationDialog(false);
                throw new Error("Failed to fetch authentication URL");
            }

            const data = await response.json();
            if (data?.status) {
                console.log("Authentication successful", data);
                // setFlagContinueWithGoogle(true)
                setLoader(false)
                let url = data.data;
                console.log("url", url);
                window.location.href = url;
                // 
                // setTimeout(async () => {
                //     await UserMeAPI(
                //         async (res) => {
                //             
                //             if (res?.data?.status) {
                //                 console.log("response of user me API", res);
                //                 setUserDetails({
                //                     flagUserLoggedIn: res?.data?.status,
                //                     userInfo: res?.data?.data[0]
                //                 })
                //                 await handleCraeteChatbotSubscription();
                //             }
                //             else {
                //                 setUserDetails({
                //                     flagUserLoggedIn: false,
                //                     userInfo: {}
                //                 })
                //             }
                //         },
                //         (err) => {
                //             console.log("error of user me API", err);
                //             setUserDetails({
                //                 flagUserLoggedIn: false,
                //                 userInfo: {}
                //             })
                //         })
                // }, 4000);

                // setTimeout(async () => {
                //     await handleCraeteChatbotSubscription();
                // }, 4000);
            }
            else {
                console.log("Authentication failed", data);
                if (data?.data?.error?.message) {
                    setAlertMessage({
                        status: true,
                        message: data?.data?.error?.message,
                        error: true
                    })
                    setLoader(false)
                }
                else {
                    setAlertMessage({
                        status: true,
                        message: "Something Went Wrong!, Please try again later",
                        error: true
                    })
                    setLoader(false)
                }
                setFlagOpenAuthenticationDialog(false);
            }
        }
        catch (error) {
            console.log("Error in authentication process", error);
            setAlertMessage({
                status: true,
                message: error.message || "Something went wrong!",
                error: true
            });
            setFlagOpenAuthenticationDialog(false);
            setLoader(false)
        }
    }

    const handleCraeteChatbotSubscription = async (flag) => {

        let payload = {
            chatbot_plan_id: chatbotSubscriptionPlanDetails.chatbot_plan_id,
        };

        let addonFeatures = [];

        if (selectedAmountAndImages.imagesId) {
            addonFeatures.push({
                chatbot_addon_features_price_id: selectedAmountAndImages.imagesId
            });
        }

        if (selectedAmountAndHours.hoursId) {
            addonFeatures.push({
                chatbot_addon_features_price_id: selectedAmountAndHours.hoursId
            });
        }

        if (addonFeatures.length > 0) {
            payload.chatbot_addon_features = addonFeatures;
        }
        setLoader(true)
        await createChatbotPlanSubscriptionAPI(payload,
            (res) => {

                if (res?.data?.status) {

                    console.log("response true of createChatbotPlanSubscriptionAPI", res);
                    setAlertMessage({
                        status: true,
                        message: res?.data?.message,
                        error: false
                    })
                    setLoader(false)
                    setFlagOpenAuthenticationDialog(false);
                    if (flag == "login") {
                        window.open(`${process.env.REACT_APP_CHATBOT_REDIRECT_URL}`, "_self")
                    }

                }
                else {
                    console.log("response false of createChatbotPlanSubscriptionAPI", res);
                    if (res?.data?.error?.message) {
                        setAlertMessage({
                            status: true,
                            message: res?.data?.error?.message,
                            error: true
                        })
                        setLoader(false)
                    }
                    else {
                        setAlertMessage({
                            status: true,
                            message: "Something Went Wrong!, Please try again later",
                            error: true
                        })
                        setLoader(false)
                    }
                    setFlagOpenAuthenticationDialog(false);
                }
            },
            (err) => {
                console.log("error response false of createChatbotPlanSubscriptionAPI", err);
                setAlertMessage({
                    status: true,
                    message: "Something Went Wrong!, Please try again later",
                    error: true
                })
                setLoader(false)
                setFlagOpenAuthenticationDialog(false);
            })

    }

    return (
        <>
            <div id="get-started-dialog-content">
                <Grid container sx={{ position: "relative" }}>
                    {flagShowVarificationBlock == false ?
                        (
                            <>
                                <Grid item xs={12}>
                                    <Grid container sx={{ padding: "30px 40px" }}>
                                        <Grid item sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                                            <Close
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setFlagOpenAuthenticationDialog(false)
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sx={{ margin: '10px 0px 40px' }}>
                                            <Grid container sx={{ justifyContent: "center" }}>
                                                <img
                                                    src={ChronoxPrimaryLogo}
                                                    alt="logo"
                                                    style={{ width: '180px', height: 'auto' }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container direction={"column"} sx={{ width: "100%", marginInline: "auto" }}>
                                                {!flagShowRecaptchaLogin &&
                                                    <Grid item xs={12}>
                                                        <Button onClick={handleContinueWithGoogle} className={"continueWithButton"}>
                                                            <img src={googleIcon} alt="Google Icon" style={{ margin: '0px 10px' }} />
                                                            <div style={{ flex: 1, textAlign: 'center', marginLeft: "-12px" }}>
                                                                Continue with Google
                                                            </div>
                                                        </Button>
                                                    </Grid>
                                                }

                                                {(flagShowRecaptchaLogin) && (
                                                    <>


                                                        <Grid item>
                                                            <Grid container direction="column">
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        sx={{ fontSize: "15px", fontWeight: "500", marginBottom: "10px", textAlign: "center" }}>
                                                                        Solve recaptcha to continue
                                                                    </Typography>
                                                                </Grid>

                                                                <Grid item sx={{ justifyContent: "center", display: "flex" }}>
                                                                    <Button className={'recaptchaBlockPopup'}>
                                                                        <ReCAPTCHA
                                                                            className={"mainRecaptchaBlock"}
                                                                            ref={recaptchaRef}
                                                                            sitekey={SiteKey}
                                                                            onChange={(value) => {
                                                                                handleCreateUser(value);
                                                                            }}
                                                                        />
                                                                    </Button>
                                                                </Grid>

                                                                <Grid item>
                                                                    <Grid container
                                                                        onClick={() => { setFlagShowRecaptchaLogin(false) }}
                                                                        sx={{ position: "relative", marginTop: "20px", alignItems: 'center', justifyContent: 'start', cursor: 'pointer' }}
                                                                    >
                                                                        <Grid item sx={{ position: "absolute", top: "5%", left: 0 }}>
                                                                            <ArrowBackIcon sx={{ color: "#7A7A7A", height: "22px", width: "22px" }} />
                                                                        </Grid>

                                                                        <Grid item>
                                                                            <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "#7A7A7A", marginLeft: "25px" }}>
                                                                                &nbsp;Back
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )
                                                }

                                                {(!flagShowRecaptchaLogin) &&
                                                    <Grid item xs={12}>
                                                        <Divider
                                                            sx={{
                                                                width: '100%',
                                                                borderColor: "#6A6A6A",
                                                                marginTop: '9px',
                                                                borderStyle: "unset",
                                                                fontSize: '14px',
                                                                fontWeight: '500'
                                                            }}
                                                        >
                                                            OR
                                                        </Divider>
                                                    </Grid>
                                                }

                                                {!(flagShowRecaptchaLogin) &&
                                                    <>
                                                        <Grid item xs={12} sx={{ margin: '20px 0px' }}>
                                                            <Grid container>
                                                                <InputLabel sx={{ fontWeight: "600", color: "#4C4A4D", fontSize: "15px", }}>Email</InputLabel>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        className={"auth-email-textfield custom-no-border"}
                                                                        required
                                                                        fullWidth
                                                                        id="email"
                                                                        name="email"
                                                                        placeholder="Enter Email"
                                                                        autoComplete="email"
                                                                        autoFocus
                                                                        value={user.email}
                                                                        onChange={(e) => {
                                                                            setUser({ ...user, email: e.target.value });
                                                                            // setError(emptyErrorObj);
                                                                            setFlagEmailValid(true)
                                                                        }}
                                                                        onBlur={() => {
                                                                            user.email.length > 0 &&
                                                                                setFlagEmailValid(emailRegex.test(user.email));
                                                                        }}
                                                                        error={!flagEmailValid}
                                                                        helperText={
                                                                            !flagEmailValid && "Please enter a valid Email Address"
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                }

                                                {!(flagShowRecaptchaLogin) &&
                                                    <Grid item xs={12}>
                                                        <Button
                                                            fullWidth
                                                            disabled={user.email !== "" && flagEmailValid ? false : true}
                                                            className="auth-continue-btn"
                                                            onClick={() => {
                                                                setFlagShowRecaptchaLogin(true)
                                                            }}
                                                        >Continue</Button>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <Grid container sx={{ padding: "30px 40px" }}>
                                        <Grid item sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                                            <Close
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setFlagOpenAuthenticationDialog(false)
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sx={{ margin: '10px 0px 40px' }}>
                                            <Grid container sx={{ justifyContent: "center" }}>
                                                <img
                                                    src={ChronoxPrimaryLogo}
                                                    alt="logo"
                                                    style={{ width: '180px', height: 'auto' }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} sx={{ marginBottom: '5px' }}>
                                                    <InputLabel sx={{ fontWeight: "600", color: "#4C4A4D", fontSize: "15px", }} >Email</InputLabel>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        value={user.email}
                                                        sx={{ '& .MuiInputBase-root': { paddingRight: "0px !important" } }}
                                                        InputProps={{
                                                            endAdornment: flagShowVarificationBlock && (
                                                                <IconButton
                                                                    className={"editIconStyle"}
                                                                // onClick={handleFlagOtp}
                                                                >
                                                                    {flagShowVarificationBlock ? (
                                                                        <img src={editIcon} alt="Logo" width="17px" height="17px" />
                                                                    ) : (<></>)
                                                                    }
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        // onChange={handleLogin}
                                                        disabled={flagShowVarificationBlock}

                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sx={{ margin: "25px 0px !important", }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant='span'
                                                        sx={{ fontSize: "15px", fontWeight: "600", color: "#4C4A4D", }}
                                                    >
                                                        Verification Code
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant='span'
                                                        style={{ fontSize: "13px", color: "#7A7A7A", fontWeight: "500", }}>
                                                        Sent to&nbsp;
                                                        {user.email}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item sx={{ marginBottom: "40px" }}>
                                            <OtpInput
                                                value={otp}
                                                onChange={(value) => {
                                                    setOtp(value);
                                                    setInvalidOtp(false);
                                                }}
                                                isInputNum={true}
                                                shouldAutoFocus={true}
                                                numInputs={6}
                                                inputStyle={
                                                    invalidOtp ? 'otpInputError' : 'inputOtpStyle'
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button
                                                variant='contained'
                                                fullWidth
                                                size='large'
                                                className='verifucation-login-btn'
                                                disabled={otp.length < 6}
                                                onClick={() => {
                                                    handleLogin(otp)
                                                }}
                                            >Login</Button>
                                        </Grid>

                                        <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                            <Grid container>
                                                {seconds > 0 || minutes > 0 ? (
                                                    <Grid item xs={12}>
                                                        <a style={{ fontSize: "15px", color: "color: rgb(76, 74, 77);", fontWeight: "500" }}>
                                                            {" "}
                                                            Haven’t received code? Resend code in{" "}
                                                        </a>

                                                        <a style={{ fontSize: "14px", color: "rgba(30, 136, 229, 1)", marginRight: "20px" }}>
                                                            {`${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`}
                                                        </a>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12}>
                                                        <a style={{ fontSize: "15px", color: "#4C4A4D", fontWeight: "500", cursor: 'pointer' }}>
                                                            {" "}
                                                            Haven’t received code?
                                                            <span
                                                                style={{ color: "#6A097D", marginLeft: '5px' }}
                                                                onClick={handleResendOTP}
                                                            >
                                                                Resend
                                                            </span>
                                                        </a>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container
                                                sx={{ cursor: 'pointer', alignItems: 'center', position: "relative", marginTop: "20px", gap: "5px" }}
                                                onClick={() => { setFlagShowVarificationBlock(false) }}
                                            >
                                                <Grid item>
                                                    <ArrowBackIcon sx={{ color: "#7A7A7A", height: "22px", width: "22px", verticalAlign: 'sub' }} />
                                                </Grid>

                                                <Grid item>
                                                    <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "#7A7A7A", }}>
                                                        Back
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }
                </Grid>
            </div>

        </>
    )
}


export default UserAuthenticationDialog;