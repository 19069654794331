import React from 'react'
import './CommonFormatImageContents.css'
import { Button, Grid } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CommonFormatImageContents = (props) => {
    const {content} = props
    return (
        <Grid item className='common-format-main-item-section'>
            <Grid container justifyContent={"center"}>
                <Grid item xl={9} lg={10.5} md={11} sm={11} xs={12} className='common-format-main-section'>
                    <Grid container direction={content.healthCareID %2 == 0 ? "row-reverse" : "row"} flexWrap={{xs: "wrap", md: "nowrap"}} gap={5} alignItems={"center"}>
                        <Grid item sx={{width: "100%"}}>
                            <Grid container direction={"column"} gap={{xs:3, md:3}}>
                                <Grid item>
                                    <h2 className='common-format-heading-text-style'>{content.heading}</h2>
                                    <h2 className='common-format-heading2-text-style'>{content.subHeading}</h2>
                                </Grid>
                                <Grid item>
                                    <p className='common-format-classification-text-style'>{content.classificationData}</p>
                                </Grid>
                                <Grid item>
                                    {content.subData.map((sub, index) => 
                                        <Grid container flexWrap={"nowrap"} gap={"12px"} mb={"10px"} key={index}>
                                            <Grid item mt={"3px"}>{content.subDataIcon}</Grid>
                                            <Grid item className='common-format-sub-heading-text-style'><p>{sub}</p></Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                {/* <Grid item>
                                    <Button className='common-format-button-text-style' endIcon={<ArrowForwardIcon />}>{content.buttonData}</Button>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item sx={{width: "100%"}} className={content.healthCareID %2 == 0 ? 'common-format-left-image-style' : 'common-format-right-image-style'}>
                            <img src={content.imgData} className='common-format-image-style'/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CommonFormatImageContents
