import React, { useContext, useEffect, useState } from 'react'
import './ChatbotPricingCardSection.css'
import { Box, Button, Card, CardContent, Chip, Divider, Grid, MenuItem, Select, Typography, useMediaQuery, Dialog, Snackbar, Alert, Checkbox, Radio, FormControl, RadioGroup, FormControlLabel } from '@mui/material'
import checkmarkLight from '../../assests/ChatbotPricing/cbc-checkmark-light.svg'
import codingIcon from '../../assests/ChatbotPricing/cbc-coding-icon.svg'
import crossMark from '../../assests/ChatbotPricing/cbc-crossmark-light.svg'
import voice from '../../assests/ChatbotPricing/cbc-microphone.svg'
import album from '../../assests/ChatbotPricing/cbc-album.svg'
import redCross from '../../assests/ChatbotPricing/cbc-redcross-icon.svg'
import premiumImg from '../../assests/ChatbotPricing/Premium-Icon.svg'
import UserAuthenticationDialog from '../../Utils/GlobalDialog/UserAuthenticationDialog/UserAuthenticationDialog.jsx'
import { Context as mainContext } from '../../Context/MainContext/GlobalContext'
import { UserMeAPI } from '../../Api/Global/GlobalAPI'
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { createChatbotPlanSubscriptionAPI } from '../../Api/ChatbotPricing/ChatbotAPI.js'
import noChronoxBrandingIcon from '../../assests/ChatbotPricing/noChronoxBrandingIcon.svg'
import pricingCardArrow from '../../assests/ChatbotPricing/pricingCardArrow.svg'
import pricingCheckedIcon from '../../assests/ChatbotPricing/pricingCheckedIcon.svg'
import pricingUncheckedIcon from '../../assests/ChatbotPricing/pricingUncheckedIcon.svg'
import { Label } from '@mui/icons-material'
import whatsapp from '../../assests/Pricing/Pricing-Whatsapp.png'
import facebook from '../../assests/Pricing/Pricing-Facebook.png'
import instagram from '../../assests/Pricing/Pricing-Instagram.png'
import slack from '../../assests/Pricing/Pricing-Slack.png'

const ChatbotPricingCardSection = (props) => {

    const {
        chatbotPricingDetails,
        pricingDropdownValue,
        setPricingDropdownValue,
        pricingInputDetails,
        selectedAmountAndHours,
        setSelectedAmountAndHours,
        selectedAmountAndImages,
        setSelectedAmountAndImages,
        selectedChoiceForBranding,
        setSelectedChoiceForBranding,
        basePrices,
        setBasePrices,
        totalPrices,
        setTotalPrices,
    } = props

    const {
        setUserDetails,
        setFlagOpenAuthenticationDialog,
        setLoader,
    } = useContext(mainContext)

    const {
        loading,
        userDetails,
        flagOpenAuthenticationDialog,
    } = useContext(mainContext).state


    const navigate = useNavigate()
    const [alertMessage, setAlertMessage] = useState({
        status: false,
        message: "",
        error: "",
    });
    const [chatbotSubscriptionPlanDetails, setChatbotSubscriptionPlanDetails] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
    const [planSelected, setPlanSelected] = useState('Premium')
    const flagMobileSmallWidth = useMediaQuery('(max-width : 420px) and (min-width : 0px)')
    const showAddOnsFree = selectedAmountAndHours.visible || selectedAmountAndImages.visible || selectedChoiceForBranding.visible;
    const showAddOnsPremium = selectedAmountAndHours.visible || selectedAmountAndImages.visible || selectedChoiceForBranding.visible;


    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-US').format(number);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const updateURLParams = () => {
            // Remove the query parameters completely
            window.history.pushState({}, '', window.location.pathname);
        };

        if (userDetails.flagUserLoggedIn) {
            if (searchParams.get('chatbotId')) {
                let payload = {
                    chatbot_plan_id: searchParams.get('chatbotId'),
                };

                let addonFeatures = [];

                if (searchParams.get('imageId')) {
                    addonFeatures.push({
                        chatbot_addon_features_price_id: searchParams.get('imageId')
                    });
                }

                if (searchParams.get('hoursId')) {
                    addonFeatures.push({
                        chatbot_addon_features_price_id: searchParams.get('hoursId')
                    });
                }

                if (addonFeatures.length > 0) {
                    payload.chatbot_addon_features = addonFeatures;
                }
                createChatbotPlanSubscriptionAPI(payload,
                    (res) => {
                        if (res?.data?.status) {
                            console.log("response true of createChatbotPlanSubscriptionAPI", res);
                            setAlertMessage({
                                status: true,
                                message: res?.data?.message,
                                error: false
                            })
                            setLoader(false)
                            setFlagOpenAuthenticationDialog(false);
                            updateURLParams()
                            setTimeout(() => {
                                window.open(`${process.env.REACT_APP_CHATBOT_REDIRECT_URL}`, "_self")
                            }, 2000);
                            // if (flag == "login") {
                            //     debugger
                            // }

                        }
                        else {
                            console.log("response false of createChatbotPlanSubscriptionAPI", res);
                            if (res?.data?.error?.message) {
                                setAlertMessage({
                                    status: true,
                                    message: res?.data?.error?.message,
                                    error: true
                                })
                                setLoader(false)
                            }
                            else {
                                setAlertMessage({
                                    status: true,
                                    message: "Something Went Wrong!, Please try again later",
                                    error: true
                                })
                                setLoader(false)
                            }
                            setFlagOpenAuthenticationDialog(false);
                            updateURLParams()
                        }
                    },
                    (err) => {
                        console.log("error response false of createChatbotPlanSubscriptionAPI", err);
                        setAlertMessage({
                            status: true,
                            message: "Something Went Wrong!, Please try again later",
                            error: true
                        })
                        setLoader(false)
                        setFlagOpenAuthenticationDialog(false);
                        updateURLParams()
                    })
            }
            else {
                updateURLParams()
            }
        }

    }, [userDetails.flagUserLoggedIn])

    const handleGetStarted = (planSelectedId) => {
        setChatbotSubscriptionPlanDetails({
            ...chatbotPricingDetails,
            chatbot_plan_id: planSelectedId
        })

        const chatbotId = planSelectedId;
        const imageId = selectedAmountAndImages.imagesId;
        const hoursId = selectedAmountAndHours.hoursId;
        const continueFlag = false;

        // Initialize a new URLSearchParams object
        const params = new URLSearchParams();

        // Conditionally add params
        if (chatbotId) {
            // console.log("chatbotId", chatbotId);
            params.append('chatbotId', chatbotId);
        }
        if (imageId) {
            params.append('imageId', imageId);
        }
        if (hoursId) {
            params.append('hoursId', hoursId);
        }

        // Always add continueFlag
        params.append('continueFlag', continueFlag.toString());

        // Update search params
        setSearchParams(params);
    };

    const updateTotalPrices = (amountChange) => {
        setTotalPrices(prev => ({
            free: Math.max(0, prev.free + amountChange),
            premium: Math.max(basePrices.premium, prev.premium + amountChange)
        }));
    };

    const handleRemoveAudioInput = () => {
        const amountToRemove = selectedAmountAndHours.amount || 0;
        setSelectedAmountAndHours({
            hours: '0',
            amount: 0,
            visible: false
        });
        updateTotalPrices(-amountToRemove);
    };

    const handleRemoveImageInput = () => {
        const amountToRemove = selectedAmountAndImages.amount || 0;
        setSelectedAmountAndImages({
            images: '0',
            amount: 0,
            visible: false
        });
        updateTotalPrices(-amountToRemove);
    };

    const handleRemoveBrandingChoice = () => {
        const amountToRemove = selectedChoiceForBranding.amount || 0;
        setSelectedChoiceForBranding({
            brandingChoice: "No",
            amount: 0,
            visible: false
        });
        updateTotalPrices(-amountToRemove);
    };

    const handleBuyNowPlans = (planId) => {
        if (userDetails.flagUserLoggedIn == false) {
            setFlagOpenAuthenticationDialog(true)
        }
        else {
            if (userDetails.userInfo.flag_user_anonymous) {
                setFlagOpenAuthenticationDialog(true)
            }
            else {
                if (userDetails.userInfo.flag_email_verified) {
                    window.open(`${process.env.REACT_APP_CHATBOT_REDIRECT_URL}`, "_self")
                }
            }
        }
    }

    const handleClose = () => {
        setAlertMessage({
            status: false,
            message: "",
            error: "",
        })
    };



    return (
        <>
            {flagOpenAuthenticationDialog &&
                <Dialog
                    open={flagOpenAuthenticationDialog}
                    maxWidth='sm'
                    className='user-auth-dialog'
                >
                    <UserAuthenticationDialog
                        handleClose={handleClose}
                        alertMessage={alertMessage}
                        setAlertMessage={setAlertMessage}
                        setSelectedAmountAndImages={setSelectedAmountAndImages}
                        selectedAmountAndImages={selectedAmountAndImages}
                        selectedAmountAndHours={selectedAmountAndHours}
                        setSelectedAmountAndHours={setSelectedAmountAndHours}
                        chatbotSubscriptionPlanDetails={chatbotSubscriptionPlanDetails}
                        setChatbotSubscriptionPlanDetails={setChatbotSubscriptionPlanDetails}
                    />
                </Dialog>
            }

            {alertMessage.status &&
                <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={alertMessage.error == true ? "error" : "success"} sx={{ width: '100%' }}>
                        {alertMessage.message}
                    </Alert>
                </Snackbar>
            }

            <Grid container>

                <Grid item xs={11} className='cbc-main-item'>
                    <Grid container sx={{ justifyContent: "center", gap: "50px" }}>
                        {chatbotPricingDetails?.filter((e) => (e?.chatbot_plan_type_name == "Free"))?.map((planDetails, ind) => {
                            return (
                                <Grid item xs={3}
                                    key={ind}
                                    onClick={() => { setPlanSelected('Free') }}
                                    className={`cbc-card-main-item ${planSelected == 'Free' ? `cbc-premium-bg-item` : ''}`}
                                >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} sx={{ marginBottom: '5px' }}>
                                                    <span className='cbc-head-text'>{planDetails.chatbot_plan_type_name}</span>
                                                </Grid>

                                                <Grid item xs={12} sx={{ margin: '5px auto' }}>
                                                    <span className='cbc-subhead-text'>
                                                        {planDetails.chatbot_plan_type_description}
                                                    </span>
                                                </Grid>

                                                <Grid item xs={12} sx={{ margin: '5px auto', height: { xs: "auto", sm: "100%" } }}>
                                                    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Grid item xl={5} lg={4.8} md={5} sm={5} xs={12}>
                                                            <Grid container>
                                                                <Grid item xs={12} sx={{ margin: "10px auto" }}>
                                                                    <span className='cbc-pricing-text'>${totalPrices.free}<span className='cbc-pricing-duration-text'> &nbsp;/month</span></span>
                                                                </Grid>
                                                                <Grid item xs={12} sx={{ margin: "10px auto" }}>
                                                                    {/* {planDetails?.chatbot_plan[0]?.chatbot_key_feature_type?.filter((elements) => elements?.chatbot_key_feature_type_name == "AI Agent").map((type) => {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <span style={{ fontSize: "14px" }}>AI Messages&nbsp;
                                                                                    {type?.chatbot_key_feature[0]?.chatbot_key_feature_text_value ? formatNumber(type?.chatbot_key_feature[0]?.chatbot_key_feature_text_value) : ""}</span>
                                                                            </React.Fragment>
                                                                        );
                                                                    })} */}
                                                                    {planDetails?.chatbot_plan[0].chatbot_plan_feature_chats &&
                                                                        <>
                                                                            <Grid container >
                                                                                <Grid item xs={12}>
                                                                                    <span style={{ fontSize: "14px" }}> AI Messages</span>
                                                                                </Grid>
                                                                                <Grid item
                                                                                    sx={{
                                                                                        border: "1px solid #c2bfc4",
                                                                                        padding: "7px 8px",
                                                                                        margin: "8px 0px 0px",
                                                                                        borderRadius: "5px",
                                                                                        width: { xs: flagMobileSmallWidth ? '35%' : '30%', sm: 'auto' },
                                                                                        minWidth: { xs: flagMobileSmallWidth ? '35%' : '30%', sm: '55%' },
                                                                                    }}
                                                                                >
                                                                                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                                                                                        {planDetails?.chatbot_plan[0]?.chatbot_plan_feature_chats ? formatNumber(planDetails?.chatbot_plan[0]?.chatbot_plan_feature_chats) : ""}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>

                                                                        </>
                                                                    }

                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xl={6.8} lg={6.8} md={6.5} sm={6.5} xs={flagMobileSmallWidth ? 10.5 : 8} sx={{ margin: { xs: '18px 0px', sm: 'auto' } }}>
                                                            <Grid container>
                                                                {showAddOnsFree && (
                                                                    <Grid item xs={12} className='cbc-addons-item'>
                                                                        <Grid container>
                                                                            <Grid item xs={12}>
                                                                                <span style={{ color: "#0A6ADD", fontSize: '13px', fontWeight: '500' }}>ADD - ONS</span>
                                                                            </Grid>
                                                                            {selectedAmountAndHours.visible && (
                                                                                <Grid item xs={12}>
                                                                                    <Grid container sx={{ justifyContent: 'start', alignItems: 'center' }}>
                                                                                        <Grid item xs={'auto'}>
                                                                                            <span className='cbc-addons-span'>Audio Input: &nbsp;</span>
                                                                                        </Grid>
                                                                                        <Grid item xs>
                                                                                            <Grid container sx={{ alignItems: 'center' }}>
                                                                                                <Grid item xs sx={{ textAlign: "start" }}>
                                                                                                    <span className='cbc-addons-highlighted-span'>{selectedAmountAndHours.hours} hours</span>
                                                                                                </Grid>
                                                                                                <Grid item sx={{ cursor: 'pointer' }} onClick={handleRemoveAudioInput}>
                                                                                                    <img src={redCross} style={{ width: '15px', height: 'auto', objectFit: 'fill', verticalAlign: 'middle' }} alt="Remove" />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                            {selectedAmountAndImages.visible && (
                                                                                <Grid item xs={12}>
                                                                                    <Grid container sx={{ justifyContent: 'start', alignItems: 'center' }}>
                                                                                        <Grid item xs={'auto'}>
                                                                                            <span className='cbc-addons-span'>Image Input: &nbsp;</span>
                                                                                        </Grid>
                                                                                        <Grid item xs>
                                                                                            <Grid container sx={{ alignItems: 'center' }}>
                                                                                                <Grid item xs sx={{ textAlign: "start" }}>
                                                                                                    <span className='cbc-addons-highlighted-span'>{selectedAmountAndImages.images} images</span>
                                                                                                </Grid>
                                                                                                <Grid item sx={{ cursor: 'pointer' }} onClick={handleRemoveImageInput}>
                                                                                                    <img src={redCross} style={{ width: '15px', height: 'auto', objectFit: 'fill', verticalAlign: 'middle' }} alt="Remove" />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                            {selectedChoiceForBranding.visible && (
                                                                                <Grid item xs={12}>
                                                                                    <Grid container sx={{ justifyContent: 'start', alignItems: 'center' }}>
                                                                                        <Grid item xs={'auto'}>
                                                                                            <span className='cbc-addons-span'>Branding Choice: &nbsp;</span>
                                                                                        </Grid>
                                                                                        <Grid item xs>
                                                                                            <Grid container sx={{ alignItems: 'center' }}>
                                                                                                <Grid item xs sx={{ textAlign: "start" }}>
                                                                                                    <span className='cbc-addons-highlighted-span'>{selectedChoiceForBranding.brandingChoice} </span>
                                                                                                </Grid>
                                                                                                <Grid item sx={{ cursor: 'pointer' }} onClick={handleRemoveBrandingChoice}>
                                                                                                    <img src={redCross} style={{ width: '15px', height: 'auto', objectFit: 'fill', verticalAlign: 'middle' }} alt="Remove" />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sx={{ margin: "5px auto 10px" }}>
                                                    <>
                                                        <Button
                                                            variant={planSelected == "Free" ? 'contained' : 'outlined'}
                                                            fullWidth
                                                            size='medium'
                                                            className={`${planSelected == "Free" ? 'cbc-started-contained-btn' : 'cbc-started-outlined-btn'}`}
                                                            onClick={() => {
                                                                navigate('/contact-us')
                                                                // let planSelectedId = planDetails.chatbot_plan_type_id
                                                                // handleBuyNowPlans(planSelectedId)
                                                                // handleGetStarted(planSelectedId)
                                                            }}
                                                        >
                                                            Contact Sales
                                                        </Button>
                                                    </>
                                                </Grid>

                                                {/* {(userDetails?.userInfo?.chatbot_subscription_plan && userDetails?.userInfo?.chatbot_subscription_plan.length) &&
                                                    userDetails?.userInfo?.chatbot_subscription_plan[0].chatbot_plan_type_name == "Free" &&
                                                    <Grid item xs={12} sx={{ margin: '5px 0px' }}>
                                                        <span style={{ color: '#6A097D', fontSize: '15px', fontWeight: '500' }}>
                                                            Valid till : {moment(userDetails?.userInfo?.chatbot_subscription_plan[0].chatbot_customer_subscription_plan_expire_at).format('DD-MMM-YYYY')}
                                                        </span>
                                                    </Grid>
                                                } */}

                                                {planDetails?.chatbot_plan[0]?.chatbot_key_feature_type?.filter((elements) => elements?.chatbot_key_feature_type_name == "AI Agent").map((type, ind) => {
                                                    return (
                                                        <Grid item xs={12} sx={{ margin: '5px auto' }} key={ind}>
                                                            <Grid container>
                                                                <React.Fragment key={type.chatbot_key_feature_type_id}>
                                                                    <Grid item xs={12} sx={{ margin: '5px 0px ' }}>
                                                                        <span className='cb-plans-subpoints-text'>
                                                                            {type?.chatbot_key_feature_type_name}
                                                                        </span>
                                                                    </Grid>
                                                                    {type.chatbot_key_feature.map((items, ind) => {
                                                                        return (
                                                                            <React.Fragment key={items.chatbot_key_feature_id}>
                                                                                <Grid item xs={12} sx={{ margin: "5px auto" }}>
                                                                                    <Grid container sx={{ alignItems: 'start' }}>
                                                                                        <Grid item xs={1}>
                                                                                            <img src={items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_boolean_value ? checkmarkLight : crossMark : checkmarkLight} alt="" className='cb-plan-light-checkmark' />
                                                                                        </Grid>
                                                                                        <Grid item xs={11}>
                                                                                            <span className='cb-plan-points-text'>
                                                                                                {items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_name : items.chatbot_key_feature_text_value + " " + items.chatbot_key_feature_name}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </React.Fragment>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}

                                                {planDetails?.chatbot_plan[0]?.chatbot_key_feature_type?.filter((elements) => elements?.chatbot_key_feature_type_name === "Knowledge Base").map((type, ind) => {
                                                    return (
                                                        <Grid item xs={12} sx={{ margin: '5px auto' }} key={ind}>
                                                            <Grid container>
                                                                <React.Fragment key={type.chatbot_key_feature_type_id}>
                                                                    <Grid item xs={12} sx={{ margin: '5px 0px ' }}>
                                                                        <span className='cb-plans-subpoints-text'>
                                                                            {type?.chatbot_key_feature_type_name}
                                                                        </span>
                                                                    </Grid>
                                                                    {type.chatbot_key_feature.map((items, ind) => {
                                                                        return (
                                                                            <React.Fragment key={items.chatbot_key_feature_id}>
                                                                                <Grid item xs={12} sx={{ margin: "5px auto" }}>
                                                                                    <Grid container sx={{ alignItems: 'start' }}>
                                                                                        <Grid item xs={1}>
                                                                                            <img src={items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_boolean_value ? checkmarkLight : crossMark : ''} alt="" className='cb-plan-light-checkmark' />
                                                                                        </Grid>
                                                                                        <Grid item xs={11}>
                                                                                            <span className='cb-plan-points-text'>
                                                                                                {items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_name : items.chatbot_key_feature_text_value + " " + items.chatbot_key_feature_name}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </React.Fragment>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}

                                                {planDetails?.chatbot_plan[0]?.chatbot_key_feature_type?.filter((elements) => elements?.chatbot_key_feature_type_name === "Supported Channels").map((type, ind) => {
                                                    return (
                                                        <Grid item xs={12} sx={{ margin: '5px auto' }} key={ind}>
                                                            <Grid container>
                                                                <React.Fragment key={type.chatbot_key_feature_type_id}>
                                                                    <Grid item xs={12} sx={{ margin: '5px 0px ' }}>
                                                                        <span className='cb-plans-subpoints-text'>
                                                                            {type?.chatbot_key_feature_type_name}
                                                                        </span>
                                                                    </Grid>
                                                                    {type.chatbot_key_feature.map((items) => {
                                                                        return (
                                                                            <React.Fragment key={items.chatbot_key_feature_id}>
                                                                                <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                                                    <Grid container sx={{ alignItems: 'center', gap: '20px' }}>
                                                                                        <Grid item xs={1}>
                                                                                            <img
                                                                                                src={
                                                                                                    items.chatbot_key_feature_name == "Website" ? codingIcon :
                                                                                                        items.chatbot_key_feature_name == "WhatsApp Business" ? whatsapp :
                                                                                                            items.chatbot_key_feature_name == 'Facebook Messenger' ? facebook :
                                                                                                                items.chatbot_key_feature_name == 'Instagram' ? instagram : items.chatbot_key_feature_name == 'Slack' && slack
                                                                                                }
                                                                                                style={{ width: '25px', height: 'auto', objectFit: "fill", verticalAlign: 'middle' }}
                                                                                                alt=""
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={10}>
                                                                                            <Grid container >
                                                                                                <Grid item xs={12}>
                                                                                                    <span className='cb-plan-points-text'>
                                                                                                        {items.chatbot_key_feature_name}
                                                                                                    </span>
                                                                                                </Grid>
                                                                                                <Grid item xs={12} sx={{ mt: '2px' }}>
                                                                                                    <div
                                                                                                        style={{ fontSize: '14px', fontWeight: '500', color: '#616161' }}
                                                                                                        dangerouslySetInnerHTML={{ __html: items.chatbot_key_feature_description }}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </React.Fragment>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}

                                                {planDetails?.chatbot_plan[0]?.chatbot_key_feature_type?.filter((elements) => elements?.chatbot_key_feature_type_name === "Key Features").map((type, ind) => {
                                                    return (
                                                        <Grid item xs={12} sx={{ margin: '30px auto 10px' }} key={ind}>
                                                            <Grid container>
                                                                <React.Fragment key={type.chatbot_key_feature_type_id}>
                                                                    <Grid item xs={12} sx={{ margin: '5px 0px ' }}>
                                                                        <span className='cb-plans-subpoints-text'>
                                                                            {type?.chatbot_key_feature_type_name}
                                                                        </span>
                                                                    </Grid>
                                                                    {type.chatbot_key_feature.map((items) => {
                                                                        return (
                                                                            <React.Fragment key={items.chatbot_key_feature_id}>
                                                                                <Grid item xs={12} sx={{ margin: "5px auto" }}>
                                                                                    <Grid container sx={{ alignItems: 'start' }}>
                                                                                        <Grid item xs={1}>
                                                                                            <img src={items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_boolean_value ? checkmarkLight : crossMark : ''} alt="" className='cb-plan-light-checkmark' />
                                                                                        </Grid>
                                                                                        <Grid item xs={11}>
                                                                                            <span className='cb-plan-points-text'>
                                                                                                {items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_name : items.chatbot_key_feature_text_value + " " + items.chatbot_key_feature_name}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </React.Fragment>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}

                                                {planDetails?.chatbot_plan[0]?.chatbot_key_feature_type?.filter((elements) => elements?.chatbot_key_feature_type_name === "WhatsApp Business").map((type, ind) => {
                                                    return (
                                                        <Grid item xs={12} sx={{ margin: '30px auto 10px' }} key={ind}>
                                                            <Grid container>
                                                                <React.Fragment key={type.chatbot_key_feature_type_id}>
                                                                    <Grid item xs={12} sx={{ margin: '5px 0px ' }}>
                                                                        <span className='cb-plans-subpoints-text'>
                                                                            {type?.chatbot_key_feature_type_name}
                                                                        </span>
                                                                    </Grid>
                                                                    {type.chatbot_key_feature.map((items) => {
                                                                        return (
                                                                            <React.Fragment key={items.chatbot_key_feature_id}>
                                                                                <Grid item xs={12} sx={{ margin: "5px auto" }}>
                                                                                    <Grid container sx={{ alignItems: 'start' }}>
                                                                                        <Grid item xs={1}>
                                                                                            <img src={items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_boolean_value ? checkmarkLight : crossMark : ''} alt="" className='cb-plan-light-checkmark' />
                                                                                        </Grid>
                                                                                        <Grid item xs={11}>
                                                                                            <span className='cb-plan-points-text'>
                                                                                                {items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_name : items.chatbot_key_feature_text_value + " " + items.chatbot_key_feature_name}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </React.Fragment>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}

                        {chatbotPricingDetails?.filter((e) => (e?.chatbot_plan_type_name == "Premium"))?.map((planDetails, ind) => {
                            return (
                                <Grid item xs={3}
                                    key={ind}
                                    onClick={() => { setPlanSelected("Premium") }}
                                    className={`cbc-card-main-item ${planSelected == "Premium" ? `cbc-premium-bg-item` : ''}`}
                                // className={`cbc-card-main-item cbc-premium-bg-item`}
                                >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} sx={{ marginBottom: '5px' }}>
                                                    <span className='cbc-head-text'>{planDetails.chatbot_plan_type_name}</span>
                                                    <img src={premiumImg} style={{ width: '20px', height: 'auto', marginLeft: '5px' }} alt="" />
                                                </Grid>

                                                <Grid item xs={12} sx={{ margin: '5px auto' }}>
                                                    <span className='cbc-subhead-text'>
                                                        {planDetails.chatbot_plan_type_description}
                                                    </span>
                                                </Grid>

                                                <Grid item xs={12} sx={{ margin: '5px auto', height: { xs: "auto", sm: "100%" }, }}>
                                                    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Grid item xl={5} lg={4.8} md={5} sm={5} xs={12} >
                                                            <Grid container>
                                                                <Grid item xs={12} sx={{ margin: "10px auto" }}>
                                                                    <span className='cbc-pricing-text'>
                                                                        ${totalPrices.premium}
                                                                        <span className='cbc-pricing-duration-text'>/month</span></span>
                                                                </Grid>
                                                                <Grid item xs={12} sx={{ margin: "10px auto" }}>
                                                                    <Grid container sx={{ gap: "10px", alignItems: 'center' }}>
                                                                        <span style={{ fontSize: "14px" }}>AI Messages&nbsp; </span>
                                                                        <Grid item xs={12}>
                                                                            <Select
                                                                                id="demo-simple-select"
                                                                                className=''
                                                                                size='small'
                                                                                sx={{
                                                                                    width: { xs: flagMobileSmallWidth ? '35%' : '30%', sm: 'auto' },
                                                                                    minWidth: { xs: flagMobileSmallWidth ? '35%' : '30%', sm: '55%' },
                                                                                }}
                                                                                value={{
                                                                                    chatbot_plan_amount: formatNumber(pricingDropdownValue.chatbot_plan_amount),
                                                                                    chatbot_plan_feature_chats: formatNumber(pricingDropdownValue.chatbot_plan_feature_chats)
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    const selectedValue = Number(e.target.value);
                                                                                    // setPricingDropdownValue(selectedValue);
                                                                                    setPricingDropdownValue({
                                                                                        ...pricingDropdownValue,
                                                                                        chatbot_plan_amount: Number(e.target.value.chatbot_plan_amount),
                                                                                        chatbot_plan_feature_chats: Number(e.target.value.chatbot_plan_feature_chats)
                                                                                    });

                                                                                    setBasePrices(prev => ({
                                                                                        ...prev,
                                                                                        premium: Number(e.target.value.chatbot_plan_amount)
                                                                                    }));
                                                                                    setTotalPrices(prev => ({
                                                                                        ...prev,
                                                                                        premium: Number(e.target.value.chatbot_plan_amount) + (selectedAmountAndHours.amount || 0) + (selectedAmountAndImages.amount || 0) + (selectedChoiceForBranding.amount)
                                                                                    }));
                                                                                }}
                                                                                renderValue={(e) =>
                                                                                    <div style={{ textAlign: "left" }}>
                                                                                        <span>{formatNumber(pricingDropdownValue.chatbot_plan_feature_chats)}</span>
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                {planDetails.chatbot_plan.map((value, index) => (
                                                                                    <MenuItem
                                                                                        value={{
                                                                                            chatbot_plan_amount: value.chatbot_plan_amount,
                                                                                            chatbot_plan_feature_chats: value.chatbot_plan_feature_chats
                                                                                        }}
                                                                                        key={index}
                                                                                    >
                                                                                        {formatNumber(value.chatbot_plan_feature_chats)}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xl={6.8} lg={6.8} md={6.5} sm={6.5} xs={flagMobileSmallWidth ? 10.5 : 8} sx={{ margin: { xs: '18px 0px', sm: 'auto' } }}>
                                                            <Grid container>
                                                                {showAddOnsPremium &&
                                                                    (
                                                                        <Grid item xs={12} className='cbc-addons-item' sx={{ backgroundColor: 'white' }}>
                                                                            <Grid container>
                                                                                <Grid item xs={12}>
                                                                                    <span style={{ color: "#0A6ADD", fontSize: '13px', fontWeight: '500' }}>ADD - ONS</span>
                                                                                </Grid>
                                                                                {selectedAmountAndHours.visible && (
                                                                                    <Grid item xs={12}>
                                                                                        <Grid container sx={{ justifyContent: 'start', alignItems: 'center' }}>
                                                                                            <Grid item xs={'auto'}>
                                                                                                <span className='cbc-addons-span'>Audio Input: &nbsp;</span>
                                                                                            </Grid>
                                                                                            <Grid item xs>
                                                                                                <Grid container sx={{ alignItems: 'center' }}>
                                                                                                    <Grid item xs sx={{ textAlign: "start" }}>
                                                                                                        <span className='cbc-addons-highlighted-span'>{selectedAmountAndHours.hours} hours</span>
                                                                                                    </Grid>
                                                                                                    <Grid item sx={{ cursor: 'pointer' }} onClick={() => { handleRemoveAudioInput() }}>
                                                                                                        <img src={redCross} style={{ width: '15px', height: 'auto', objectfit: 'fill', verticalAlign: 'middle' }}></img>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )}

                                                                                {selectedAmountAndImages.visible && (
                                                                                    <Grid item xs={12}>
                                                                                        <Grid container sx={{ justifyContent: 'start', alignItems: 'center' }}>
                                                                                            <Grid item xs={'auto'}>
                                                                                                <span className='cbc-addons-span'>Image Input: &nbsp;</span>
                                                                                            </Grid>
                                                                                            <Grid item xs>
                                                                                                <Grid container sx={{ alignItems: 'center' }}>
                                                                                                    <Grid item xs sx={{ textAlign: "start" }}>
                                                                                                        <span className='cbc-addons-highlighted-span'>{selectedAmountAndImages.images} images</span>
                                                                                                    </Grid>
                                                                                                    <Grid item sx={{ cursor: 'pointer' }} onClick={() => { handleRemoveImageInput() }}>
                                                                                                        <img src={redCross} style={{ width: '15px', height: 'auto', objectfit: 'fill', verticalAlign: 'middle' }}></img>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>)
                                                                                }

                                                                                {selectedChoiceForBranding.visible && (
                                                                                    <Grid item xs={12}>
                                                                                        <Grid container sx={{ justifyContent: 'start', alignItems: 'center' }}>
                                                                                            <Grid item xs={'auto'}>
                                                                                                <span className='cbc-addons-span'>Branding Choice: &nbsp;</span>
                                                                                            </Grid>
                                                                                            <Grid item xs>
                                                                                                <Grid container sx={{ alignItems: 'center' }}>
                                                                                                    <Grid item xs sx={{ textAlign: "start" }}>
                                                                                                        <span className='cbc-addons-highlighted-span'>{selectedChoiceForBranding.brandingChoice} </span>
                                                                                                    </Grid>
                                                                                                    <Grid item sx={{ cursor: 'pointer' }} onClick={() => { handleRemoveBrandingChoice() }}>
                                                                                                        <img src={redCross} style={{ width: '15px', height: 'auto', objectFit: 'fill', verticalAlign: 'middle' }} alt="Remove" />
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    )
                                                                }

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sx={{ margin: "5px auto 10px" }}>
                                                    <Button
                                                        variant={planSelected == "Premium" ? 'contained' : 'outlined'}
                                                        fullWidth
                                                        size='medium'
                                                        className={planSelected == "Premium" ? 'cbc-started-contained-btn' : 'cbc-started-outlined-btn'}
                                                        onClick={() => {
                                                            navigate('/contact-us')
                                                            // let planSelectedId = planDetails.chatbot_plan_type_id
                                                            // handleBuyNowPlans(planSelectedId)

                                                            // handleGetStarted(planSelectedId)
                                                        }}
                                                    >
                                                        Contact Sales
                                                    </Button>
                                                </Grid>

                                                {/* {(userDetails?.userInfo?.chatbot_subscription_plan && userDetails?.userInfo?.chatbot_subscription_plan.length) &&
                                                    userDetails?.userInfo?.chatbot_subscription_plan[0].chatbot_plan_type_name == "Premium" &&
                                                    <Grid item xs={12} sx={{ margin: '5px 0px' }}>
                                                        <span style={{ color: '#6A097D', fontSize: '15px', fontWeight: '500' }}>
                                                            Valid till : {moment(userDetails?.userInfo?.chatbot_subscription_plan[0].chatbot_customer_subscription_plan_expire_at).format('DD-MMM-YYYY')}
                                                        </span>
                                                    </Grid>
                                                } */}

                                                {planDetails.chatbot_plan.find(item => item.chatbot_plan_amount == pricingDropdownValue?.chatbot_plan_amount)?.chatbot_key_feature_type?.filter((elements) => elements?.chatbot_key_feature_type_name == "AI Agent").map((type, ind) => {
                                                    return (
                                                        <Grid item xs={12} sx={{ margin: '5px auto' }} key={ind}>
                                                            <Grid container>
                                                                <React.Fragment key={type.chatbot_key_feature_type_id}>
                                                                    <Grid item xs={12} sx={{ margin: '5px 0px ' }}>
                                                                        <span className='cb-plans-subpoints-text'>
                                                                            {type?.chatbot_key_feature_type_name}
                                                                        </span>
                                                                    </Grid>
                                                                    {type.chatbot_key_feature.map((items) => {
                                                                        return (
                                                                            <React.Fragment key={items.chatbot_key_feature_id}>
                                                                                <Grid item xs={12} sx={{ margin: "5px auto" }}>
                                                                                    <Grid container sx={{ alignItems: 'start' }}>
                                                                                        <Grid item xs={1}>
                                                                                            <img src={items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_boolean_value ? checkmarkLight : crossMark : checkmarkLight} alt="" className='cb-plan-light-checkmark' />
                                                                                        </Grid>
                                                                                        <Grid item xs={11}>
                                                                                            <span className='cb-plan-points-text'>
                                                                                                {items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_name : formatNumber(items.chatbot_key_feature_text_value) + " " + items.chatbot_key_feature_name}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </React.Fragment>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}

                                                {planDetails.chatbot_plan.find(item => item.chatbot_plan_amount == pricingDropdownValue.chatbot_plan_amount)?.chatbot_key_feature_type?.filter((elements) => elements?.chatbot_key_feature_type_name == "Knowledge Base").map((type, ind) => {
                                                    return (
                                                        <Grid item xs={12} sx={{ margin: '5px auto' }} key={ind}>
                                                            <Grid container>
                                                                <React.Fragment key={type.chatbot_key_feature_type_id}>
                                                                    <Grid item xs={12} sx={{ margin: '5px 0px ' }}>
                                                                        <span className='cb-plans-subpoints-text'>
                                                                            {type?.chatbot_key_feature_type_name}
                                                                        </span>
                                                                    </Grid>
                                                                    {type.chatbot_key_feature.map((items) => {
                                                                        return (
                                                                            <React.Fragment key={items.chatbot_key_feature_id}>
                                                                                <Grid item xs={12} sx={{ margin: "5px auto" }}>
                                                                                    <Grid container sx={{ alignItems: 'start' }}>
                                                                                        <Grid item xs={1}>
                                                                                            <img src={items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_boolean_value ? checkmarkLight : crossMark : ''} alt="" className='cb-plan-light-checkmark' />
                                                                                        </Grid>
                                                                                        <Grid item xs={11}>
                                                                                            <span className='cb-plan-points-text'>
                                                                                                {items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_name : formatNumber(items.chatbot_key_feature_text_value) + " " + items.chatbot_key_feature_name}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </React.Fragment>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}

                                                {planDetails.chatbot_plan.find(item => item.chatbot_plan_amount == pricingDropdownValue.chatbot_plan_amount)?.chatbot_key_feature_type?.filter((elements) => elements?.chatbot_key_feature_type_name == "Supported Channels").map((type, ind) => {
                                                    return (
                                                        <Grid item xs={12} sx={{ margin: '5px auto' }} key={ind}>
                                                            <Grid container>
                                                                <React.Fragment key={type.chatbot_key_feature_type_id}>
                                                                    <Grid item xs={12} sx={{ margin: '5px 0px ' }}>
                                                                        <span className='cb-plans-subpoints-text'>
                                                                            {type?.chatbot_key_feature_type_name}
                                                                        </span>
                                                                    </Grid>
                                                                    {type.chatbot_key_feature.map((items) => {
                                                                        return (
                                                                            <React.Fragment key={items.chatbot_key_feature_id}>
                                                                                <Grid item xs={12} sx={{ margin: '11px 0px', }}>
                                                                                    <Grid container sx={{ alignItems: 'center', gap: '20px' }}>
                                                                                        <Grid item xs={1}>
                                                                                            <img
                                                                                                src={
                                                                                                    items.chatbot_key_feature_name == "Website" ? codingIcon :
                                                                                                        items.chatbot_key_feature_name == "WhatsApp Business" ? whatsapp :
                                                                                                            items.chatbot_key_feature_name == 'Facebook Messenger' ? facebook :
                                                                                                                items.chatbot_key_feature_name == 'Instagram' ? instagram : items.chatbot_key_feature_name == 'Slack' && slack
                                                                                                }
                                                                                                style={{ width: '25px', height: 'auto', objectFit: "fill", verticalAlign: 'middle' }}
                                                                                                alt=""
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={10}>
                                                                                            <Grid container >
                                                                                                <Grid item xs={12}>
                                                                                                    <span className='cb-plan-points-text'>
                                                                                                        {items.chatbot_key_feature_name}
                                                                                                    </span>
                                                                                                </Grid>
                                                                                                <Grid item xs={12} sx={{ mt: '2px', }}>
                                                                                                    <div
                                                                                                        style={{ fontSize: '14px', fontWeight: '500', color: '#616161' }}
                                                                                                        dangerouslySetInnerHTML={{ __html: items.chatbot_key_feature_description }}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </React.Fragment>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}

                                                {planDetails.chatbot_plan.find(item => item.chatbot_plan_amount == pricingDropdownValue.chatbot_plan_amount)?.chatbot_key_feature_type?.filter((elements) => elements?.chatbot_key_feature_type_name == "Key Features").map((type, ind) => {
                                                    return (
                                                        <Grid item xs={12} sx={{ margin: '30px auto 10px' }} key={ind}>
                                                            <Grid container>
                                                                <React.Fragment key={type.chatbot_key_feature_type_id}>
                                                                    <Grid item xs={12} sx={{ margin: '5px 0px ' }}>
                                                                        <span className='cb-plans-subpoints-text'>
                                                                            {type?.chatbot_key_feature_type_name}
                                                                        </span>
                                                                    </Grid>
                                                                    {type.chatbot_key_feature.map((items) => {
                                                                        return (
                                                                            <React.Fragment key={items.chatbot_key_feature_id}>
                                                                                <Grid item xs={12} sx={{ margin: "5px auto" }}>
                                                                                    <Grid container sx={{ alignItems: 'start' }}>
                                                                                        <Grid item xs={1}>
                                                                                            <img src={items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_boolean_value ? checkmarkLight : crossMark : ''} alt="" className='cb-plan-light-checkmark' />
                                                                                        </Grid>
                                                                                        <Grid item xs={11}>
                                                                                            <span className='cb-plan-points-text'>
                                                                                                {items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_name : formatNumber(items.chatbot_key_feature_text_value) + " " + items.chatbot_key_feature_name}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </React.Fragment>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}

                                                {planDetails.chatbot_plan.find(item => item.chatbot_plan_amount == pricingDropdownValue.chatbot_plan_amount)?.chatbot_key_feature_type?.filter((elements) => elements?.chatbot_key_feature_type_name == "WhatsApp Business").map((type, ind) => {
                                                    return (
                                                        <Grid item xs={12} sx={{ margin: '30px auto 10px' }} key={ind}>
                                                            <Grid container>
                                                                <React.Fragment key={type.chatbot_key_feature_type_id}>
                                                                    <Grid item xs={12} sx={{ margin: '5px 0px ' }}>
                                                                        <span className='cb-plans-subpoints-text'>
                                                                            {type?.chatbot_key_feature_type_name}
                                                                        </span>
                                                                    </Grid>
                                                                    {type.chatbot_key_feature.map((items) => {
                                                                        return (
                                                                            <React.Fragment key={items.chatbot_key_feature_id}>
                                                                                <Grid item xs={12} sx={{ margin: "5px auto" }}>
                                                                                    <Grid container sx={{ alignItems: 'start' }}>
                                                                                        <Grid item xs={1}>
                                                                                            <img src={items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_boolean_value ? checkmarkLight : crossMark : ''} alt="" className='cb-plan-light-checkmark' />
                                                                                        </Grid>
                                                                                        <Grid item xs={11}>
                                                                                            <span className='cb-plan-points-text'>
                                                                                                {items.chatbot_key_feature_value_boolean_flag ? items.chatbot_key_feature_name : formatNumber(items.chatbot_key_feature_text_value) + " " + items.chatbot_key_feature_name}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </React.Fragment>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>

                <Grid item xs={12} className='cbc-input-main-item'>
                    <Grid container sx={{ justifyContent: 'center' }}>
                        <Grid item xs={11} sx={{ margin: '10px 0px 5px', textAlign: 'center' }} >
                            <span className='cbc-in-maintext'>
                                Customize your plan with optional Add-ons
                            </span>
                        </Grid>
                        <Grid item xs={11} sx={{ margin: '5px 0px 20px', textAlign: 'center' }}>
                            <span className='cbc-in-subtext' style={{ fontWeight: "500", fontSize: "18px" }}>
                                Available in both Free and Premium plans
                            </span>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: '10px 0px' }} >
                            <Grid container sx={{ justifyContent: 'center', gap: "50px" }}>
                                {pricingInputDetails.filter((ele) => (ele.chatbot_addon_features_name == "Audio Input"))?.map((data, ind) => {
                                    return (
                                        <Grid item xs={3} className={`cbc-input-card ${selectedAmountAndHours.amount != 0 ? 'cbc-addon-card-selected' : ''}`} key={ind}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container sx={{ gap: '20px', alignItem: "center" }}>
                                                        <img src={data.chatbot_addon_features_icon_url ? data.chatbot_addon_features_icon_url : voice} className='cbc-input-icon-img' alt="" />
                                                        <span className='cbc-input-icon-text'>
                                                            {data.chatbot_addon_features_name}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                                <Divider style={{ width: '100%', margin: "10px auto" }} />
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={12} sx={{ margin: '10px auto' }}>
                                                            <span style={{ fontSize: '25px', fontWeight: '500' }}>
                                                                ${selectedAmountAndHours.amount} &nbsp;&nbsp;
                                                                <span style={{ fontSize: '16px', fontWeight: '500' }}>
                                                                    /month for&nbsp;
                                                                    <span style={{ color: '#751b86' }}>{selectedAmountAndHours.hours} hours</span>
                                                                </span>
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ margin: '10px auto' }}>
                                                            <span style={{ fontSize: '16px', fontWeight: '500', color: '#1F384C' }}>
                                                                {data.chatbot_addon_features_description}
                                                            </span>
                                                        </Grid>

                                                        <Grid item xs={12} className={`cbc-addon-card`} >
                                                            <FormControl component="fieldset" sx={{ width: "100%" }}>
                                                                <RadioGroup>
                                                                    {data?.chatbot_addon_features_price?.map((item) => (
                                                                        <React.Fragment key={item.chatbot_addon_features_value}>
                                                                            <Divider style={{ width: '100%', margin: "4px auto" }} />
                                                                            <Grid container justifyContent="space-between" alignItems="center">
                                                                                <Grid item>
                                                                                    <Grid container flexWrap="nowrap" gap={3} alignItems="center">
                                                                                        <Grid item sx={{ fontWeight: "500", fontSize: "14px", width: "80px", maxWidth: "90px" }}>{item?.chatbot_addon_features_value} Hours</Grid>
                                                                                        <Grid item sx={{ fontWeight: "500", fontSize: "14px" }}><img src={pricingCardArrow} alt={'arrow icon'} /></Grid>
                                                                                        <Grid item sx={{ fontWeight: "500", fontSize: "14px", width: "70px", maxWidth: "90px" }}>${item?.chatbot_addon_features_price_amount}/month</Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Radio
                                                                                        value={item.chatbot_addon_features_value}
                                                                                        checked={selectedAmountAndHours.hours === item.chatbot_addon_features_value}
                                                                                        onChange={(e) => {
                                                                                            const selectedValue = e.target.value
                                                                                            const selectedFeature = data.chatbot_addon_features_price.find(feature => feature.chatbot_addon_features_value === selectedValue);
                                                                                            // console.log(selectedFeature, 'selected feature')
                                                                                            const newAmount = selectedFeature ? parseInt(selectedFeature.chatbot_addon_features_price_amount, 10) : 0;
                                                                                            const amountChange = newAmount - (selectedAmountAndHours.amount || 0);

                                                                                            setSelectedAmountAndHours({
                                                                                                hours: selectedValue,
                                                                                                amount: newAmount,
                                                                                                visible: selectedValue !== "0",
                                                                                                hoursId: selectedValue?.chatbot_addon_features_price_id
                                                                                            });

                                                                                            updateTotalPrices(amountChange);
                                                                                        }}
                                                                                        icon={<img width="20px" src={pricingUncheckedIcon} alt={"unchecked icon"} />}
                                                                                        checkedIcon={<img width="20px" src={pricingCheckedIcon} alt={"checked icon"} />}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>

                                                        {/* <Grid item xs={12} sx={{ margin: '5px auto' }}>
                                                            <Grid container>
                                                                <Grid item xl={3} lg={3} md={3} sm={3} xs={4}>
                                                                    <Select
                                                                        id="demo-simple-select"
                                                                        className='cbc-input-dropdown'
                                                                        fullWidth
                                                                        size='small'
                                                                        MenuProps={{ sx: { maxHeight: "260px", } }}
                                                                        value={{
                                                                            chatbot_addon_features_value: selectedAmountAndHours.hours,
                                                                            chatbot_addon_features_price_id: selectedAmountAndHours.hoursId
                                                                        }}
                                                                        onChange={(e) => {

                                                                            // OLD CODE 
                                                                            // const selectedValue = e.target.value;
                                                                            // const selectedFeature = data.chatbot_addon_features_price.find(feature => feature.chatbot_addon_features_value === selectedValue);
                                                                            // const newAmount = selectedFeature ? parseInt(selectedFeature.chatbot_addon_features_price_amount) : 0;
                                                                            // const amountChange = newAmount - (selectedAmountAndHours.amount || 0);
                                                                            // setSelectedAmountAndHours({
                                                                            //     hours: selectedValue,
                                                                            //     amount: newAmount,
                                                                            //     visible: selectedValue !== "0"
                                                                            // });
                                                                            // updateTotalPrices(amountChange);

                                                                            // NEW CODE 
                                                                            const selectedValue = e.target.value;
                                                                            console.log("selectedValue", selectedValue);
                                                                            const selectedFeature = data.chatbot_addon_features_price.find(feature => feature.chatbot_addon_features_value === selectedValue.chatbot_addon_features_value);
                                                                            const newAmount = selectedFeature ? parseInt(selectedFeature.chatbot_addon_features_price_amount, 10) : 0;
                                                                            const amountChange = newAmount - (selectedAmountAndHours.amount || 0);

                                                                            setSelectedAmountAndHours({
                                                                                hours: selectedValue.chatbot_addon_features_value,
                                                                                amount: newAmount,
                                                                                visible: selectedValue !== "0",
                                                                                hoursId: selectedValue?.chatbot_addon_features_price_id
                                                                            });

                                                                            updateTotalPrices(amountChange);
                                                                        }}
                                                                        renderValue={(e) =>
                                                                            <div style={{ textAlign: "left" }}>
                                                                                <span>{selectedAmountAndHours.hours}</span>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        {data.chatbot_addon_features_price.map((value, index) => (
                                                                            <MenuItem
                                                                                value={{
                                                                                    chatbot_addon_features_value: value.chatbot_addon_features_value,
                                                                                    chatbot_addon_features_price_id: value.chatbot_addon_features_price_id
                                                                                }}
                                                                                key={index}
                                                                            >
                                                                                {value.chatbot_addon_features_value}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )

                                })}

                                {pricingInputDetails.filter((ele) => (ele.chatbot_addon_features_name == "Image Input"))?.map((data, ind) => {
                                    return (
                                        <Grid item xs={3} key={ind} className={`cbc-input-card ${selectedAmountAndImages.amount != 0 ? 'cbc-addon-card-selected' : ''}`}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container sx={{ gap: '20px', alignItem: "center" }}>
                                                        <img src={data.chatbot_addon_features_icon_url ? data.chatbot_addon_features_icon_url : album} className='cbc-input-icon-img' alt="" />
                                                        <span className='cbc-input-icon-text'>
                                                            {data.chatbot_addon_features_name}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                                <Divider style={{ width: '100%', margin: "10px auto" }} />
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={12} sx={{ margin: '10px auto' }}>
                                                            <span style={{ fontSize: '25px', fontWeight: '500' }}>
                                                                ${selectedAmountAndImages.amount}&nbsp;&nbsp;
                                                                <span style={{ fontSize: '16px', fontWeight: '500' }}>
                                                                    /month for
                                                                    <span style={{ color: '#751b86' }}> {selectedAmountAndImages.images} images</span>
                                                                </span>
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ margin: '10px auto' }}>
                                                            <span style={{ fontSize: '16px', fontWeight: '500', color: '#1F384C' }}>
                                                                {data.chatbot_addon_features_description}
                                                            </span>
                                                        </Grid>

                                                        <Grid item xs={12} className="cbc-addon-card">
                                                            <FormControl component="fieldset" sx={{ width: "100%" }}>
                                                                <RadioGroup>
                                                                    {data?.chatbot_addon_features_price?.map((item) => (
                                                                        <React.Fragment key={item.chatbot_addon_features_value}>
                                                                            <Divider style={{ width: '100%', margin: "4px auto" }} />
                                                                            <Grid container justifyContent="space-between" alignItems="center">
                                                                                <Grid item>
                                                                                    <Grid container flexWrap="nowrap" gap={3} alignItems="center">
                                                                                        <Grid item sx={{ fontWeight: "500", fontSize: "14px", width: "100px", maxWidth: "100px" }}>{item?.chatbot_addon_features_value} Images</Grid>
                                                                                        <Grid item sx={{ fontWeight: "500", fontSize: "14px" }}><img src={pricingCardArrow} alt={'arrow icon'} /></Grid>
                                                                                        <Grid item sx={{ fontWeight: "500", fontSize: "14px", width: "70px", maxWidth: "100px" }}>${item?.chatbot_addon_features_price_amount}/month</Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Radio
                                                                                        value={item.chatbot_addon_features_value}
                                                                                        checked={selectedAmountAndImages.images === item.chatbot_addon_features_value}
                                                                                        onChange={(e) => {
                                                                                            const selectedValue = e.target.value
                                                                                            const selectedFeature = data.chatbot_addon_features_price.find(feature => feature.chatbot_addon_features_value === selectedValue);
                                                                                            const newAmount = selectedFeature ? parseInt(selectedFeature.chatbot_addon_features_price_amount, 10) : 0;
                                                                                            const amountChange = newAmount - (selectedAmountAndImages.amount || 0);

                                                                                            setSelectedAmountAndImages({
                                                                                                images: selectedValue,
                                                                                                amount: newAmount,
                                                                                                visible: selectedValue !== "0",
                                                                                                hoursId: selectedValue?.chatbot_addon_features_price_id
                                                                                            });

                                                                                            updateTotalPrices(amountChange);
                                                                                        }}
                                                                                        icon={<img width="20px" src={pricingUncheckedIcon} alt={"unchecked icon"} />}
                                                                                        checkedIcon={<img width="20px" src={pricingCheckedIcon} alt={"checked icon"} />}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}

                                {pricingInputDetails.filter((ele) => (ele.chatbot_addon_features_name == "No Chronox AI Branding"))?.map((data, ind) => {
                                    return (
                                        <Grid item xs={3} key={ind} className={`cbc-input-card ${selectedChoiceForBranding.amount != 0 ? 'cbc-addon-card-selected' : ''}`}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container sx={{ gap: '20px', alignItem: "center" }}>
                                                        <img src={data.chatbot_addon_features_icon_url ? data.chatbot_addon_features_icon_url : noChronoxBrandingIcon} className='cbc-input-icon-img' alt="" />
                                                        <span className='cbc-input-icon-text'>
                                                            {data.chatbot_addon_features_name}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                                <Divider style={{ width: '100%', margin: "10px auto" }} />
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={12} sx={{ margin: '10px auto' }}>
                                                            <span style={{ fontSize: '25px', fontWeight: '500' }}>
                                                                ${selectedChoiceForBranding.amount} &nbsp;&nbsp;
                                                                <span style={{ fontSize: '16px', fontWeight: '500' }}>
                                                                    /month
                                                                    {/* <span style={{ color: '#751b86' }}>{selectedChoiceForBranding.amount}</span> */}
                                                                </span>
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ margin: '10px auto' }}>
                                                            <span style={{ fontSize: '16px', fontWeight: '500', color: '#1F384C' }}>
                                                                {data.chatbot_addon_features_description}
                                                            </span>
                                                        </Grid>

                                                        <Grid item xs={12} className="cbc-addon-card">
                                                            <FormControl component="fieldset" sx={{ width: "100%" }}>
                                                                <RadioGroup defaultValue={'0'}>
                                                                    {data?.chatbot_addon_features_price?.map((item) => (
                                                                        <React.Fragment key={item.chatbot_addon_features_value}>
                                                                            <Divider style={{ width: '100%', margin: "4px auto" }} />
                                                                            <Grid container justifyContent="space-between" alignItems="center">
                                                                                <Grid item>
                                                                                    <Grid container flexWrap="nowrap" gap={3} alignItems="center">
                                                                                        <Grid item sx={{ fontWeight: "500", fontSize: "14px", width: "60px", maxWidth: "90px" }}>{item?.chatbot_addon_features_value}</Grid>
                                                                                        <Grid item sx={{ fontWeight: "500", fontSize: "14px" }}><img src={pricingCardArrow} alt={'arrow icon'} /></Grid>
                                                                                        <Grid item sx={{ fontWeight: "500", fontSize: "14px", width: "60px", maxWidth: "90px" }}>${item?.chatbot_addon_features_price_amount}/month</Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Radio
                                                                                        value={item.chatbot_addon_features_value}
                                                                                        checked={selectedChoiceForBranding.brandingChoice === item.chatbot_addon_features_value}
                                                                                        onChange={(e) => {
                                                                                            const selectedValue = e.target.value
                                                                                            const selectedFeature = data.chatbot_addon_features_price.find(feature => feature.chatbot_addon_features_value === selectedValue);
                                                                                            const newAmount = selectedFeature ? parseInt(selectedFeature.chatbot_addon_features_price_amount, 10) : 0;
                                                                                            const amountChange = newAmount - (selectedChoiceForBranding.amount || 0);
                                                                                            setSelectedChoiceForBranding({
                                                                                                brandingChoice: selectedValue,
                                                                                                amount: newAmount,
                                                                                                visible: selectedValue !== "",
                                                                                                brandingChoiceId: selectedValue?.chatbot_addon_features_price_id
                                                                                            });

                                                                                            updateTotalPrices(amountChange);
                                                                                        }}
                                                                                        icon={<img width="20px" src={pricingUncheckedIcon} alt={"unchecked icon"} />}
                                                                                        checkedIcon={<img width="20px" src={pricingCheckedIcon} alt={"checked icon"} />}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )

                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChatbotPricingCardSection