import React, { useContext, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Context as mainContext } from './Context/MainContext/GlobalContext'
import Chatbot from "./Pages/Chatbot/Chatbot";
import WhatsappChatbot from "./Pages/WhatsappChatbot/WhatsappChatbot";
import ChatbotPricing from "./Pages/ChatbotPricing/ChabotPricing";
import ContactSales from "./Pages/ContactSales/ContactSales";
import ChatbotFeatures from "./Component/ChatbotFeatures/ChatbotFeatures";
import ChronoxChatbot from "./Pages/ChronoxChatbot/ChronoxChatbot";
import AiChatbot from "./Component/AIChatbot/AiChatbot";
import FacebookPage from "./Pages/FacebookChatbot/FacebookChatbot";
import AiWhatsappLanding from "./Pages/AiWhatsappLanding/AiWhatsappLanding";
import CustomAIChatbotDialog from "./Utils/GlobalDialog/CustomAIChatbotDialog/CustomAIChatbotDialog";
import { Dialog } from "@mui/material";
import ChronoxAtGitex from "./Pages/ChronoxAtGitex/ChronoxAtGitex";
import ArticlePage from "./Pages/ArticlePage/ArticlePage";
import HealthCareComponent from "./Component/IndustrialComponents/HealthCareComponents/HealthCareComponent";
import FoodSectorComponents from "./Component/IndustrialComponents/FoodSectorComponents/FoodSectorComponents";
import RetailSectorComponent from "./Component/IndustrialComponents/RetailSectorComponents/RetailSectorComponent";
import EcommerceComponent from "./Component/IndustrialComponents/ECommerceComponents/ECommerceComponent";
import BlogHome from "./Pages/ArticlePage/BlogHome";
import blog from '../src/assests/Blog/Blog-Bg-min.jpg'
import blog2 from '../src/assests/Blog/Blog-bg-text-min.jpg'
import ChronoxAtTechExpo from "./Pages/ChronoxAtTechExpo/ChronoxAtTechExpo";
import blog3 from '../src/assests/Blog/Health-care-image.svg'
import blog4 from '../src/assests/Blog/Ai-fix-contact-centers.png'
import Omnichannel from "./Pages/OmnichannelDeployment/Omnichannel";
import ScrollToTop from "./Pages/ScrollToTop/ScrollToTop";
import InstagramHeadSection from "./Component/InstagramHeadSection/InstagramHeadSection";
import Instagram from "./Pages/Instagram/Instagram";
import SlackAiChatbot from "./Pages/SlackAiChatbot/SlackAiChatbot";
import InteractiveMessage from "./Pages/InteractiveMessage/InteractiveMessage";
import WebsiteAiChatbot from "./Pages/WebsiteAiChatbot/WebsiteAiChatbot";
import VoiceAIComingSoon from "./Pages/VoiceAI/voiceAIComingSoon";


const RoutePage = () => {

  const [isBelow1000, setIsBelow1000] = useState(window.innerWidth < 1000);
  const { userDetails, loading, toastNotification } = useContext(mainContext).state
  const { setUserDetails, setToastNotification } = useContext(mainContext)
  const location = useLocation();
  const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);
  const [flagOpenCustomAIChatbotDialog, setFlagOpenCustomAIChatbotDialog] = useState(false);
  const [flagBanner, setFlagBanner] = useState(false);
  const [blogHeader, setBlogHeader] = useState(
    [
      {
        img: blog,
        headText: 'Distinctions Between Rule Based Chatbots and Conversational AI',
        navigateLink: '/blog/blog-ai-chatbot',
        timeRead: "10 mins Read",
        alt: 'Distinctions Between Rule Based Chatbots and Conversational AI'
      },
      {
        img: blog3,
        headText: 'The Evolution of Chatbots: From Simple Automation to Intelligent Conversations',
        navigateLink: '/blog/evolution-of-chatbots',
        timeRead: "10 mins Read",
        alt: 'The Evolution of Chatbots: From Simple Automation to Intelligent Conversations'
      },
      {
        img: blog2,
        headText: 'Top 5 Use Cases for Conversational AI Chatbots in Customer Service',
        navigateLink: '/blog/top-5-usecases',
        timeRead: "10 mins Read",
        alt: 'Top 5 Use Cases for Conversational AI Chatbots in Customer Service'
      },
      {
        img: blog,
        headText: 'Chatbot and Digital Transformation',
        navigateLink: '/blog/chatbot-and-digital-transformation',
        timeRead: "10 mins Read",
        alt: 'Chatbot and Digital Transformation'
      },
      {
        img: blog4,
        headText: 'Nobody Likes Contact Centers – Here’s Why AI Agents Fix Them',
        navigateLink: '/blog/ai-agents-vs-ccaas-future-of-customer-support',
        timeRead: "2 mins Read",
        alt: "Comparison between CCaaS and AI Agents: Customer frustrated with long hold times in traditional CCaaS"
      },
    ]
  );


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dialogFlag = queryParams.get('meeting_flag') === 'true';
    setTimeout(() => {
      setFlagOpenBookaMeetingDemo(dialogFlag);
    }, 1000);
  }, [location]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFlagOpenCustomAIChatbotDialog(true);
    }, 60000); // 60000 milliseconds = 1 minute

    return () => clearTimeout(timer); // Clean up the timer if the component unmounts
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/blog/ai-agents-vs-ccaas-future-of-customer-support") {
      document.title = "How Chronox AI Fixes Contact Centers (CCaaS)";

      let metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute(
          "content",
          "Discover how Chronox AI improves contact centers with cutting-edge AI solutions."
        );
      } 
      else {
        const newMeta = document.createElement("meta");
        newMeta.name = "description";
        newMeta.content =
          "Discover how Chronox AI improves contact centers with cutting-edge AI solutions.";
          document.head.appendChild(newMeta);
      }
    }
    else {
      document.title = "Chronox AI | No-Code AI Agents for Instant Support";
    }
  }, [window.location.pathname]);



  return (
    <>
      {/* {loading && <Loader />} */}

      {/* {
        flagOpenBookaMeetingDemo &&
        <Dialog open={flagOpenBookaMeetingDemo}
          maxWidth='lg'
          sx={{ '&.MuiPaper-root': { width: '100%' } }}
          className="iframe-dialog"
        >
          <BookAMeetingDialog
            flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
            setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
          />
        </Dialog>
      } */}

      {/* {toastNotification.status &&
        <Snackbar open={true} autoHideDuration={8000} onClose={handleNotificationClose}>
          <Alert onClose={handleClose} severity={toastNotification.error == true ? "error" : "success"} >
            {toastNotification.message}
          </Alert>
        </Snackbar>
      } */}

      {/* {flagOpenCustomAIChatbotDialog && (
        <Dialog
          open={flagOpenCustomAIChatbotDialog}
          maxWidth='lg'
        >
          <CustomAIChatbotDialog
            flagOpenCustomAIChatbotDialog={flagOpenCustomAIChatbotDialog}
            setFlagOpenCustomAIChatbotDialog={setFlagOpenCustomAIChatbotDialog}
          />
        </Dialog>
      )} */}

      <ScrollToTop />
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<Chatbot flagBanner={flagBanner} setFlagBanner={setFlagBanner} blogHeader={blogHeader} />} />
        <Route path="/ai-chatbot" element={<AiChatbot flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/whatsapp-ai-chatbot" element={<WhatsappChatbot flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/facebook-ai-chatbot" element={<FacebookPage flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/pricing" element={<ChatbotPricing flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/contact-us" element={<ContactSales flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/industry/healthcare" element={<HealthCareComponent flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/industry/food" element={<FoodSectorComponents flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/industry/retail" element={<RetailSectorComponent flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/industry/ecommerce" element={<EcommerceComponent flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/events/tech-expo-gujarat" element={<ChronoxAtTechExpo />} />
        <Route path="/events/chronox-at-gitex-global-dubai" element={<ChronoxAtGitex />} />
        <Route path="/chronox-at-gitex-global-dubai" element={<ChronoxAtGitex flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        {/* <Route path="/whatsapp" element={<AiWhatsappLanding flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} /> */}
        <Route path="/omnichannel-deployment" element={<Omnichannel flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/instagram" element={<Instagram flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/slack-ai-chatbot" element={<SlackAiChatbot flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/interactive-message" element={<InteractiveMessage flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/website-ai-chatbot" element={<WebsiteAiChatbot flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        <Route path="/voice-ai" element ={<VoiceAIComingSoon flagBanner={flagBanner} setFlagBanner={setFlagBanner} />} />
        
        <Route
          path="/blog"
          element={
            <BlogHome
              blogHeader={blogHeader}
              setBlogHeader={setBlogHeader}
            />
          }
        />

        <Route
          path="/blog/blog-ai-chatbot"
          element={
            <ArticlePage
              blogName="blog-ai-chatbot"
              blogHeader={blogHeader} />
          }
        />

        <Route
          path="/blog/evolution-of-chatbots"
          element={
            <ArticlePage
              blogName="evolution-of-chatbots"
              blogHeader={blogHeader}
            />
          }
        />

        <Route
          path="/blog/top-5-usecases"
          element={
            <ArticlePage
              blogName="top-5-usecases"
              blogHeader={blogHeader}
            />
          }
        />

        <Route
          path="/blog/chatbot-and-digital-transformation"
          element={
            <ArticlePage
              blogName="chatbot-and-digital-transformation"
              blogHeader={blogHeader}
            />
          }
        />

        <Route
          path="/blog/ai-agents-vs-ccaas-future-of-customer-support"
          element={
            <ArticlePage
              blogName="ai-agents-vs-ccaas-future-of-customer-support"
              blogHeader={blogHeader}
            />
          }
        />

        {/* Catch-all redirect to home */}
        {/* <Route path="/chatbot-feature" element={<ChronoxChatbot />} /> */}
      </Routes>

    </>
  );
};


export default RoutePage;
