import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import omini from '../../assests/LandingPage/chatbot-omnichannel.svg'
import './OminiChannelSection.css'
import EastIcon from '@mui/icons-material/East';

const OminiChannelSection = () => {
    return (
        <>
            <Grid container>
                <Grid item xs={12} className='omcs-main-item'>
                    <Grid container>

                        <Grid item xl={9} lg={10} md={11} sm={11} xs={11} className='omcs-content-item'>
                            <Grid container className='omcs-content-container'>
                                <Grid item xl={5.5} lg={5.5} md={5.5} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} className='omcs-head-point-text-item'>
                                            <Typography variant='h2' className='omcs-head-point-text'>
                                                The easiest platform to integrate into your customer service tech stack
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='omcs-subhead-point-text-item'>
                                            <p className='omcs-subhead-point-text'>
                                                Build what makes your brand stand out and let us handle the rest.
                                                Keep everything secure and reliable while managing all your customer interactions on one CS platform,
                                                across all channels and languages.
                                            </p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant='contained'
                                                size='large'
                                                endIcon={<EastIcon />}
                                                className='omini-btn'
                                                onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')}
                                                aria-label='See the Platform'
                                            >
                                                See the Platform
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xl={5.5} lg={5.5} md={5.5} sm={12} xs={12} className='omini-image-item' >
                                    <img
                                        src={omini}
                                        alt="Omni Channels"
                                        className='omini-image'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default OminiChannelSection