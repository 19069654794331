import { Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import './BlogHome.css'
import { Context as mainContext } from '../../Context/MainContext/GlobalContext'
import { useNavigate } from 'react-router-dom'
import SearchIcon from "@mui/icons-material/Search";
import Navbar from '../Navbar/Navbar'
import moment from 'moment'



const BlogHome = (props) => {

    const navigate = useNavigate()
    const { blogHeader } = props
    const { setBlogText } = useContext(mainContext)
    const today = new Date();

    const formattedDate = moment().format('MMMM D, YYYY');

    const [searchTerm, setSearchTerm] = useState('');

    // Handle search input change
    const handleChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    // Filter blogHeader based on searchTerm
    const filteredBlogs = blogHeader.filter((ele) =>
        ele.headText.toLowerCase().includes(searchTerm)
    );


    const handleCardClick = (ele) => {
        const plainBlogData = {
            headText: ele.headText,
            navigateLink: ele.navigateLink,
            img: ele.img,
            timeRead: ele.timeRead,
            alt: ele.alt
        };

        localStorage.setItem('selectedBlogText', JSON.stringify(plainBlogData));
        setBlogText(plainBlogData);
        navigate(ele.navigateLink);
    };


    return (
        <>
            <Navbar />
            <Grid container>
                <Grid item xs={12} className='bh-page-main-item'>
                    <Grid container>
                        <Grid item xs={12} className='bh-header-item'>
                            <Grid container>
                                <Grid item
                                    className='bh-main-blog-card'
                                    // onClick={() => navigate(blogHeader[0].navigateLink)}
                                    onClick={() => {
                                        const mainBlogData = {
                                            headText: blogHeader[0].headText,
                                            navigateLink: blogHeader[0].navigateLink,
                                            img: blogHeader[0].img,
                                        };

                                        // Store the main blog data in localStorage
                                        localStorage.setItem('selectedBlogText', JSON.stringify(mainBlogData));

                                        // Set the blogText in the context
                                        setBlogText(mainBlogData);

                                        // Navigate to the main blog's page
                                        navigate(blogHeader[0].navigateLink);
                                    }}
                                >
                                    <Grid container sx={{ height: '100%' }}>
                                        <Grid item xl={7} lg={7} md={7} sm={7} xs={12} sx={{ height: '100%' }}>
                                            <img
                                                src={blogHeader[0].img}
                                                alt=""
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                    borderRadius: "10px 0px 0px 10px"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xl={5} lg={5} md={5} sm={5} xs={12} sx={{ height: '100%' }} className='bh-main-card-text-item'>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant='span'
                                                        className='bh-card-blog-text'
                                                    >
                                                        {blogHeader[0].headText}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                    <Grid container sx={{ gap: '10px' }}>
                                                        <Grid item>
                                                            <Typography
                                                                variant='span'
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    fontWeight: '600',
                                                                    color: 'Black',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {formattedDate}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Divider orientation='vertical' style={{ borderWidth: '1px', borderColor: 'gray', height: '100%', margin: '0 auto' }} />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant='span' sx={{ fontSize: '14px', fontWeight: '500', color: 'black' }}>
                                                                {blogHeader[0].timeRead}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className='bh-card-wrapper-item'>
                            <Grid container sx={{ justifyContent: { xl: 'start', lg: 'start', md: "center", sm: 'center', xs: 'center' }, gap: '30px' }}>
                                <Grid item xl={12} lg={12} md={11} sm={11} xs={12} sx={{ margin: '0 auto' }}>
                                    <Grid container>
                                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className='bh-card-home-card-item'>
                                            <Typography variant='span' sx={{ fontSize: '30px', fontWeight: '600', color: 'black' }}>
                                                Resource Library
                                            </Typography>
                                        </Grid>
                                        {/* <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className='bh-card-search-card-item'>
                                            <TextField
                                                size='small'
                                                id="search"
                                                type="search"
                                                className='arp-searchbar'
                                                placeholder='Search Artical'
                                                value={searchTerm}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="center" sx={{ mr: 2 }}>
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: 'transparent', // Remove border
                                                            // boxShadow: 'none',         // Remove box-shadow if any
                                                        },
                                                    }
                                                }}
                                            />
                                        </Grid> */}
                                    </Grid>
                                </Grid>

                                {filteredBlogs?.slice(1)?.map((ele, index) => (
                                    <Grid
                                        item
                                        className="bh-card-main-item"
                                        key={index}
                                        onClick={() => handleCardClick(ele)}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <img src={ele.img} alt="" className="bh-card-img" />
                                            </Grid>
                                            <Grid item xs={12} sx={{ padding: '10px' }}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="span"
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontWeight: '600',
                                                            }}
                                                        >
                                                            {ele.headText}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ margin: '5px 0px 0px' }}>
                                                        <Grid container sx={{ gap: '10px' }}>
                                                            <Grid item>
                                                                <Typography
                                                                    variant="span"
                                                                    sx={{
                                                                        fontSize: '13px',
                                                                        fontWeight: '500',
                                                                        color: 'gray',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    October 5, 2024
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Divider
                                                                    orientation="vertical"
                                                                    style={{
                                                                        borderWidth: '1px',
                                                                        borderColor: 'gray',
                                                                        height: '80%',
                                                                        margin: '5px auto',
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    variant="span"
                                                                    sx={{
                                                                        fontSize: '13px',
                                                                        fontWeight: '500',
                                                                        color: 'gray',
                                                                    }}
                                                                >
                                                                    {ele.timeRead}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default BlogHome