import { Grid, Typography } from '@mui/material'
import React from 'react'
import './GitexBusinessSection.css'
import img1 from '../../assests/Gitex/b1.png'
import img2 from '../../assests/Gitex/b2.png'
import img3 from '../../assests/Gitex/b3.png'
import img4 from '../../assests/Gitex/b4.png'

const GitexBusinessSection = () => {


    const cards = [
        {
            image: img1,
            head: 'Healthcare',
            subHead: 'Streamline patient care and reduce wait times—your virtual assistant is ready to handle inquiries 24/7.'
        },
        {
            image: img2,
            head: 'Food & Beverage',
            subHead: 'Transform your customer experience—serve up instant responses to orders and reservations, anytime, anywhere.'
        },
        {
            image: img3,
            head: 'Retail',
            subHead: 'Boost sales and enhance customer satisfaction with personalized shopping experiences available around the clock.'
        },
        {
            image: img4,
            head: 'Travel & Hospitality',
            subHead: "Say goodbye to long hold times —offer real - time assistance for bookings and travel inquiries at your guests' fingertips."
        },
    ]


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} sx={{ textAlign: 'center', margin: "0px 0px 50px" }}>
                            <Typography variant='span' className='gbs-professional-solution-text'>
                                Professional solution for every industry
                            </Typography>
                        </Grid>

                        <Grid item xs={11}>
                            <Grid container className='gbs-cards-container' sx={{ justifyContent: 'center', gap: '35px', flexWrap: {xs: "wrap", lg:"nowrap"} }}>
                                {cards.map((data, index) => {
                                    return (
                                        <Grid item xs={3} className='gbs-card-item' key={index}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <img src={data.image} alt={data.head} className='gbs-card-image' />
                                                </Grid>
                                                <Grid item xs={12} sx={{ padding: '10px' }}>
                                                    <Grid container>
                                                        <Grid item xs={12} sx={{ margin: '0px 0px 5px' }}>
                                                            <Typography
                                                                variant='span'
                                                                className='gbs-card-head'
                                                            >
                                                                {data.head}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ margin: '5px 0px' }}>
                                                            <Typography
                                                                variant='span'
                                                                className='gbs-card-subhead'
                                                            >
                                                                {data.subHead}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default GitexBusinessSection