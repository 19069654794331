import React from 'react'
import './LiveChatAISection.css'
import { Grid, Typography } from '@mui/material'
import ComparisonContainer from './ComparisonContainer'

const LiveChatAISection = () => {
    return (
        <>
            <Grid container sx={{backgroundColor: "white"}}>
            {/* <Grid container sx={{background: "radial-gradient(circle, rgba(152,247,202,1) 0%, rgba(205,245,218,1) 50%, rgba(255,255,255,1) 100%)", backgroundSize: "50% 200%", backgroundPosition: "center top"}}> */}
                <Grid item xl={9} lg={11} md={11} sm={11} xs={11} className='liveai-head-text-main-item'>
                    <Grid container direction={"column"} gap={3}>
                        <Grid item xs={11} className='liveai-head-text-item'>
                            <Typography
                                variant='h2'
                                className='liveai-head-normal-text-color'
                            >
                                What sets Chronox AI apart from the rest
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sx={{width: {xs:"90%", md: "70%"}}} className='liveai-head-text-item'>
                            <p className='liveai-head-sub-normal-text-color'>
                                Discover how Chronox AI outperforms others by offering robust & intelligent customer solutions that blends personalized AI driven automation with human support
                            </p>
                        </Grid>

                        <Grid item xs={11} sx={{width: "100%"}} className='liveai-head-text-comparison-item'>
                            <ComparisonContainer />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default LiveChatAISection
