import React, { useContext, useEffect } from 'react'
import './TopPanel.css'
import { Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import { useState } from "react";
import { Context as mainContext } from '../../Context/MainContext/GlobalContext'


import facebookimg from "../../assests/FacebookLogo.svg";
import tweeterimg from "../../assests/TwitterLogo.svg";
import linkedinimg from "../../assests/LinkedinLogo.svg";
import instagramimg from "../../assests/InstagramLogo.svg";
import { useNavigate } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TopPanel = (props) => {

  const { blogHeader } = props
  const { blogText } = useContext(mainContext).state
  const { setBlogText } = useContext(mainContext)
  const [searchTerm, setSearchTerm] = useState("");
  const issmallMobile = useMediaQuery('(min-width: 0px) and (max-width: 400px');


  const navigate = useNavigate()

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const savedBlogText = localStorage.getItem('selectedBlogText');
    if (savedBlogText && !blogText) {
      setBlogText(JSON.parse(savedBlogText));
    }
  }, [blogText, setBlogText]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} className='arp-head-main-item'>
          <Grid container>
            <Grid item xl={9} lg={9} md={9} sm={9} xs={11} sx={{ margin: '0 auto', textAlign: 'start' }}>
              <Grid container>
                <Grid item xs={12} >
                  <Typography
                    variant='span'
                    className='arp-chatbot-blog-head'
                  >
                    {blogText?.headText ? blogText?.headText : ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '5px' }}>
                  <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item xs={issmallMobile ? 12 : 7} sx={{ margin: issmallMobile ? '0px 0px 5px' : '0px' }}>
                      <Grid container sx={{ gap: '10px' }}>
                        <Grid item>
                          <Typography

                            variant='span'
                            sx={{
                              fontSize: '14px',
                              fontWeight: '600',
                              color: '#6a097d',
                              textDecoration: "underline",
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                            onClick={() => { navigate('/blog'); setBlogText(null) }}
                          >
                            <ArrowBackIcon sx={{ mr: '5px' }} />
                            BLOG
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Divider orientation='vertical' style={{ borderWidth: '1px', borderColor: '#a8a8a8', height: '90%', margin: '0 auto' }} />
                        </Grid>
                        <Grid item>
                          <Typography variant='span' sx={{ fontSize: '14px', fontWeight: '500', color: 'black' }}>
                            {blogText?.timeRead || ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={issmallMobile ? 12 : 5} sx={{ textAlign: 'end', margin: issmallMobile ? '5px 0px 0px' : '0px' }} className="lp-ft-social-item">
                      {/* <a href="https://twitter.com/" target="_blank" className="lp-ft-social-link1">
                        <img src={tweeterimg} alt="" />
                      </a> */}
                      <a href="https://www.linkedin.com/company/chronox-ai/" target="_blank" className="lp-ft-social-link">
                        <img src={linkedinimg} alt="" />
                      </a>
                      <a href="https://www.facebook.com/people/Chronox-AI/61562335443030/" target="_blank" className="lp-ft-social-link">
                        <img src={facebookimg} alt="" />
                      </a>
                      <a href="https://www.instagram.com/chronoxai/" target="_blank" className="lp-ft-social-link">
                        <img src={instagramimg} alt="" />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className='blog-bg-image-item'>
                  <img
                    src={blogText?.img && blogText?.img}
                    className='blog-bg-image'
                    alt={blogText?.alt || ""}
                    style={{
                      width: '100%',
                      height: '600px',
                      objectFit: 'cover',
                      marginBottom: '-5px',
                      borderRadius: '15px 15px 0px 0px',
                      filter: 'brightness(100%)',
                      display: 'block'
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  )
}

export default TopPanel