import React, { useState } from 'react'
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import './ChronoxAtGitexBoothSection.css'
// import gitex from '../../assests/GlobalImages/gitex-frame.svg'
// import tikit from '../../assests/Gitex/gitex-ticket.svg'
import secure from '../../assests/GlobalImages/ticket-symbole.png'
import CopyToClipboard from 'react-copy-to-clipboard';
import IconsColor from '../../Utils/Global/iconsColor'
import { useNavigate } from 'react-router-dom'


const ChronoxAtGitexBoothSection = () => {

    const [copyText, setCopyText] = useState('Copy')
    const [hover, setHover] = useState(false)
    const navigate = useNavigate()

    return (
        <>
            <Grid container>
                <Grid item xs={12} className='gi-card-main-item'>
                    <Grid container >
                        <Grid item xs={10} sx={{ margin: '0 auto' }}>
                            <Grid container sx={{ justifyContent: 'space-between', }}>
                                <Grid item xs={5.8}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ margin: '20px auto' }}>
                                            <Typography
                                                variant='span'
                                                sx={{ fontSize: '50px', fontWeight: '600', color: '#6A097D' }}
                                            >
                                                See you at GITEX <br />
                                                <Typography
                                                    variant='span'
                                                    sx={{ fontSize: '40px', fontWeight: '500', color: '#6A097D' }}

                                                >
                                                    Meet Chronox AI @Booth No. H7-19
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '20px auto' }}>
                                            <Typography variant='span' sx={{ fontSize: '22px', fontWeight: 500, color: 'black' }}>
                                                Don’t miss the opportunity to experience our
                                                smart and efficient AI-first Agents. Whether
                                                you’re looking for small, medium or large
                                                enterprise solutions, we’ve got you covered.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '20px auto' }}>
                                            <Typography variant='span' sx={{ fontSize: '22px', fontWeight: 500, color: 'black' }}>
                                                No Ticket Yet? <br />
                                                Attend GITEX for <Typography variant='span' sx={{ fontSize: '22px', fontWeight: 500, color: '#FFB762F8' }}>Free</Typography> by using our promo code!
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '20px auto' }}
                                            onMouseEnter={() => { setHover(true) }}
                                            onMouseLeave={() => { setHover(false) }}
                                        >
                                            <Grid container sx={{ alignItems: 'center', gap: '20px' }}>
                                                {/* <Grid item>
                                                    <img
                                                        src={tikit}
                                                        alt=""
                                                        srcset=""
                                                        className='gai-ticket-img'
                                                    />
                                                </Grid> */}
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="contained"
                                                        size='large'
                                                        className='gradient-button'
                                                        onClick={() => { navigate('/contact-us') }}
                                                    >
                                                        Get Free PROMO CODE
                                                    </Button>
                                                </Grid>
                                                {/* {hover &&
                                                    <Grid item>
                                                        <CopyToClipboard text={'GTXOKIDOOH'}>
                                                            <Tooltip
                                                                title={copyText}
                                                                onMouseLeave={() => setCopyText("Copy!")}
                                                                onClick={() => {
                                                                    setCopyText("Copied!");
                                                                }}
                                                            >
                                                                <Grid item style={{ cursor: 'pointer' }}>
                                                                    <IconButton>
                                                                        <IconsColor
                                                                            iconName={"Copy"}
                                                                            fillColor={"black"}
                                                                            width={"25"}
                                                                            height={"25"}
                                                                        />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Tooltip>
                                                        </CopyToClipboard>
                                                    </Grid>
                                                } */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5.8} sx={{ textAlign: 'end' }}>
                                    <img src={secure} style={{ width: "500px", height: 'auto', transform: 'rotate(24deg)' }} alt="" srcSet="" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChronoxAtGitexBoothSection