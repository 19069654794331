import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import './AIChatbotSimplified.css'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import shareable from '../../assests/LandingPage/sharable.svg'

const AIChatbotSimplified = () => {
    const simplifiedAnswers = ["Instant Answers", "Trained Data API", "Automated Customer Support", "Tailored AI Bot", "Instant Answers", "Trained Data API", "Automated Customer Support", "Tailored AI Bot"]
    return (
        <>
            <Grid container>
                <Grid item xl={8.5} lg={11} md={11} sm={11} xs={11} className='simplified-head-text-main-item'>
                    <Grid container>
                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11} className='simplified-head-text-item'>
                            <h2 className='simplified-head-text'>AI Chatbot simplified</h2>
                        </Grid>

                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11} className='simplified-head-text-item1'>
                            <p className=''>Streamline customer support with AI-driven chatbots that are easy to set up and scale. </p>
                        </Grid>


                        <Grid item xs={11} className='simplified-button-text-item'>
                            <a
                                className='ai-chatbot-simplified-button'
                                href={process.env.REACT_APP_REDIRECT_URL}
                                target='blank'
                            // endIcon={<img src={shareable} style={{ width: '15px', height: 'auto ' }} />}
                            >
                                Explore Now <img src={shareable} alt='Explore' style={{ width: '10px', height: 'auto ' }} loading='lazy' />
                            </a>
                        </Grid>

                        <Grid item xs={12} sx={{ width: "100%" }} className='slider-buttons-style'>
                            <div className="scrolling-container">
                                <div className="scrolling-content">
                                    <Grid container direction={"row"} gap={3} flexWrap={"nowrap"}>
                                        {simplifiedAnswers.map((answer, index) =>
                                            <Grid item key={index} className='marquee-button-styles'>
                                                <Grid container direction={"row"} alignItems={"center"} gap={1} flexWrap={"nowrap"}>
                                                    <Grid item>
                                                        <CheckCircleOutlineIcon sx={{ display: "flex" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        <h3 className='simplified-buttons-style'>{answer}</h3>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {simplifiedAnswers.map((answer, index) => (
                                            <Grid item key={index + simplifiedAnswers.length} className="marquee-button-styles">
                                                <Grid container direction={"row"} alignItems={"center"} gap={1} flexWrap={"nowrap"}>
                                                    <Grid item>
                                                        <CheckCircleOutlineIcon sx={{ display: "flex" }} />
                                                    </Grid>
                                                    <Grid item>
                                                        {answer}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AIChatbotSimplified
