import { Button, Grid, useMediaQuery } from '@mui/material'
import React, { useLayoutEffect, useRef } from 'react'
import './CommonStructurePage.css'
import EastIcon from '@mui/icons-material/East';

const CommonStructurePage = (props) => {
    const { format } = props;
    const smallScreen = useMediaQuery('(max-width : 900px) and (min-width : 0px)')
    const getBackgroundGredient = (formatId) => {
        switch (formatId) {
            case 1:
                return "linear-gradient(to bottom, rgba(229, 220, 239, 1), rgba(229, 220, 239, 0.6))";
            case 2:
                return "linear-gradient(to bottom, rgba(215, 228, 241, 1), rgba(215, 228, 241, 0.6))";
            case 3:
                return "linear-gradient(to bottom, rgba(252, 243, 233, 1), rgba(252, 243, 233, 0.6))";
            case 4:
                return "linear-gradient(180deg, #D6F2ED 0%, rgba(214, 242, 237, 0.6) 100%)";
        }
    }

    return (
        <Grid item
            sx={{
                margin: {
                    xs: (format.formatId % 2) == 0 ? "0px 0px 0px 15%" : "0px 15% 0px 0px",
                    sm: (format.formatId % 2) == 0 ? "0px 0px 0px 15%" : "0px 15% 0px 0px",
                    md: (format.formatId % 2) == 0 ? "160px 0px 80px 15%" : "80px 15% 80px 0px",
                },
                height: "100%"
            }}
            className='main-section-common-page'
        >
            <Grid container
                className={format.flagwhite ? "common-section-white-container" : "common-section-dark-container"}
                sx={{
                    // borderRadius:  (format.formatId % 2) == 0 ? "20px 0 0 0" : "0 20px 0 0", 
                    borderRadius: {
                        xs: (format.formatId % 2) == 0 ? "20px 0 0 20px" : "0 20px 20px 0",
                        sm: (format.formatId % 2) == 0 ? "20px 0 0 20px" : "0 20px 20px 0",
                        md: (format.formatId % 2) == 0 ? "20px 0 0 0" : "0 20px 0 0",
                        lg: (format.formatId % 2) == 0 ? "20px 0 0 0" : "0 20px 0 0",
                        xl: (format.formatId % 2) == 0 ? "20px 0 0 0" : "0 20px 0 0",
                    },
                    position: "relative",
                    background: getBackgroundGredient(format.formatId)
                }}
            >
                <Grid item xl={10} lg={11} md={11} sm={11} xs={11} className={format.flagwhite ? 'common-section-white-item' : 'common-section-dark-item'}>
                    <Grid container direction={{ xs: "column", md: (format.formatId % 2) == 0 ? "row-reverse" : "row" }} gap={8} flexWrap={"nowrap"} justifyContent={"space-between"}>
                        <Grid item xs={12} md={8} sx={{ width: "100%" }}>
                            <Grid container direction={"column"} gap={2}>
                                <Grid item>
                                    <h3 className='commnon-subfield-text-style' style={{ color: `${format.format_field_color}` }}>{format.format_sub_field}</h3>
                                </Grid>
                                <Grid item className='format-heading-item-style'>
                                    <h2 className='common-format-heading-style'>{format.format_heading}</h2>
                                </Grid>
                                <Grid item>
                                    {format.format_details.map((value) => <p className='common-format-details-style' style={{ display: "block", marginBottom: "15px" }} key={value}>{value}</p>)}
                                </Grid>
                                <Grid item className='common-section-button-components-item'>
                                    <Grid container direction={{ xs: "column", md: "row" }} alignItems={{ xs: "start", md: "center" }} gap={{ xs: 2, md: 5 }} flexWrap={"nowrap"}>
                                        <Grid item>
                                            <Button
                                                className='common-format-button-styles'
                                                sx={{
                                                    backgroundColor: format.format_field_color,
                                                    '&:hover': { backgroundColor: format.format_field_color }
                                                }}
                                                onClick={format.onclick}
                                                aria-label={format.format_button_text} 
                                            >
                                                {format.format_button_text} <EastIcon style={{ marginLeft: "10px", display: "flex" }} />
                                            </Button>
                                        </Grid>
                                        {smallScreen &&
                                            <Grid item xs={12} md={3} mt={1} textAlign={"center"} alignSelf={"center"}>
                                                <img src={format.image_data} alt="Images" className={format.formatId % 2 == 0 ? 'common-section-left-image' : 'common-section-right-image'} loading='lazy' />
                                            </Grid>
                                        }
                                        <Grid item className={'common-format-btn-dark-styles'}>
                                            <Grid container direction={"row"} gap={{ xs: 3, md: 4 }} flexWrap={"nowrap"} alignItems={"start"}>
                                                <Grid item>
                                                    <h5 className='common-format-btn-percentage'>{format.btn_data.btn_percentage}</h5>
                                                </Grid>
                                                <Grid item>
                                                    <h6 className='common-format-btn-data'>{format.btn_data.btn_data}</h6>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {!smallScreen &&
                                    <Grid item xs={12} md={3} className={(format.formatId % 2 == 0) ? 'common-section-left-image-container' : 'common-section-right-image-container'} textAlign={"center"} alignSelf={"center"}>
                                        <img src={format.image_data} alt='Image' className='common-section-image' loading='lazy' />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} md={3} className={(format.formatId % 2 == 0) ? 'common-section-left-image-container' : 'common-section-right-image-container'} textAlign={"center"} alignSelf={"center"}>
                            <img src={format.image_data} className='common-section-image'/>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CommonStructurePage
