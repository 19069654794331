import { Grid, Typography } from '@mui/material'
import React from 'react'

const TraditionalccaasBusiness = () => {
    const data = [
        {
            title: "Expensive and resource-heavy",
            content: "Cloud-based or not, CCaaS still demands large teams of human agents—driving up costs in hiring, training, and retention."
        },
        {
            title: "Slow, outdated experiences",
            content: "Customers get stuck in IVRs, wait on hold, and deal with agents limited to one conversation at a time."
        },
        {
            title: "Basic automation isn’t enough",
            content: "Most CCaaS chatbots just deflect tickets. Complex issues? They still get pushed back to human agents."
        },
        {
            title: "Scaling is a nightmare",
            content: "Need to handle more calls? That means hiring more people, increasing costs, and risking agent burnout."
        },
        {
            title: "AI adoption is fragmented",
            content: "Even when AI exists, it’s often an afterthought—leading to clunky, disconnected automation rather than a seamless experience."
        }
    ]

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                            <Typography
                                component='span'
                                className='artical-paragraph-global-text'
                            >
                                CCaaS moved contact centers to the cloud, but it never solved the fundamental problems. Here’s why businesses are still struggling:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "0px 0px 10px" }}>
                            <ul>
                                {data.map((list, index) =>
                                    <li key={index} style={{marginTop: "10px"}}>
                                        <Typography
                                            component='span'
                                            className='artical-paragraph-global-text-list'
                                        >
                                            {list.title}
                                        </Typography>
                                        <Typography
                                            component='span'
                                            className='artical-paragraph-global-text'
                                        >
                                            {` - ${list.content}`}
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default TraditionalccaasBusiness
