import React from 'react'

const VideoSchema = ({className, embedUrl, title, description, thumbnailUrl, uploadDate}) => {
    return (
        <>
            <iframe
                className={className}
                src={embedUrl} // Correct embed URL
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title={title}
            />
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "VideoObject",
                    "name": title, 
                    "description": description, 
                    "embedUrl": embedUrl,
                    "thumbnailUrl": thumbnailUrl,
                    "uploadDate": uploadDate,
                })}
            </script>
        </>
    )
}

export default VideoSchema
