import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import image1 from './../../assests/Pages/Events/gitex10.jpg'
import image2 from './../../assests/Pages/Events/gitex9.jpg'
import image3 from './../../assests/Pages/Events/gitex8.jpg'
import image4 from './../../assests/Pages/Events/gitex7.jpg'
import image5 from './../../assests/Pages/Events/gitex6.jpg'
import image6 from './../../assests/Pages/Events/gitex5.jpg'
import image7 from './../../assests/Pages/Events/gitex4.jpg'
import image8 from './../../assests/Pages/Events/gitex3.jpg'
import image9 from './../../assests/Pages/Events/gitex2.jpg'
import image10 from './../../assests/Pages/Events/gitex1.jpg'
import './EventExperience.css'

const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
];

const EventExperience = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(false);
    const isMobileScreen = useMediaQuery('(min-width: 0px) and (max-width: 600px)')

    useEffect(() => {
        setFade(true);
        const fadeTimeout = setTimeout(() => setFade(false), 500);
        const timer = setTimeout(() => goToNextSlide(), 10000);
        return () => {
            clearTimeout(timer);
            clearTimeout(fadeTimeout);
        };
    }, [currentIndex]);

    const goToNextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPrevSlide = () => {
        setCurrentIndex((prevIndex) => prevIndex === 0 ? images.length - 1 : prevIndex - 1);
    };

    return (
        <Grid container className='experince-component-container' justifyContent={"center"} alignItems={"center"}>
            <Grid item className='experience-component-item-style'>
                <Grid container justifyContent={"center"} alignItems={"center"} gap={4}>
                    <Grid item xs={12} md={10} mb={2}>
                        <Typography variant="h3" sx={{ textAlign: "center" }} className='event-name-style'>
                            Our Experience at GITEX Global 2024
                        </Typography>
                    </Grid>

                    <Grid item xs={12} xl={11} sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {!isMobileScreen &&
                                <Box className={`image-wrapper prev-wrapper ${fade ? 'fade' : ''}`}>
                                    <Box
                                        component="img"
                                        className="prev-slide"
                                        src={images[(currentIndex - 1 + images.length) % images.length]}
                                        alt="prev-slide"
                                    />
                                </Box>
                            }

                            <Box
                                className={`current-slide ${fade ? 'fade' : ''}`}
                                component="img"
                                src={images[currentIndex]}
                                alt="current-slide"
                            />

                            {!isMobileScreen &&
                                <Box className={`image-wrapper next-wrapper ${fade ? 'fade' : ''}`}>
                                    <Box
                                        component="img"
                                        className="next-slide"
                                        src={images[(currentIndex + 1) % images.length]}
                                        alt="next-slide"      
                                    />
                                </Box>
                            }
                        </Box>                       
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container alignItems={"center"} justifyContent={"center"} gap={3}>
                            <Grid item onClick={goToPrevSlide} className='arrow-button-global-style'>
                                <ChevronLeftIcon sx={{color: "white", height: "28px", width: "28px", display: "flex"}}/>
                            </Grid>

                            <Grid item>
                                <Grid container gap={"4px"} alignItems={"center"}>
                                    {images.map((im, index) => <Grid item className={index==currentIndex ? 'selected-image-dot-style' : 'image-dot-style'} key={im} onClick={() => setCurrentIndex(index)}>

                                    </Grid>)}
                                </Grid>
                            </Grid>

                            <Grid item onClick={goToNextSlide} className='arrow-button-global-style'>
                                <ChevronRightIcon sx={{color: "white", height: "28px", width: "28px", display: "flex"}}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>        
        </Grid>

    );
}

export default EventExperience
