import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import './InstaAutomateSection.css'
import SideBySideSectionWithBtn from '../GlobalComponents/SideBySideSectionWithBtn/SideBySideSectionWithBtn'
import EastIcon from '@mui/icons-material/East';

import img2 from '../../assests/Solution/Instagram/instagram 2.png'
import img3 from '../../assests/Solution/Instagram/instagram 3.png'
import img4 from '../../assests/Solution/Instagram/instagram 4.png'


const InstaAutomateSection = () => {

    const isBelow900 = useMediaQuery('(min-width: 0px) and (max-width: 900px)')


    const heroSectionData = {
        heading: "AI-Driven Product Recommendations",
        description: "AI delivers real-time, personalized product suggestions, enhancing the shopping experience. The chatbot tailors recommendations based on user preferences, driving engagement and sales. By analyzing browsing habits, it offers relevant options that boost interaction and conversions.",
        image: img4,
        btnText: "Integrate Instagram with Chronox",
        btnGlobalCustomStyle: 'in-gcs-style',
        btnVariant: 'text',
        globalEndIcon: <EastIcon />,
        flagImageDirection: "right",
        onClick: () => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')
    }
    const heroSectionData2 = {
        heading: "Automated Lead Capture & Nurturing",
        description: "AI-powered lead capture automates collecting contact details through chat, turning visitors into leads. The chatbot gathers key information and seamlessly moves it into your CRM, boosting efficiency and engagement without manual effort.",
        image: img2,
        btnText: "Integrate Instagram with Chronox",
        btnGlobalCustomStyle: 'in-gcs-style',
        btnVariant: 'text',
        globalEndIcon: <EastIcon />,
        flagImageDirection: "left",
        onClick: () => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')

    }
    const heroSectionData3 = {
        heading: "Real-Time Discount Offers",
        description: "AI-triggered discount messages engage users at the perfect moment. When users browse products or abandon their cart, the chatbot sends personalized discount codes or offers, encouraging them to complete purchases. This strategy boosts conversions and reduces cart abandonment with timely, targeted messaging.",
        image: img3,
        btnText: "Integrate Instagram with Chronox",
        btnGlobalCustomStyle: 'in-gcs-style',
        btnVariant: 'text',
        globalEndIcon: <EastIcon />,
        flagImageDirection: isBelow900 ? "left" : "right",
        onClick: () => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')
    }



    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xl={5} lg={8} md={9.5} sm={11} xs={12} className='automate-span'>
                            <span>Automate your Instagram sales with no-code conversion Flows</span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='in-automate-item'>
                    <SideBySideSectionWithBtn content={heroSectionData} />
                </Grid>
                <Grid item xs={12} className='in-automate-item'>
                    <SideBySideSectionWithBtn content={heroSectionData2} />
                </Grid>
                <Grid item xs={12} className='in-automate-item'>
                    <SideBySideSectionWithBtn content={heroSectionData3} />
                </Grid>
            </Grid>
        </>
    )
}


export default InstaAutomateSection