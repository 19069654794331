import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, Fade, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import './HeroSectionNew.css'
import meta from '../../assests/LandingPage/meta.svg'
import metaVarified from '../../assests/LandingPage/meta-varified.svg'
import VideoSchema from '../../Utils/VideoSchema/videoSchema'

const HeroSectionNew = (props) => {
    const { content, flagBanner } = props;

    const isMobile = useMediaQuery('(min-width: 0px) and (max-width: 600px)')
    const [selectedImage, setSelectedImage] = useState(content.contentImageSection.length > 2 ? content.contentImageSection[1] : content.contentImageSection[0]);
    const [slideDirection, setSlideDirection] = useState(''); // Track the slide direction
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const totalImages = content.contentImageSection.length;



    const handleImageChange = (index) => {
        const direction = index > currentIndex ? 'left' : 'right';
        setSlideDirection(direction);
        setTimeout(() => {
            setSelectedImage(content.contentImageSection[index]);
            setCurrentIndex(index);
            setSlideDirection(''); // Reset the slide direction after the transition
        }, 500); // Match this timing with the CSS transition
    };


    const handleNext = () => {
        const nextIndex = (currentIndex + 1) % totalImages;
        const direction = 'left'; // Moving to the next item slides left

        setSlideDirection(direction);
        setTimeout(() => {
            setSelectedImage(content.contentImageSection[nextIndex]);
            setCurrentIndex(nextIndex);
            setSlideDirection(''); // Reset the slide direction after the transition
        }, 500); // Match this timing with the CSS transition

        setSelectedIndex(nextIndex);
    };

    const handlePrev = () => {
        const prevIndex = currentIndex === 0 ? totalImages - 1 : currentIndex - 1;
        const direction = 'right'; // Moving to the previous item slides right

        setSlideDirection(direction);
        setTimeout(() => {
            setSelectedImage(content.contentImageSection[prevIndex]);
            setCurrentIndex(prevIndex);
            setSlideDirection(''); // Reset the slide direction after the transition
        }, 500); // Match this timing with the CSS transition

        setSelectedIndex(prevIndex);
    };

    // const handleImageChange = (index) => {
    //     setShowImage(false);
    //     setTimeout(() => {
    //         setSelectedImage(content.contentImageSection[index]);
    //         setShowImage(true);
    //         setCurrentIndex(index);
    //     }, 300);
    // };

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //     const nextIndex = (currentIndex + 1) % content.contentImageSection.length;
    //     handleImageChange(nextIndex);
    // }, 3000);

    //     return () => clearInterval(interval);
    // }, [currentIndex]);


    return (
        <>
            <Grid container
                className='front-hero-section-container'
                justifyContent={"center"}
                alignItems={"start"}
                sx={{
                    padding:
                    {
                        xl: flagBanner ? '100px 30px !important' : '150px 30px !important',
                        lg: flagBanner ? '80px 30px 80px !important' : '120px 30px !important',
                        md: flagBanner ? '80px 30px !important' : '80px 30px !important',
                        sm: flagBanner ? '80px 0px !important' : '80px 0px !important',
                        xs: flagBanner ? '60px 0px !important' : '60px 0px !important',
                    }
                }}
            >
                <Grid item xl={10.5} lg={11} md={11} sm={11} xs={12} className='front-hero-main-section'>
                    <Grid container
                        // direction={content.contentID % 2 == 0 ? "row-reverse" : "row"}
                        // flexWrap={{ xs: "wrap", lg: "nowrap" }}
                        gap={{ xs: 5, md: 3, lg: 4 }}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        flexWrap={{xs: "wrap", lg: "nowrap"}}
                    >
                        <Grid item xl={5.8} lg={5.8} md={12} sm={12} xs={12}>
                            <Grid container gap={{ xs: 3, lg: 4 }}>
                                <Grid item xs={12}>
                                    <h1 className='hero-heading-text-style'>{content.contentHeading1}</h1>
                                    <h2 className='hero-heading2-text-style'>{content.contentHeading2}</h2>
                                </Grid>

                                {content.flagListData &&
                                    <Grid item xs={12} className='content-point-item'>
                                        <Grid container direction={"column"} sx={{ rowGap: { sm: '8px', xs: '10px' } }}>
                                            {content.listData.map((list, index) =>
                                                <Grid item key={index}>
                                                    <Grid container flexWrap={"nowrap"} gap={1.5}>
                                                        <Grid item mt={"3px"}>{content.listIcon}</Grid>
                                                        <Grid item className='content-point-text'>
                                                            <p>{list}</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <Grid container sx={{ alignItems: 'center', gap: '15px' }}>
                                        <Grid item >
                                            <img src={meta} alt="Meta" srcSet="" className='vf-meta-meta-img' loading='lazy' />
                                        </Grid>
                                        <Grid item sx={{ display: 'flex' }} className='lp-divider-item'>
                                            <Divider
                                                orientation="horizontal" variant="middle"
                                                sx={{
                                                    borderWidth: '1px',
                                                    borderColor: 'black',
                                                    height: '30px',
                                                    borderColor: '#858585',
                                                    margin: '0px'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='span' className='verified-meta-tech-span'>
                                                Verified Meta Tech Provider <img src={metaVarified} style={{ width: '22px', height: 'auto', verticalAlign: 'middle' }} alt="Verified" srcSet="" loading='lazy' />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container>
                                        {content.buttonData.map((data) =>
                                            <Button
                                                className={`hero-global-btn ${data.buttonStyle === "dark" ? "hero-dark-button-style" : "hero-light-button-style"}`}
                                                onClick={(event) => {
                                                    if (data.buttonText == "Get Started") {
                                                        const url = data.buttonlink;
                                                        if (event.ctrlKey || event.metaKey) {
                                                            window.open(url, "_blank");
                                                        } else {
                                                            window.location.href = url;
                                                        }
                                                    }
                                                    else {
                                                        if (typeof data.buttonlink == "function") {
                                                            data.buttonlink();
                                                        }
                                                    }
                                                }}
                                                aria-label={data.buttonText}
                                                key={data.buttonText}
                                            >
                                                {data.buttonText}
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={6.5} lg={7.2} md={12} sm={12} xs={12} className={content.contentID % 2 == 0 ? 'left-image-item-style' : 'right-image-item-style'}>
                            <Grid container className='' style={{ flexDirection: "column", alignItems: "center" }}>
                                <Grid item xs={12} className='hsc-iframe-item' >
                                    {/* <Fade in={showImage} timeout={300}>
                                        <Box
                                            component="img"
                                            src={selectedImage.imgLink}
                                            alt={selectedImage.imgName}
                                            className='right-section-image-container'
                                        />
                                    </Fade> */}
                                    {/* <Box
                                        sx={{
                                            width: '100%',
                                            transform: slideDirection === 'left' ? 'translateX(-100%)' : slideDirection === 'right' ? 'translateX(100%)' : 'translateX(0)',
                                            transition: 'transform 0.5s ease-in-out',
                                            position: 'relative',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <video className='right-section-image-container' autoPlay loop muted>
                                            <source src="https://acedataanalytics.com/wp-content/uploads/2024/10/Instagram-chronox.mp4" type="video/mp4"></source>
                                        </video>
                                    </Box> */}

                                    {/* <iframe
                                        className='hsc-iframe'
                                        src={"https://www.youtube.com/embed/n977j6rGotk?autoplay=1"}
                                        frameBorder="0"
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen
                                        title='Transform Customer Service with Chronox AI'
                                    />  */}
                                    <VideoSchema 
                                        className='hsc-iframe'
                                        embedUrl="https://www.youtube.com/embed/n977j6rGotk?autoplay=1"
                                        title="Transform Customer Service with Chronox AI"
                                        description="Discover how Chronox AI is transforming the way businesses connect with their customers." 
                                        thumbnailUrl="https://img.youtube.com/vi/n977j6rGotk/0.jpg"
                                        uploadDate="2024-10-24T19:32:00+05:30"
                                    />
                                </Grid>

                                {/* {content.flagFloatImage &&
                                    (isMobile) ? (<>
                                        <Grid item className='right-image-button-item'>
                                            <Grid container justifyContent={"space-around"} className='hero-right-section-button-container' flexWrap={"nowrap"}>
                                                <IconButton onClick={handlePrev} className='prev-button'>
                                                    <ArrowBackIcon />
                                                </IconButton>

                                                <Grid item
                                                    xs={11}
                                                    // className='togglg-mobile-btn-item'
                                                    sx={{
                                                        maxWidth: 'calc(100% - 80px)',
                                                        width: 'calc(100% - 80px)',
                                                        display: 'flex'
                                                    }}>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        className='hero-section-right-container-button'
                                                        sx={{
                                                            backgroundColor: content.contentImageSection[selectedIndex].imgName.imgID === selectedImage.imgID ? 'black' : '#6A097D',
                                                            color: content.contentImageSection[selectedIndex].imgName.imgID === selectedImage.imgID ? '#ffffff' : '#ffffff !important',
                                                        }}
                                                    >
                                                        {content.contentImageSection[selectedIndex].imgName}
                                                    </Button>
                                                </Grid>

                                                <IconButton onClick={handleNext}>
                                                    <ArrowForwardIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item className='right-image-button-item'>
                                            <Grid container justifyContent={"space-around"} className='hero-right-section-button-container' gap={1} flexWrap={"nowrap"}>
                                                {content.contentImageSection.map((image, index) => (
                                                    <Grid item key={image.imgID}>
                                                        Commented
                                                        <Button
                                                            variant={image.imgID === selectedImage.imgID ? 'contained' : ''}
                                                            onClick={() => handleImageChange(index)}
                                                            className='hero-section-right-container-button'
                                                            sx={{
                                                                backgroundColor: image.imgID === selectedImage.imgID ? 'rgba(247, 233, 255, 1)' : '#ffffff',
                                                            }}
                                                        >
                                                            {image.imgName} 
                                                            
                                                        </Button>
                                                        <Button
                                                            variant={image.imgID === selectedImage.imgID ? 'contained' : ''}
                                                            onClick={() => handleImageChange(index)}
                                                            className='hero-section-right-container-button'
                                                            sx={{
                                                                backgroundColor: image.imgID === selectedImage.imgID ? '#6A097D' : '#ffffff',
                                                                color: image.imgID === selectedImage.imgID ? '#ffffff !important' : '#6A097D'
                                                            }}
                                                        >
                                                            {image.imgName}
                                                        </Button>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </>)
                                } */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default HeroSectionNew
