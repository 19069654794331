import { Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import './LandingChatbotFrameSections.css'
import Slider from 'react-slick/lib/slider'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoSchema from '../../Utils/VideoSchema/videoSchema';



const LandingChatbotFrameSections = () => {

    const isMobile = useMediaQuery('(min-width: 0px) and (max-width: 600px)')
    const uploadDateOfVideo = "2024-10-13T05:26:00+05:30";

    const card = [
        {
            head: "Fast, Secure, and Always On",
            subHead: "With AI-powered agents integrated into WhatsApp, your customers get instant, secure responses. WhatsApp’s real-time messaging means your important updates, confirmations, and transactions are delivered promptly, with over 90% of messages opened. No more missed opportunities.",
            img: "https://youtu.be/dYrqt9xIv8c",
            videoKey: "dYrqt9xIv8c", 
            uploadDate: uploadDateOfVideo,
            flagIframe: true
        },
        {
            head: "Direct Messages That Delight and Convert",
            subHead: "Engage your customers right in their DMs with personalized, real-time responses. Whether it's answering product questions or providing order updates, AI agents resolve queries instantly, creating a seamless experience that keeps your audience connected and ready to buy.",
            img: "https://youtu.be/o9leMKMJTmk",
            videoKey: "o9leMKMJTmk",
            uploadDate: "2024-10-13T15:33:00+05:30",
            flagIframe: true
        },
        {
            head: "Personalize Every Interaction Effortlessly",
            subHead: "Your AI agents handle inquiries the moment customers message your page, offering personalized answers to drive engagement. Facebook Messenger becomes a channel for nurturing, upselling, and automating feedback collection—while you focus on what matters.",
            img: "https://youtu.be/C3eXTp0tKuI",
            videoKey: "C3eXTp0tKuI",
            uploadDate: uploadDateOfVideo,
            flagIframe: true
        },
        {
            head: "Round-the-Clock Service, No Waiting",
            subHead: "Turn every website visit into a potential conversion with 24/7 AI-powered support. From handling product inquiries to managing order updates, your AI agents deliver instant solutions that keep customers engaged, even after hours.",
            img: "https://youtu.be/EoH49f5amDk", 
            videoKey: "EoH49f5amDk",
            uploadDate: uploadDateOfVideo,
            flagIframe: true

        },

    ]

    const backgroundColors = ['rgba(202, 202, 202, 0.4)', 'rgba(255, 246, 211, 1)', 'rgba(204, 228, 255, 1)', 'rgba(234, 234, 255, 1)',];

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>

                        <div style={{ width: '100%' }}>
                            <Grid item xl={9} lg={9} md={9} sm={10} xs={11} className='lcf-head-item'>
                                <Typography
                                    variant='h2'
                                >
                                    Meet Customers Where They Are,  Resolve Issues in Real-Time
                                </Typography>
                            </Grid>
                        </div>

                        {!isMobile ?
                            <>
                                <Grid item xs={12} className='lcf-card-main-item'>
                                    <Grid container sx={{ justifyContent: 'center' }}>
                                        {card.map((ele, index) => {
                                            const embedUrl = ele.img.replace("youtu.be/", "www.youtube.com/embed/"); 
                                            const thumbnailUrl = `https://img.youtube.com/vi/${ele.videoKey}/0.jpg`;
                                            return (
                                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ backgroundColor: backgroundColors[index % backgroundColors.length] }} className='lcf-card-frame-item' key={index}>
                                                    <Grid container className='lcf-card-frame-container' >
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid item xl={11} lg={12} md={12} sm={10} xs={12} className='lcf-card-head-item'>
                                                                    <h3 className='lcf-card-head'>
                                                                        {ele.head}
                                                                    </h3>
                                                                </Grid>
                                                                <Grid item xl={10} lg={12} md={12} sm={10} xs={12} className='lcf-card-subhead-item'>
                                                                    <p className='lcf-card-subhead'>
                                                                        {ele.subHead}
                                                                    </p>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sx={{ textAlign: 'center' }} className='landing-image-chatbot-item' >
                                                            {/* <iframe
                                                                className="landing-image-chatbot-iframe"
                                                                src={embedUrl} // Correct embed URL
                                                                frameBorder="0"
                                                                allow="autoplay; fullscreen; picture-in-picture"
                                                                allowFullScreen
                                                                title={embedUrl}
                                                            /> */} 
                                                            <VideoSchema 
                                                                className='landing-image-chatbot-iframe'
                                                                embedUrl={embedUrl}
                                                                title={ele.head}
                                                                description={ele.subHead} 
                                                                thumbnailUrl={thumbnailUrl} 
                                                                uploadDate={ele.uploadDate}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </> : (
                                <>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={11} sx={{ margin: '0 auto', textAlign: 'center' }}>
                                        <Grid className='lcf-card-frame-container'>
                                            <Slider
                                                dots={true}
                                                infinite={true}
                                                autoplay={false}
                                                className='chatbot-click-slider'
                                                speed={400}
                                                arrow={false}
                                                slidesToShow={1}
                                                slidesToScroll={1}
                                                appendDots={dots => (
                                                    <div className="dot-container">
                                                        <ul className="chatbot-dots">{dots}</ul>
                                                    </div>
                                                )}
                                            >
                                                {card.map((ele, index) => {
                                                    const embedUrl = ele.img.replace("youtu.be/", "www.youtube.com/embed/");
                                                    const thumbnailUrl = `https://img.youtube.com/vi/${ele.videoKey}/0.jpg`;
                                                    return (
                                                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ backgroundColor: backgroundColors[index % backgroundColors.length] }} className='lcf-card-frame-item' key={index}>
                                                            <Grid container className='lcf-card-frame-container' >
                                                                <Grid item xs={12}>
                                                                    <Grid container>
                                                                        <Grid item xl={11} lg={12} md={12} sm={10} xs={12} className='lcf-card-head-item'>
                                                                            <span className='lcf-card-head'>
                                                                                {ele.head}
                                                                            </span>
                                                                        </Grid>
                                                                        <Grid item xl={8} lg={12} md={12} sm={10} xs={12} className='lcf-card-subhead-item'>
                                                                            <span className='lcf-card-subhead'>
                                                                                {ele.subHead}
                                                                            </span>
                                                                        </Grid>
                                                                        {/* {index == 1 &&
                                                                            <div style={{ height: '25px', width: '100%' }}></div>
                                                                        } */}
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12} sx={{ textAlign: 'center' }} className='landing-image-chatbot-item'>
                                                                    {/* <iframe
                                                                        className="landing-image-chatbot-iframe"
                                                                        src={embedUrl} // Correct embed URL
                                                                        frameBorder="0"
                                                                        allow="autoplay; fullscreen; picture-in-picture"
                                                                        allowFullScreen
                                                                        title={embedUrl}
                                                                    /> */}
                                                                    <VideoSchema 
                                                                        className='landing-image-chatbot-iframe'
                                                                        embedUrl={embedUrl}
                                                                        title={ele.head}
                                                                        description={ele.subHead} 
                                                                        thumbnailUrl={thumbnailUrl} 
                                                                        uploadDate={ele.uploadDate}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Slider>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default LandingChatbotFrameSections